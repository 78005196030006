import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { authService, getActiveRoute, getActiveTab, getUniqueArray } from '../helper';
import { constants, formDefaultValues } from '../constants';
import { CContext } from '../components/utils';
import { getApplicationProgress } from '../services';

const { ROUTE } = constants;
const { allStepsForAavasLoan, applicantionProgressAvailable } = formDefaultValues;
// ----------------------------------------------------------------------

export default function LogoOnlyLayout() {
  const [isFirstTime, setIsFirstTime] = useState(true);
  const {
    isMobile,
    activeStep,
    setTabPanelValue,
    setActiveStep,
    setHideStepper,
    setErrorMessage,
    setApplicationProgress,
    updateApplicationProgress,
    setUpdateApplicationProgress,
    setShowProvisionalOffer,
    setOfferAccepted,
    mixpanel,
  } = useContext(CContext);
  const pathName = useMemo(() => window.location.pathname.toLocaleLowerCase(), [window.location.pathname]);
  const navigate = useNavigate();

  useEffect(() => {
    if (
      pathName === ROUTE.LOAN_APPLICAIONT &&
      new URLSearchParams(window.location.search).get('razorpay_payment_link_status')
    ) {
      setActiveStep(getActiveTab(ROUTE.FEE_PAYMENT));
    }
  }, []);

  useEffect(() => {
    setErrorMessage({ title: null, show: false });
    setHideStepper(false);
  }, [activeStep, pathName]);

  useEffect(() => {
    if (sessionStorage.mixpanel_token && mixpanel && mixpanel.people?.set)
      mixpanel.people.set({
        ...(sessionStorage.userName && { $name: sessionStorage.userName }),
        ...(sessionStorage.phoneNumber && { $phone: sessionStorage.phoneNumber }),
      });
  }, [mixpanel]);

  const updateRoutesAsPerConditions = (_applicationProgress, updateFirstTime) => {
    if (
      (isFirstTime && !new URLSearchParams(window.location.search).get('razorpay_payment_link_status')) ||
      updateFirstTime
    ) {
      const pendingStep = allStepsForAavasLoan.filter((item) => !_applicationProgress[item.key]);
      const isPhotosForOcrPending = pendingStep.some((item) => item.key === 'isOcrDocumentSaved');
      if (pendingStep.length !== allStepsForAavasLoan.length && isPhotosForOcrPending) {
        pendingStep.shift();
      }
      if (pendingStep.length > 0) {
        if (isMobile) {
          if (pendingStep[0].route === '/applicant-details') {
            return setTabPanelValue(1);
          }
          return navigate(pendingStep[0].route, { replace: true });
        }
        if (window?.location?.pathname?.toLowerCase() !== ROUTE.LOAN_APPLICAIONT) {
          setActiveStep(getActiveTab(pendingStep[0].route));
          return navigate(ROUTE.LOAN_APPLICAIONT, { replace: true });
        }
        if (pendingStep[0].route === '/applicant-details') {
          setTabPanelValue(1);
        } else {
          return setActiveStep(getActiveTab(pendingStep[0].route));
        }

        //
      }
    }
    delete _applicationProgress.isOcrDocumentSaved;
    if (Object.values(_applicationProgress).every((item) => item === true)) {
      // navigate(ROUTE.APPLICATION_STATUS, { replace: true });
    }
    return true;
  };

	const data = {
			"id": 25,
			"isApplicationInfoSaved": false,
			"isApplicantInfoSaved": false,
			"isAssetInfoSaved": false,
			"isFeePaymentSaved": false,
			"isFtrDocumentSaved": false,
			"isOcrDocumentSaved": false
	}
  const getAndUpdateApplicationStatus = async (updateFirstTime) => {
    const res = await getApplicationProgress();
    // const modifiedResponse = {
		// 	applicationProgress: {
		// 		data
    //   }
    // };
		// console.log('modifiedResponse :', modifiedResponse);
    if (res.status) {
			delete res?.data?.id
      const { data } = res;
			
      // setShowProvisionalOffer(showProvisionalOffer);
      // if (showProvisionalOffer === false) {
      //   setOfferAccepted(true);
      // } else {
      //   setOfferAccepted(res?.data?.isOfferAccepted ? res.data.isOfferAccepted : false);
      // }
      const completed = Object.keys(data || {}).filter((item) => data[item]);
      let available = [];
      Object.keys(applicantionProgressAvailable).forEach((item) => {
        if (completed.includes(item)) {
          available = [...available, ...applicantionProgressAvailable[item]];
        } else if (completed.length === 0) {
          available = [...available];
        }
      });
      setApplicationProgress((prev) => ({ ...prev, completed, available: getUniqueArray(available) }));
      updateRoutesAsPerConditions(data || { isFtrDocumentSaved: false }, updateFirstTime);
    } else if (!res.status) {
      setErrorMessage({ title: res.error.msg, show: true });
    }
    setIsFirstTime(false);
  };

  useEffect(() => {
    setErrorMessage({ title: null, show: false });
    if (!authService.isLoggedIn() && pathName !== ROUTE.LOGIN && pathName !== ROUTE.INITIAL_SCREEN && pathName !== ROUTE.REGISTER ) {
      // navigate(ROUTE.LOGIN, { replace: true });
    } else if (authService.isLoggedIn()) {
      if (pathName === ROUTE.LOGIN) {
        navigate(isMobile ? ROUTE.APPLICANT_DETAILS : ROUTE.LOAN_APPLICAIONT, { replace: true });
      } else if (isMobile && pathName === ROUTE.LOAN_APPLICAIONT) {
        navigate(getActiveRoute(activeStep), { replace: true });
      } else if (!isMobile && pathName !== ROUTE.LOAN_APPLICAIONT && pathName !== ROUTE.APPLICATION_STATUS) {
        navigate(`${ROUTE.LOAN_APPLICAIONT}${window.location.search}`, { replace: true });
        setActiveStep(getActiveTab(pathName));
      }
    }
    if (![ROUTE.INITIAL_SCREEN, ROUTE.ALL, ROUTE.LOGIN].includes(pathName)) {
      getAndUpdateApplicationStatus();
    }
  }, [pathName, isMobile, activeStep]);

  useEffect(() => {
    if (updateApplicationProgress) {
      getAndUpdateApplicationStatus(updateApplicationProgress && updateApplicationProgress !== 'true');
    }
    setUpdateApplicationProgress(false);
  }, [updateApplicationProgress]);

  return <Outlet />;
}

