/* eslint-disable react/display-name */
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useNavigate } from 'react-router-dom';


// @mui
import { Box, Typography, Stack, Button } from '@mui/material';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { Delete as DeleteIcon, Add as AddBoxIcon } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';

// sections
import { CContext } from '../components/utils';
// ----------------------------------------------------------------------

import { FormProvider } from '../components/hook-form';
import { useYupValidatoins } from '../custom-hooks';
import { constants, formDefaultValues } from '../constants';
import {
  assetDetailsContentStyle as ContentStyle,
  assetDetailsContainerStyle as ContainerStyle,
} from '../styled-components';
import { AssetDetailsForm, DeleteModal } from '../components/common-components';
import {
  deleteAssets,
  getAssetDetails,
  postAssetDetails,
  updateProvisionLetter,
} from '../services';
import { hanelMixPanelEvents, printErrorsOnConsole, filterErrorMessage } from '../helper/utils';
import ProvisionalOffer from './ProvisionalOffer';

const { ROUTE } = constants;

export default React.memo(() => {
  const [openDialog, setOpenDialog] = useState(false);
  const [deleteModal, setDeleteModal] = useState({ state: false });
  const [offerModal, setOfferModal] = useState({});
  const [moreAssets, setMoreAssets] = useState([]);
  const [allApplicant, setAllApplicant] = useState([]);
  const [currentAddressData, setCurrentAddressData] = useState({});
  const { t } = useTranslation('translation');
  const { object, mixed, string, bool, array, requireDynamic, arrayOfObject } = useYupValidatoins();
  const {
    isMobile,
    activeStep,
    setActiveStep,
    errorMessage,
    setErrorMessage,
    setShowLoader,
    setOfferAccepted,
    mixpanel,
  } = useContext(CContext);
  const navigate = useNavigate();

  // Schema for asset details form
  const assetDetailsSchema = object.shape({
    assets: arrayOfObject({
      address: object.shape({
        plotNumber: string,
        floorNumber: string,
        streetAndBuildingName: string,
        locality: string,
        landmark: requireDynamic('string', 'LANDMARK_REQUIRED'),
        pinCode: requireDynamic('string', 'PIN_CODE_REQUIRED'),
        villageOrTown: requireDynamic('string', 'CITY_VILLAGE_TOWN_REQUIERD'),
        tehsil: requireDynamic('string', 'DISTRICT_IS_REQUIRED'),
        state: requireDynamic('string', 'STATE_IS_REQUIRED'),
      }),
      isPropertyIdentified: string.when('displayProperyIdentified', {
        is: (val) => val === 'true',
        then: requireDynamic('string', 'IS_THE_PROPERTY_IDENTIFIED_REQ'),
      }),
      isPropertyGovtFunded: string.when('displayProperyIdentified', {
        is: (val) => val === 'true',
        then: requireDynamic('string', 'IS_THE_PROPERTY_IDENTIFIED_REQ'),
      }),
      propertyTitle: string.when('displayProperyIdentified', {
        is: (val) => val === 'true',
        then: requireDynamic('string', 'PROPERT_TITLE_REQ'),
      }),
      isCurrentAddSameAsCollateralProperty: bool,
      // typeOfProperty: requireDynamic('string', 'TYPE_OF_PROPERTY_REQUIRED'),
      propertyOwnership: requireDynamic('string', 'PROPERTY_OWNERSHIP_REQUIRED'),
      displayProposedAreaOfConstruction: bool,
      displayProperyIdentified: bool,

      // }),
      proposedAreaOfConstruction: string.when('displayProposedAreaOfConstruction', {
        is: (val) => val === 'true',
        then: requireDynamic('string', 'PROPOSED_AREA_CONSTRUCTION_REQUIRED'),
      }),
      allApplicantIds: array.of(object),
    }),
  });

  const methods = useForm({
    resolver: yupResolver(assetDetailsSchema),
    defaultValues: { assets: [formDefaultValues.AssetDetailsDefaultValues] },
    mode: 'all',
  });

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };
  
  const handleCloseDialog = () => {
    setOpenDialog(false);
  };
  
  const {
    handleSubmit,
    getValues,
    reset,
    setValue,
    watch,
    formState: { isSubmitting, isValid },
  } = methods;

  const onDeleteAsset = async (index) => {
    const currentIndex = index + 1;
    const values = await getValues();

    let res = '';

    if (values.assets[currentIndex].id || values.assets[currentIndex].applicationCollateralId) {
      res = await deleteAssets(values.assets[currentIndex].applicationCollateralId);
    }
    if (res?.status || res === '') {
      values.assets.splice(index + 1, 1);
      reset({ ...values });
      const prev = [...moreAssets];
      prev.splice(index, 1);
      setMoreAssets(prev);
      setDeleteModal.call(null, { state: false });
    } else {
      setErrorMessage({ title: res.error.msg, show: true });
    }
  };

  const fetchAssetDetails = async () => {
    setShowLoader(true);
    const [assetDetailsResponse] = await Promise.all([
      getAssetDetails({responseKey: 'GET_ASSET_INFO'}),

    ]);
    
  
    if (assetDetailsResponse?.status) {
      const assetDetailsData = assetDetailsResponse.data;
      if (assetDetailsData.assetDetails && Array.isArray(assetDetailsData.assetDetails)) {
        const existingAssets = [];
        const { displayProposedAreaOfConstruction, displayProperyIdentified } = assetDetailsData;
        
        const values = assetDetailsData.assetDetails;
        if (values.length > 1) {
          values.forEach(() => {
            existingAssets.push(new Date().getTime());
          });
        }
  
        setValue('assets', values);
        setMoreAssets([...existingAssets]);
      }
    } else {
      setErrorMessage({ title: assetDetailsResponse.error.msg, show: true });
    }
    setShowLoader(false);
  };
  

  useEffect(() => {
    fetchAssetDetails();
  }, []);

  const onAcceptOffer = async (result) => {
    setShowLoader(true)
    const { applicableInterest, applicableEmiForLoanAmountAndTermOffered, applicableLoanAmount } = result
    const res = await updateProvisionLetter({ isOfferAccepted: true, applicableInterest, applicableEmiForLoanAmountAndTermOffered, applicableLoanAmount });
    redirectAccordingToDevice();
    if (res?.data.code === 200) {
      hanelMixPanelEvents.call(mixpanel, 'Provisional Offer Letter', 'Provisional Offer Letter Submitted');
      setOfferAccepted(res.data.isOfferAccepted);
      redirectAccordingToDevice();
      setShowLoader(false)

    } else {
      setOfferAccepted(false);
    }
  };

  // checking device type

  const redirectAccordingToDevice = () => {
    if (isMobile) {
      navigate(ROUTE.FEE_PAYMENT, { replace: true });
    } else {
      setActiveStep(activeStep + 1);
    }
  };

  const submitForm = async (data) => {
    const assetInfo = [...data.assets];
    assetInfo.forEach((item, index) => {
      assetInfo[index].address.addressType =
        assetInfo[0].isCurrentAddSameAsCollateralProperty === true ? 'CURRENT' : 'ASSET';
      return null;
    });
    const payload = {
      assetInfo,
    };
    const res = await postAssetDetails({apiKey: 'POST_ASSET_INFO', payload});

    if (res?.status) {
      // redirectAccordingToDevice()
      hanelMixPanelEvents.call(mixpanel, 'Assets Details', 'Assets Details Submitted');
      if (res?.data && res?.data?.showProvisionalOffer === true) {
        setOfferModal(res.data);
      } else {
        redirectAccordingToDevice();
      }
    } else {
      setErrorMessage({ title: filterErrorMessage(res?.error), show: true });
    }
  };

  const handlePreviousButton = (routeToNavigate) => {
    if (isMobile) {
      navigate(routeToNavigate, { replace: true });
    } else {
      setActiveStep(activeStep - 1);
    }
  };

  return (
    <>
      <ContainerStyle >
        <ContentStyle>
          <FormProvider methods={methods} onSubmit={handleSubmit(submitForm, printErrorsOnConsole.bind(this))}>
            <AssetDetailsForm
              key={`${'item'}-${0}`}
              {...{
                formfor: 'assets.0',
                reset,
                setValue,
                getValues,
                allApplicant,
                currentAddressData,
                watchForpropertyOwnership: watch('assets.0.propertyOwnership'),
                watchForAddType: watch(`assets.0.isCurrentAddSameAsCollateralProperty`)?.toString(),
                watchForPropertyIdentiFied: watch(`assets.0.displayProperyIdentified`)?.toString(),
                watchIsPropertyIdentified: watch('assets.0.isPropertyIdentified')?.toString(),
              }}
            />
            {/* <hr /> */}

            {moreAssets.length > 0 && (
              <Stack>
                {moreAssets.map((item, index) => (
                  <React.Fragment key={`${item}-${index}`}>
                    <Stack direction="row" spacing={3} mb={5} className="addAssetBlock width-100">
                      <Typography variant="h6" gutterBottom sx={{ marginBottom: -2.5, textAlign: 'left' }}>
                        {t('ADD_ANOTHER_ASSET')}
                      </Typography>
                      <Box
                        className="iconContainer deleteIconContainer"
                        onClick={setDeleteModal.bind(null, {
                          state: true,
                          txtMessage: 'DELETE_FROM_ASSET',
                          question: 'ARE_YOU_SURE_DELETE_ASSET',
                          deleteDocType: index,
                        })}
                      >
                        <DeleteIcon />
                      </Box>
                    </Stack>
                    <AssetDetailsForm
                      key={item}
                      {...{
                        formfor: `assets.${index + 1}`,
                        reset,
                        setValue,
                        getValues,
                        allApplicant,
                        currentAddressData,
                        watchForpropertyOwnership: watch(`assets.${index + 1}.propertyOwnership`),
                        watchForAddType: watch(`assets.${index + 1}.isCurrentAddSameAsCollateralProperty`)?.toString(),
                        watchForPropertyIdentiFied: watch(`assets.${index + 1}.displayProperyIdentified`)?.toString(),
                        watchIsPropertyIdentified: watch(`assets.${index + 1}.isPropertyIdentified`)?.toString(),
                      }}
                    />
                  </React.Fragment>
                ))}
              </Stack>
            )}


       <Stack
          direction="row"
          justifyContent="space-between"
          mt={5}
          sx={{
            position: 'fixed',
            bottom: 0,
            left: '225px',
            right: 0,
            bgcolor: 'white',
            padding: '10px',
          }}
        >
                <Button 
                onClick={()=>{handlePreviousButton()}}
                variant="outlined" 
                sx={{ minWidth: 120,
                color: "#1B2A68",
                borderColor: "#1B2A68",
                '&:hover': {
                 bgcolor: "#2A3C8B",
                 color: "#FFFFFF",
                  } 
                }} 
                >                
                {t('PREVIOUS')}
                </Button>
                <LoadingButton
                  size="large"
                  type="submit"
                  variant="contained"
                  onClick={handleOpenDialog}
                  disabled={!isValid || isSubmitting}
                  sx={{ minWidth: 120, 
                    bgcolor: "#1B2A68",  
                    '&:hover': {
                    bgcolor: "#2A3C8B", 
                    }}} 
                >
                  {t('NEXT')} ➜
                </LoadingButton>
              </Stack>
          </FormProvider>
        </ContentStyle>
      </ContainerStyle>
      {/* <Dialog

      open={openDialog}
      onClose={handleCloseDialog}
      sx={{
        display:'flex', justifyContent:'center',
        '& .MuiPaper-root': {
          maxWidth: 'none',
          overflowY: 'visible',
          padding:'30px'
        },
      }}
    >
      <DialogTitle sx={{
        display:'flex',
        alignItems:'center',
        justifyContent: 'space-between'
      }}>
    <Typography color='#1B2A68' variant='h4'> {t('UNASSISTED')}</Typography>   
    <IconButton
          edge="end"
          color="inherit"
          onClick={handleCloseDialog}
          aria-label="close"
          sx={{
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <Emicalculator redirectAccordingToDevice={redirectAccordingToDevice} />

      
    </Dialog> */}


      {deleteModal.state && (
        <DeleteModal
          deleteModal={deleteModal}
          handleModalClose={setDeleteModal.bind(null, { state: false })}
          onDeleteFileModal={onDeleteAsset.bind(this)}
        />
      )}

      {openDialog === true && (
        <ProvisionalOffer
          modalState={offerModal?.showProvisionalOffer || false}
          handleModalClose={()=>{setOpenDialog(false)}}
          onAcceptOffer={onAcceptOffer.bind(this)}
          dataForCalculation={offerModal?.dataForCalculation ? offerModal?.dataForCalculation : {}}
          offerData={offerModal.offerData}
        />
      )}
    </>
  );
});

