import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import EditIcon from '@mui/icons-material/Edit';
import { Typography, Box, TextField, Grid, IconButton, Divider } from '@mui/material';
import { postUploadDocToAvaas } from '../services';
import { CContext } from '../components/utils';
import DocumentDetailsPreview from './DoumentDetailsPreview';

const PreviewData = ({ modalState, handleCloseDialog }) => {
  const { t } = useTranslation('translation');
  const [, setNext] = useState(false);
  const [applicationId, setApplicationId] = useState('');
  const [applicationDetails, setApplicationDetails] = useState({});
  const [addressDetails, setAddressDetails] = useState({});
  const [mainApplicantDetails, setMainApplicantDetails] = useState({});
  const [assetDetailsArray, setAssetDetailsArray] = useState([]);
  const [documentDetailsArray, setDocumentDetailsArray] = useState([]);
  const [coApplicantDetailsArray, setCoApplicantDetailsArray] = useState([]);

  const {
    setShowLoader,
    isMobile,
    setErrorMessage,
    setActiveStep,
    activeStep,
    applicationProgress: { completed },
  } = useContext(CContext);

  useEffect(() => {
    if (completed?.includes('isFeePaymentSaved')) {
      handlePaymentCompleted();
    }
  }, [completed]);

  const handlePaymentCompleted = () => {
    setShowLoader(false);
    setNext(true);
  };

  const handleSubmitAndPrintButton = () => {
    setActiveStep(activeStep + 1);
    handleCloseDialog();
  };

  const handleEditApplicationButton = () => {
    handleCloseDialog();
  };

  useEffect(() => {
	(async () => {
		let response
		try {
			setShowLoader(true);
			response = await postUploadDocToAvaas();
			const applicantionId = response?.data?.applicationDetails?.id;
			setApplicationId(applicantionId)
			setAddressDetails(response?.data?.applicantAddress[1])

			const applicationDetailsResponse = response?.data?.applicationDetails
			delete applicationDetailsResponse.applicationId
			delete applicationDetailsResponse.createdAt
			delete applicationDetailsResponse.updatedAt
			delete applicationDetailsResponse.id
			setApplicationDetails(applicationDetailsResponse)

			const mainApplicantDetailsResponse = response?.data?.applicant
			delete mainApplicantDetailsResponse.applicantId
			delete mainApplicantDetailsResponse.spouseName
			delete mainApplicantDetailsResponse.relationshipWithMainApplicant
			delete mainApplicantDetailsResponse.isMainApplicant
			delete mainApplicantDetailsResponse.password
			delete mainApplicantDetailsResponse.createdAt
			delete mainApplicantDetailsResponse.updatedAt
			delete mainApplicantDetailsResponse.id
			setMainApplicantDetails(mainApplicantDetailsResponse)

			const coApplicantDetailsResponse = response?.data?.coApplicants
			const coApplicantUpdatedArray = []
			for (let index = 0; index < coApplicantDetailsResponse.length; index++) {
				const activeCoApplicantObject = coApplicantDetailsResponse[index];
				delete activeCoApplicantObject.applicantId
				delete activeCoApplicantObject.spouseName
				delete activeCoApplicantObject.isMainApplicant
				delete activeCoApplicantObject.password
				delete activeCoApplicantObject.createdAt
				delete activeCoApplicantObject.updatedAt
				delete activeCoApplicantObject.id
				coApplicantUpdatedArray.push(activeCoApplicantObject)
			}
			setCoApplicantDetailsArray(coApplicantUpdatedArray)

			const assetDetailsResponse = response?.data?.assetDetails
			const assetDetailsUpdatedArray = []
			for (let index = 0; index < assetDetailsResponse.length; index++) {
				const activeAssetObject = assetDetailsResponse[index];
				delete activeAssetObject.displayIsPropertyIdentifiedFrontendFlag
				delete activeAssetObject.displayProposedAreaOfConstructionFrontendField
				delete activeAssetObject.isPropertyIdentified
				delete activeAssetObject.createdAt
				delete activeAssetObject.updatedAt
				delete activeAssetObject.id
				delete activeAssetObject.propertyTitle
				delete activeAssetObject.proposedAreaOfConstruction
				delete activeAssetObject.isPropertyGovernmentFunded
				assetDetailsUpdatedArray.push(activeAssetObject)
			}
			setAssetDetailsArray(assetDetailsUpdatedArray[0])

			const documentDetailsResponse = response?.data?.documentDetails
			const documentDetailsUpdatedArray = []
			for (let index = 0; index < documentDetailsResponse.length; index++) {
				const activeDocumentObject = documentDetailsResponse[index];
				delete activeDocumentObject.aadharCardNumber
				delete activeDocumentObject.createdAt
				delete activeDocumentObject.documentDataType
				delete activeDocumentObject.documentName
				delete activeDocumentObject.drivingLicenseNumber
				delete activeDocumentObject.id
				delete activeDocumentObject.isKycDocument
				delete activeDocumentObject.panCardNumber
				delete activeDocumentObject.updatedAt
				delete activeDocumentObject.voterCardNumber
				delete activeDocumentObject.uploadedFileDetails
				documentDetailsUpdatedArray.push(activeDocumentObject)
			}
			setDocumentDetailsArray(documentDetailsUpdatedArray)

		} catch (error) {
			setErrorMessage({ title: response.error.errors[0], show: true });
			console.error('Error while application data preview:', error);
		}

		setShowLoader(false);
	})();
}, []);


  const sectionConfigs = [
    {
      title: "PERSONAL DETAILS",
      fields: [
        { label: "First Name", key: "firstName" },
        { label: "Middle Name", key: "middlename" },
        { label: "Last Name", key: "lastname" },
        { label: "Gender", key: "gender" },
        { label: "Marital Status", key: "maritalStatus" },
        { label: "Date of Birth", key: "dateOfBirth" },
        { label: "Mobile Number", key: "phoneNumber" },
        { label: "Email", key: "emailId" },
        { label: "Religion", key: "religion" },
        { label: "Caste Category", key: "casteCategory" },
        { label: "Father's Name", key: "fatherName" },
        { label: "Mother's Name", key: "motherName" },
        { label: "Customer Type", key: "customerType" },
        { label: "PAN Number", key: "panCardNumber" },
      ],
      data: () => mainApplicantDetails,
    },
    {
      title: "APPLICATION DETAILS",
      fields: [
        { label: "Type of Loan", key: "isNewLoan", value: (details) => details?.isNewLoan ? 'New Loan' : 'Existing Loan' },
        { label: "Loan Type Required", key: "loanType" },
        { label: "Required Loan Amount", key: "loanAmount" },
        { label: "Loan Tenure in Months", key: "loanTenureInMonths" },
        { label: "Other EMI", key: "otherMonthlyEMI" },
      ],
      data: () => applicationDetails,
    },
    {
      title: "ADDRESS DETAILS",
      fields: [
        { label: "Plot Number", key: "plotNumber" },
        { label: "Floor Number", key: "floorNumber" },
        { label: "Street and Building Name", key: "streetAndBuildingName" },
        { label: "Locality", key: "locality" },
        { label: "Landmark", key: "landmark" },
        { label: "Pincode", key: "pinCode" },
        { label: "District", key: "tehsil" },
        { label: "Village/Town", key: "villageOrTown" },
        { label: "State", key: "state" },
      ],
      data: () => addressDetails,
    },
    {
		title: "PROPERTY DETAILS",
		fields: [
		  { label: 'Type of Property', key: 'typeOfProperty', source: 'direct' },
		  { label: 'Property Ownership', key: 'propertyOwnership', source: 'direct' },
		  { label: 'Plot Number', key: 'plotNumber', source: 'referenceAddress' },
		  { label: 'Floor Number', key: 'floorNumber', source: 'referenceAddress' },
		  { label: 'Street and Building Name', key: 'streetAndBuildingName', source: 'referenceAddress' },
		  { label: 'Locality', key: 'locality', source: 'referenceAddress' },
		  { label: 'Landmark', key: 'landmark', source: 'referenceAddress' },
		  { label: 'Village/Town', key: 'villageOrTown', source: 'referenceAddress' },
		  { label: 'State', key: 'state', source: 'referenceAddress' },
		],
		data: () => assetDetailsArray,
	  },
  ];

  return (
    <><Box sx={{
      display: 'flex',
      alignItems: 'center',
      background: '#D3D3D3',
      justifyContent: 'center'
    }}>
      <Typography variant="subtitle1" style={{ color: '#black', marginTop: '10px', }}>
        Application ID : {applicationId}
      </Typography>
    </Box><Box sx={{ padding: '20px', maxWidth: '1200px' }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '20px' }}>
          <Box>
            <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
              Preview Data
            </Typography>
            <Typography variant="h4" sx={{ fontWeight: 'bold' }}>
              About you
            </Typography>
          </Box>
          <IconButton onClick={handleEditApplicationButton} color="primary">
            <EditIcon />
          </IconButton>
        </Box>

        <Box
          sx={{
            paddingTop: '20px',
            maxHeight: 'calc(100vh - 300px)',
            overflow: 'auto',
            '&::-webkit-scrollbar': { display: 'none' },
          }}
        >
          {sectionConfigs.map((section, sectionIndex) => (
            <React.Fragment key={sectionIndex}>
              <Grid container spacing={3} sx={{ marginTop: '10px' }}>
                <Grid item xs={3}>
                  <Typography variant="h6" sx={{ color: '#00296b' }}>
                    {section.title}
                  </Typography>
                </Grid>
                <Grid item xs={9}>
                  <Grid container spacing={3}>
                    {section.fields.map((field, index) => (
                      <Grid item xs={4} key={index}>
                        <Typography sx={{color:'#6b7280', marginBottom:'5px'}} >{field.label}</Typography>
                        <TextField
                          size="small"
                          label={field.label}  // Keep the label here
                          value={section.title === 'PROPERTY DETAILS'
                            ? (
                              field.source === 'direct'
                                ? section.data()[field.key] || ''
                                : section.data()?.addressReference?.[field.key] || ''
                            )
                            : (
                              field.value
                                ? field.value(section.data())
                                : section.data()?.[field.key] || ''
                            )}
                          fullWidth
                          focused
                          sx={{
                            '& .MuiOutlinedInput-root': {
                              '&.Mui-focused fieldset': {
                                borderColor: '#D3D3D3',
                                borderRadius: '8px',
                              },
                            },
                            '& .MuiInputLabel-root': {
                              display: 'none',  // Hide the label
                            },
                          }}
                          InputProps={{
                            readOnly: true,
                          }}
                          InputLabelProps={{
                            shrink: false,  // Prevent the label from shrinking
                          }}
                        />
                      </Grid>
                    ))}
                    {section.fields.length % 3 !== 0 && <Grid item xs={4} />}
                  </Grid>
                </Grid>
              </Grid>
              <Divider sx={{ marginTop: '20px' }} />
            </React.Fragment>
          ))}

          <Grid container spacing={3} sx={{ marginTop: '10px' }}>
            <Grid item xs={3}>
              <Typography variant="h6" sx={{ color: '#00296b' }}>
                Documents Uploaded
              </Typography>
            </Grid>
            <DocumentDetailsPreview documentDetailsArray={documentDetailsArray} />
          </Grid>
          <Divider sx={{ marginTop: '20px' }} />
        </Box>
      </Box></>
  );
};

export default React.memo(PreviewData);
