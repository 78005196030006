import React, { useState, useEffect } from 'react';
import { Typography, Box, DialogActions, Checkbox, FormControlLabel, IconButton } from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { RHFTextField } from '../components/hook-form';

const AssistedByForm = ({
  toggleDialog,
  setIsAssociateAssisted,
  setIsEmployeeAssisted,
  setSelectedRole,
  setIsDataSaved,
  employeeName,
  setEmployeeName,
  employeeCode,
  setEmployeeCode,
  setIsAssistedByEmployeeOfAavas,
  isEmployeeAssisted,
  isAssociateAssisted,
}) => {
  const [localEmployeeName, setLocalEmployeeName] = useState(isEmployeeAssisted ? employeeName : '');
  const [localEmployeeCode, setLocalEmployeeCode] = useState(isEmployeeAssisted ? employeeCode : '');
  const [localVendorName, setLocalVendorName] = useState(isAssociateAssisted ? employeeName : '');
  const [localVendorId, setLocalVendorId] = useState(isAssociateAssisted ? employeeCode : '');
  const [localSelectedRole, setLocalSelectedRole] = useState(isEmployeeAssisted ? 'Employee' : isAssociateAssisted ? 'Vendor' : '');
  const [isFormValid, setIsFormValid] = useState(false);

  const initialState = {
    employeeName: isEmployeeAssisted ? employeeName : '',
    employeeCode: isEmployeeAssisted ? employeeCode : '',
    vendorName: isAssociateAssisted ? employeeName : '',
    vendorId: isAssociateAssisted ? employeeCode : '',
    selectedRole: isEmployeeAssisted ? 'Employee' : isAssociateAssisted ? 'Vendor' : '',
  };

  useEffect(() => {
    if (
      (localSelectedRole === 'Employee' && localEmployeeName && localEmployeeCode) ||
      (localSelectedRole === 'Vendor' && localVendorName && localVendorId)
    ) {
      setIsFormValid(true);
    } else {
      setIsFormValid(false);
    }
  }, [localEmployeeName, localEmployeeCode, localVendorName, localVendorId, localSelectedRole]);

  const handleCheckboxChange = (event) => {
    const value = event.target.value;

    if (localSelectedRole === value) {
      // Uncheck the checkbox if the same value is clicked again
      setLocalSelectedRole('');
      setIsEmployeeAssisted(false);
      setIsAssociateAssisted(false);
    } else {
      setLocalSelectedRole(value);
      setIsEmployeeAssisted(value === 'Employee');
      setIsAssociateAssisted(value === 'Vendor');
    }
  };

  const handleSave = () => {
    if (isFormValid) {
      if (localSelectedRole === 'Employee') {
        setEmployeeName(localEmployeeName);
        setEmployeeCode(localEmployeeCode);
        setIsAssistedByEmployeeOfAavas(true);
      } else if (localSelectedRole === 'Vendor') {
        setEmployeeName(localVendorName);
        setEmployeeCode(localVendorId);
        setIsAssistedByEmployeeOfAavas(true);
      }
      setSelectedRole(localSelectedRole);
      setIsDataSaved(true);
      toggleDialog();
    }
  };

  const handleClose = () => {
    setLocalEmployeeName(initialState.employeeName);
    setLocalEmployeeCode(initialState.employeeCode);
    setLocalVendorName(initialState.vendorName);
    setLocalVendorId(initialState.vendorId);
    setLocalSelectedRole(initialState.selectedRole);

    setIsEmployeeAssisted(false);
    setIsAssociateAssisted(false);

    toggleDialog();
  };

  return (
    <Box sx={{ padding: '30px 25px', border: '1px solid #e0e0e0', backgroundColor: '#f5f5f5', width: '445px', marginLeft: '100px' }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography variant="h5" component="h2" gutterBottom>
          Assisted by
        </Typography>
        <IconButton
          edge="end"
          color="inherit"
          onClick={handleClose} // Call handleClose to reset the form if dialog is closed
          aria-label="close"
          sx={{
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </Box>

      <Typography variant="subtitle2" color="textSecondary" mb={2}>
        Subscription plan depends on what you need
      </Typography>

      <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', gap: '12px' }}>
        {localSelectedRole === 'Employee' && (
          <>
            <RHFTextField
              name="aavasEmployeeName"
              label="Name"
              placeholder="Name"
              required
              value={localEmployeeName}
              onChange={(e) => setLocalEmployeeName(e.target.value)}
            />
            <RHFTextField
              name="aavasEmployeeCode"
              label="ID"
              placeholder="Code"
              required
              value={localEmployeeCode}
              onChange={(e) => setLocalEmployeeCode(e.target.value)}
            />
          </>
        )}

        {localSelectedRole === 'Vendor' && (
          <>
            <RHFTextField
              name="associateVendorName"
              label="Vendor Name"
              placeholder="Vendor Name"
              required
              value={localVendorName}
              onChange={(e) => setLocalVendorName(e.target.value)}
            />
            <RHFTextField
              name="associateVendorId"
              label="Vendor ID"
              placeholder="Vendor ID"
              required
              value={localVendorId}
              onChange={(e) => setLocalVendorId(e.target.value)}
            />
          </>
        )}
      </Box>

      <Box sx={{ display: 'flex', mb: 2, gap: '45px' }}>
        <FormControlLabel
          control={
            <Checkbox
              checked={localSelectedRole === 'Employee'}
              onChange={handleCheckboxChange}
              value="Employee"
            />
          }
          label="Employee"
          sx={{ '.MuiTypography-root': { fontSize: '14px', fontWeight: 'medium' } }}
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={localSelectedRole === 'Vendor'}
              onChange={handleCheckboxChange}
              value="Vendor"
            />
          }
          label="Vendor"
          sx={{ '.MuiTypography-root': { fontSize: '14px', fontWeight: 'medium' } }}
        />
      </Box>

      <DialogActions sx={{ mt: 2 }}>
        <LoadingButton
          onClick={handleSave}
          disabled={!isFormValid}
          variant="contained"
          color="primary"
        >
          Save
        </LoadingButton>
      </DialogActions>
    </Box>
  );
};

export default AssistedByForm;
