import React from 'react';

// @mui
import { Box, Typography, Button } from '@mui/material';

import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material/styles';
import CheckIcon from '@mui/icons-material/Check';
import FamilyPhoto from '../assets/images/family.jpg';
import ShopPhoto from '../assets/images/shop.jpg';

const ContainerStyle = styled(Box)(({ theme }) => ({
  display: 'flex',
  height: '100vh',
  justifyContent: 'center',
  alignItems: 'center',
  '& .contentPart': {
    width: 700,
    padding: 30,
    boxShadow: '0 7px 14px rgb(0 0 0 / 10%), 0 8px 16px rgb(0 0 0 / 10%)',
    borderRadius: 4,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      boxShadow: 'none',
      padding: 20,
    },
    '& .header': {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      borderBottom: `1px solid ${theme.palette.greyBlue}`,
      '& .iconBox': {
        width: 70,
        height: 70,
        borderRadius: '50%',
        background: theme.palette.checkCircleBg,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        '& svg': {
          fontSize: 50,
          color: theme.palette.common.white,
        },
        [theme.breakpoints.down('sm')]: {
          width: 60,
          height: 60,
          '& svg': {
            fontSize: 40,
          },
        },
      },
      '& p': {
        fontWeight: 600,
        fontSize: 24,
        color: theme.palette.common.black,
        margin: 0,
        padding: '15px 0',
        [theme.breakpoints.down('sm')]: {
          fontSize: 18,
        },
      },
    },
    '& .contentBlock': {
      '& p': {
        fontWeight: 600,
        fontSize: 24,
        color: theme.palette.common.black,
        margin: 0,
        padding: '15px 15px',
        textAlign: 'center',
        [theme.breakpoints.down('sm')]: {
          fontSize: 18,
        },
      },
      '& .contentSection': {
        display: 'flex',
        alignItems: 'center',
        '& .imageContainer': {
          marginRight: 30,
          '& .imageBox': {
            width: 220,
            '& img': {
              width: '100%',
            },
          },
        },
        '& .list': {
          '& > div': {
            fontSize: 16,
            color: theme.palette.common.black,
            fontWeight: 500,
            padding: '8px 0',
          },
        },
        [theme.breakpoints.down('sm')]: {
          flexDirection: 'column',
          '& .imageContainer': {
            marginRight: 0,
            display: 'flex',
            marginBottom: 15,
            '& .imageBox': {
              width: '100%',
              marginTop: 0,
              paddingRight: 8,
              '& + .imageBox': {
                paddingRight: 0,
                paddingLeft: 8,
              },
            },
          },
          '& .list': {
            width: '100%',
          },
        },
      },
    },
    '& .buttonBox': {
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      marginTop: 24,
      '& button': {
        padding: '6px 30px',
        fontSize: 16,
        fontWeight: 500,
        textTransform: 'capitalize',
        height: 'inherit',
        background: theme.palette.error.main,
        color: theme.palette.common.white,
        borderRadius: 4,
        width: 'inherit',
      },
    },
  },
}));

export default () => {
  const { t } = useTranslation('translation');
  const list = [
    {
      title: t('EASY_TO_FILL'),
    },
    {
      title: t('APPLY_AT_YOUR_OWN_PACE'),
    },
    {
      title: t('EXPLANATION_FOR_STEP'),
    },
    {
      title: t('MANAGE_YOUR_APPLICATION'),
    },
  ];
  return (
    <ContainerStyle>
      <Box className="contentPart">
        <Box className="header">
          <Box className="iconBox">
            <CheckIcon />
          </Box>
          <Typography>{t('THANKS_FOR_SHARING')}</Typography>
        </Box>
        <Box className="contentBlock">
          <Typography>{t('FILL_LOAN_APPLICATION')}</Typography>
          <Box className="contentSection">
            <Box className="imageContainer">
              <Box className="imageBox">
                <img src={FamilyPhoto} alt="family_photo" />
              </Box>
              <Box className="imageBox" mt={2}>
                <img src={ShopPhoto} alt="shop_photo" />
              </Box>
            </Box>
            <Box className="list">
              {list.map((items, i) => (
                <Box key={i}>{items.title}</Box>
              ))}
            </Box>
          </Box>
        </Box>
        <Box className="buttonBox">
          <Button>{t('PROVIDE_DETAILS')}</Button>
        </Box>
      </Box>
    </ContainerStyle>
  );
};
