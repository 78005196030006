import { httpService } from '../helper';
import { constants } from '../constants';

export const getApplicantDetails = async (data) => httpService().get(`${constants.API.APPLICANT_DETAILS}`, data)

export const postApplicantDetails = async (data) => httpService().post(`${constants.API.APPLICANT_DETAILS}`, data);
export const getPanCardDetails = async (panNumber) =>
  httpService().get(`${constants.API.GET_PAN_DETAILS}?panCardNumber=${panNumber}`);
export const sendOtp = async (data) => httpService().post(`${constants.API.SEND_OTP}`, data);
export const verifyOtp = async (data) => httpService().post(`${constants.API.VERIFY_OTP}`, data);
export const panVerification = async (data) => httpService().post(`${constants.API.PAN_VERIFICATION}`, data);
export const getAccountDisabledField = async (panNumber) =>
  httpService().get(`${constants.API.GET_ACCOUNT_DISABLED_FIELD}?pan=${panNumber}`);
