import { styled } from '@mui/material/styles';
import { Grid, Tab } from '@mui/material';
import LoginImage from '../assets/images/backgroundLoginImage.png';

export const CustomTab = styled(Tab)(() => ({
  color: '#192C79',
}));

export const GridContainer = styled(Grid)({
  flexGrow: 1,
  height: '100vh',
});

export const ImageContainer = styled(Grid)({
  position: 'relative',
  height: '100%',
  width: '100%',
  backgroundImage: `url(${LoginImage})`,
  backgroundSize: 'cover',
  backgroundPosition: 'center',
});

export const ContentContainer = styled(Grid)(({ theme }) => ({
  backgroundColor: '#fff',
  padding: '30px 80px',
  // height:'100%',
  [theme.breakpoints.down('md')]: {
    padding: '30px',
  },
}));

export const StyledImage = styled('img')({
  position: 'absolute',
  bottom: 0,
  left: '50%',
  transform: 'translateX(-50%)',
  width: '80%',
  height: '90%',
});

export const LanguageIconStyle = styled('img')({
  position: 'absolute',
  top: 10,
  right: 10,
  cursor: 'pointer'
});

export const FooterLogoStyle = styled('img')({
margin:'auto'
});