import React, { memo, useContext, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Typography, Box, Stepper, Step, Grid, Menu, MenuItem, Divider, Button } from '@mui/material';
import { StepConnector } from '@mui/material';
import { AccountCircle as AccountCircleIcon } from '@mui/icons-material';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { styled } from '@mui/material/styles';
import { Popover } from '@mui/material';
import jwtDecode from 'jwt-decode';
import { CContext } from '../utils';
import ErrorMessage from './ErrorMessage';
import { ContentSections, LogoSection, StepLabels, StepperBox } from '../../styled-components';
import Logo from '../../assets/images/companyLogo.png';
import LanguageIconLight from '../../assets/images/languageIconLight.svg';
import { formDefaultValues } from '../../constants';
import PhotosForOcr from '../../pages/PhotosForOcr';

import {
  ApplicantDetails,
  ApplicationStatus,
  AssetDetails,
  BasicInformation,
  FeePayment,
  RequiredDocuments,
} from '../../pages';

const { allStepsForAavasLoan } = formDefaultValues;

const steps = [
  { key: 'isOcrDocumentSaved', element: <PhotosForOcr key={'first'} /> },
  { key: 'isApplicationInfoSaved', element: <BasicInformation key={'second'} /> },
  { key: 'isApplicantInfoSaved', element: <ApplicantDetails key={'third'} /> },
  { key: 'isAssetInfoSaved', element: <AssetDetails key={'fourth'} /> },
  { key: 'isFtrDocumentSaved', element: <RequiredDocuments key={'fifth'} /> },
  { key: 'isFeePaymentSaved', element: <FeePayment key={'sixth'} /> },
  { key: 'isApplicationStatusSaved', element: <ApplicationStatus key={'seventh'} /> },
].map((step) => ({ step, ...allStepsForAavasLoan.find((item) => item.key === step.key) }));


const WebContainerStyle = styled(Grid)(() => ({
  display: 'flex',
  height: '100vh',
  flexDirection: 'column',
}));

export const LanguageIconStyle = styled('img')({
  cursor: 'pointer',
  height: '30px',
  width: '30px',
  marginRight: '10px'
});

const SideMenu = styled(Grid)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  position: 'fixed',
  backgroundColor: theme.palette.background.paper,
}));

const MainContent = styled(Grid)(() => ({
  display: 'flex',
  flexDirection: 'column',
  height: '100vh',
  marginLeft: '224px',
}));

const HeaderSection = styled(Grid)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  backgroundColor: theme.palette.background.default,
  padding: theme.spacing(1.5),
  height: '10%',
  boxSizing: 'border-box',
  position: 'fixed',
  left: '225px',
  zIndex: 1,
}));

const ContentSection = styled(Grid)(({ theme }) => ({
  padding: '40px 0px',
  height: '100%',
}));

const ColorlibConnector = styled(StepConnector)(() => ({
  // Add your styles here
}));

function CommonHeader({ webViewOnly, children }) {
  const authToken = sessionStorage.getItem('authToken');
  const decodedToken = jwtDecode(authToken);
  const { firstName, lastName = '', emailId } = decodedToken;
  const displayLastName = lastName || '';
  const { t, i18n } = useTranslation('translation');  // Added i18n for language check
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();
  const [popoverAnchorEl, setPopoverAnchorEl] = useState(null);

  const handlePopoverOpen = (event) => {
    setPopoverAnchorEl(event.currentTarget);
  };
  
  const handlePopoverClose = () => {
    setPopoverAnchorEl(null);
  };
  
  const isPopoverOpen = Boolean(popoverAnchorEl);
  
  const {
    isMobile,
    activeStep,
    setActiveStep,
    changeLanguage,
    applicationProgress: { completed, available },
  } = useContext(CContext);

  const handleLanguageChange = (lang) => {
    if (lang !== i18n.language) {  // Check if the selected language is different
      changeLanguage(lang);  // Only change if it's different
    }
    setAnchorEl(null);  // Close the Menu
  };

  const openMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    sessionStorage.clear();
    navigate('/login');
  };

  const classNameForTitle = useCallback(
    (item) => {
      const className = 'hand-icon';
      if (item.available === 'isRequiredDocumentsAvailable') {
        return className;
      }
      if (available.includes(item.available) || completed.includes(item.key)) {
        return className;
      }
      return 'disabled-title';
    },
    [completed, available]
  );

  return (
    <WebContainerStyle className="234234234234" container>
      <SideMenu item xs={2}>
        <StepperBox>
          <LogoSection sx={{width:'202px'}}>
            <img src={Logo} alt="logo" width='72%' />
          </LogoSection>
          <ContentSections>
            <Box sx={{ marginTop: '15px', marginBottom: '10px', padding: '10px', color: '#ffffff' }}>
              <Typography variant='h6' sx={{fontSize:'18px'}}>{t('LOAN_APPLICATION')}</Typography>
            </Box>
            <Stepper
              activeStep={activeStep}
              orientation="vertical"
              connector={<ColorlibConnector />}
              sx={{
                '& .MuiStepConnector-line': {
                  minHeight: '14px',
                },
              }}
            >
              {steps?.map((item, index) => (
                <Step
                  key={item.label}
                  completed={completed.includes(item.key)}
                  className={classNameForTitle(item)}
                  onClick={setActiveStep.bind(
                    null,
                    classNameForTitle(item) === 'disabled-title' ? activeStep : index
                  )}
                >
                  <StepLabels> {t(item.label)}</StepLabels>
                </Step>
              ))}
            </Stepper>
          </ContentSections>
        </StepperBox>
      </SideMenu>
      <MainContent item xs={10} container>
        <HeaderSection item container>
          <Box sx={{display: 'flex', alignItems: 'center'}}>
            <Typography sx={{ fontWeight: '600', fontSize: '1.3rem' }}>{t(steps?.[activeStep]?.label)}</Typography>
          </Box>
          <Box display="flex" alignItems="center" sx={{ position: 'fixed', top: '15px', right: '15px', zIndex: 1 }}>
            <Box>
              <LanguageIconStyle src={LanguageIconLight} alt="Language" onClick={openMenu} />
              <Menu disableScrollLock anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={closeMenu}>
                <MenuItem disableScrollLock onClick={() => handleLanguageChange('en')}>English</MenuItem>
                <MenuItem disableScrollLock onClick={() => handleLanguageChange('hi')}>हिंदी</MenuItem>
              </Menu>
            </Box>
            <AccountCircleIcon style={{ cursor: 'pointer', fontSize: '35px' }} onClick={handlePopoverOpen} />
            <Popover
              open={isPopoverOpen}
              anchorEl={popoverAnchorEl}
              onClose={handlePopoverClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              PaperProps={{
                sx: {
                  marginTop: '8px',
                  padding: '10px',
                  borderRadius: '4px',
                },
              }}
              disableScrollLock

            >
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  p: 1,
                  position: 'relative',
                  '&::before': {
                    content: '""',
                    position: 'absolute',
                    top: '-8px',
                    left: '60%',
                    transform: 'translateX(-50%)',
                    width: 0,
                    height: 0,
                    borderLeft: '8px solid transparent',
                    borderRight: '8px solid transparent',
                    borderBottom: '8px solid white',
                  },
                }}
              >
                <Box sx={{width:'200px',}}>
                  <Typography variant="body2" sx={{ margin: 0, color: '#333', fontWeight: 'bold' }}>
                  {`${firstName} ${displayLastName}`.trim()}
                  </Typography>
                  <Typography variant="body2" sx={{ margin: '4px 0', color: '#666' }}>
                    {emailId}
                  </Typography>
                  <Box
                    sx={{
                      marginTop: 2,
                      borderTop: '1px solid #eee',
                      paddingTop: 1,
                    }}
                  >
                    <Button
                      startIcon={<ExitToAppIcon />}
                      onClick={handleLogout}
                      sx={{
                        color: '#F05252',
                        textTransform: 'none',
                        fontWeight: '400',
                      }}
                    >
                      Sign Out
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Popover>
            {/* <Divider orientation="vertical" flexItem sx={{ margin: '0 16px', backgroundColor: '#B0B0B0' }} />
            <Typography sx={{ fontWeight: '600', cursor: 'pointer' }} color="#233685" onClick={handleLogout}>
            {t('LOGOUT')}
            </Typography> */}
          </Box>
        </HeaderSection>
        <ContentSection item>{steps?.[activeStep]?.step.element}</ContentSection>

        {/* <ErrorMessage /> */}
      </MainContent>
    </WebContainerStyle>
  );
}

CommonHeader.propTypes = {
  steps: PropTypes.array,
  webViewOnly: PropTypes.bool,
  children: PropTypes.node,
};

export default memo(CommonHeader);

