// @mui
import React from 'react';
import { DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton } from '@mui/material';
import PropTypes from 'prop-types';
import { LoadingButton } from '@mui/lab';
import CloseIcon from '@mui/icons-material/Close';

import { useTranslation } from 'react-i18next';
import { photosForOcrDialogStyle as DialogStyle } from '../../styled-components';

const DeleteModal = ({
  deleteModal: { txtMessage, question, ...deleteModal },
  handleModalClose,
  onDeleteFileModal,
}) => {
  const { t } = useTranslation('translation');

  return (
    <>
      {deleteModal.state && (
        <DialogStyle onClose={handleModalClose} open={deleteModal.state}>
          <DialogTitle>
            {t(txtMessage)}
            <IconButton
              aria-label="close"
              onClick={handleModalClose}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon sx={{fontSize:'20px'}} />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <DialogContentText>{t(question)}</DialogContentText>
          </DialogContent>
          <DialogActions>
            <LoadingButton
              fullWidth
              size="large"
              type="button"
              onClick={handleModalClose}
              variant="contained"
              sx={(theme) => ({
                '&.MuiButtonBase-root': {
                  textTransform: 'capitalize!important',
                  width:'80px!important'
          },
                background: theme.palette.offWhite,
                color: theme.palette.common.black,
                '&:hover': {
                  background: "#E2DDDD",
                  color: theme.palette.common.black,
                },
              })}
            >
              {t('CANCEL')}
            </LoadingButton>
            <LoadingButton
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              onClick={onDeleteFileModal.bind(null, deleteModal.deleteDocType)}
              sx={(theme) => ({
                '&.MuiButtonBase-root': {
                  textTransform: 'capitalize!important',
                  width:'80px!important'
                },
                background: "#8E1A10",
                color: theme.palette.common.white,
                '&:hover': {
                  background: "#F26157",
                  color: theme.palette.common.white,
                },
              })}
            >
              {t('DELETE')}
            </LoadingButton>
          </DialogActions>
        </DialogStyle>
      )}
    </>
  );
};

DeleteModal.propTypes = {
  deleteModal: PropTypes.object.isRequired,
  handleModalClose: PropTypes.func.isRequired,
  onDeleteFileModal: PropTypes.func.isRequired,
};

export default React.memo(DeleteModal);
