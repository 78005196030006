import React, { useCallback, useEffect } from 'react';
import { Button, Grid, Stack, Typography } from '@mui/material';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import MobileDatePicker from '@mui/lab/MobileDatePicker';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { LoadingButton } from '@mui/lab';
import { RHFPhoneNumberInput, RHFRadio, RHFSelectbox, RHFTextField } from '../components/hook-form';
import { formDefaultValues } from '../constants';

const   IdentityVerification = ({
  watch,
  getValues,
  isApplicantDetailsDisabledAfterPANVerification,
  convertTextToUpperCase,
  onDateChange,
  t,
  tabValue,
  setTabValue,
  setIdentifyVerificationNextButton,
  identifyVerificationNextButton,
}) => {

    const formfor = 'applicant';
    const watchcustomerType =  watch('applicant.applicantMyDetails.customerType');
    const watchOccupation= watch('applicant.applicantMyDetails.occupation');
    const watchMSME= watch('applicant.SELF_EMPLOYED_BUSINESSPERSON.isThisAnMSME');
    const watchMSMENonIndividual= watch('applicant.NONINDIVIDUAL.isThisAnMSME');
    const watchAccountId= watch('applicant.applicantMyDetails.crmAccountId');
    const watchIndustry= watch('applicant.NONINDIVIDUAL.industry');

    const requiredFields = [
      `${formfor}.applicantMyDetails.identification`,
      `${formfor}.applicantMyDetails.occupation`,
      watch(`${formfor}.applicantMyDetails.identification`) === 'AADHAR_CARD_NUMBER' 
        ? `${formfor}.applicantMyDetails.aadharCardNumber` 
        : watch(`${formfor}.applicantMyDetails.identification`) === 'VOTER_ID' 
          ? `${formfor}.applicantMyDetails.voterId` 
          : watch(`${formfor}.applicantMyDetails.identification`) === 'DRIVING_LICENSE' 
            ? `${formfor}.applicantMyDetails.drivingLicense` 
            : null
    ];


    const allFieldsFilled = useCallback(() => {
      return requiredFields.every(field => {
        const value = getValues(field);
        return value !== undefined && value !== null && value !== '';
      });
    }, [getValues, requiredFields]);

    useEffect(() => {
      setIdentifyVerificationNextButton(!allFieldsFilled());
    }, [allFieldsFilled, setIdentifyVerificationNextButton]);

    const DisplayOtherContents = useCallback(
        () =>
          watchcustomerType === 'INDIVIDUAL' && watchOccupation === 'SELF_EMPLOYED_PROFESSIONAL' ? (
            <Grid container spacing={3}>
              {/* employement - self employed professional */}
              <Grid item md={4}>
                <RHFSelectbox
                  name={`${formfor}.SELF_EMPLOYED_PROFESSIONAL.constitutionOfCompany`}
                  label="CONSTITUTION_OF_COMPANY"
                  menus={formDefaultValues.constitutionOfCompanyValue}
                  required
                />
             </Grid>
             <Grid item md={4}>
                <RHFTextField
                  name={`${formfor}.SELF_EMPLOYED_PROFESSIONAL.profession`}
                  label="PROFESSION"
                  onlyText
                  required
                />
               </Grid>
               <Grid item md={4}>
                <RHFSelectbox
                  name={`${formfor}.SELF_EMPLOYED_PROFESSIONAL.sector`}
                  label="SECTOR"
                  menus={formDefaultValues.sectorValues}
                  required
                />
            </Grid>
            <Grid item md={4}>
                <RHFSelectbox
                  name={`${formfor}.SELF_EMPLOYED_PROFESSIONAL.subSector`}
                  label="SUB_SECTOR"
                  menus={formDefaultValues.subSectorValuesForSEP}
                  required
                />
             </Grid>
             <Grid item md={4}>
                <RHFTextField name={`${formfor}.SELF_EMPLOYED_PROFESSIONAL.officialEmailId`} label="OFFICIAL_EMAIL" />
                </Grid>
                <Grid item md={4}>
                <RHFPhoneNumberInput
                  sx={{
                    height: '38px',
                    padding: '5px 15px',
                    boxSizing: 'border-box',
                    marginTop: '0!important',
                    borderRadius: '10px'
                  }} 
                    hideFlag 
                  name={`${formfor}.SELF_EMPLOYED_PROFESSIONAL.officialPhoneNumber`}
                  label="OFFICIAL_PHONE"
                />
              </Grid>
              <Grid item md={4}>
                <RHFTextField
                  name={`${formfor}.SELF_EMPLOYED_PROFESSIONAL.address`}
                  label={t(`COMPANY_ADDRESS`)}
                  multiline
                  rows={2}
                  required
                />
              </Grid>
              <Grid item md={4}>
                <RHFTextField
                  name={`${formfor}.SELF_EMPLOYED_PROFESSIONAL.monthlyNetIncome`}
                  label="MONTHLY_NET_INCOME"
                  type="number"
                  required
                />
             </Grid>
              <Grid item md={4}>
                <RHFRadio
                  name={`${formfor}.SELF_EMPLOYED_PROFESSIONAL.itrFiledForIncomeDeclared`}
                  title="ITR_FIELD"
                  labels={[
                    { name: 'YES', value: true },
                    { name: 'NO', value: false },
                  ]}
                  required
                />
             </Grid>
             
            </Grid>
          ) : (
            watchcustomerType === 'INDIVIDUAL' &&
            ['BANK_SALARIED_GOVT_PSU', 'BANK_SALARIED_PRIVATE', 'CASH_SALARIED'].includes(watchOccupation) > -1 && (
              <Grid container spacing={3}>
                
                <Grid item md={4}>
                  <RHFTextField name={`${formfor}.bankOrCash.nameOfCompany`} label="NAME_OF_COMPANY" required />
                  </Grid>
                  <Grid item md={4}>
                  <RHFTextField name={`${formfor}.bankOrCash.officialEmailId`} label="OFFICIAL_EMAIL" />
                  </Grid>
                  <Grid item md={4}>
                  <RHFSelectbox
                    name={`${formfor}.bankOrCash.sector`}
                    label="SECTOR"
                    menus={formDefaultValues.sectorValues}
                    required
                  />
              </Grid>
              <Grid item md={4}>
                  <RHFSelectbox
                    name={`${formfor}.bankOrCash.subSector`}
                    label="SUB_SECTOR"
                    menus={
                      (watchOccupation === 'BANK_SALARIED_GOVT_PSU' && formDefaultValues.subSectorForGovtPSU) ||
                      (watchOccupation === 'BANK_SALARIED_PRIVATE' && formDefaultValues.subSectorForBankSalaried) ||
                      formDefaultValues.subSectorForCashSalaried
                    }
                    required
                  />
                 </Grid>
                 <Grid item md={4}>
                 <RHFPhoneNumberInput
                  sx={{
                    height: '38px',
                    padding: '5px 15px',
                    boxSizing: 'border-box',
                    marginTop: '0!important',
                    borderRadius: '10px'
                  }} 
                    hideFlag 
                  name={`${formfor}.bankOrCash.officialPhoneNumber`} 
                  label="OFFICIAL_PHONE" />
                  </Grid>
                  <Grid item md={4}>
                  <RHFTextField
                    name={`${formfor}.bankOrCash.monthlyNetIncome`}
                    label="MONTHLY_NET_INCOME"
                    type="number"
                    required
                  />
               </Grid>
               <Grid item md={4}>
                  <RHFTextField
                    name={`${formfor}.bankOrCash.address`}
                    label={t(`COMPANY_ADDRESS`)}
                    multiline
                    rows={2}
                    required
                  />
                </Grid>{' '}
              </Grid>
            )
          ),
        [watchcustomerType, watchOccupation]
      );

      const MsmeBlockOptions = useCallback(
        ({ watcForhMSME, fieldFor, showTooltipState }) =>
          `${watcForhMSME}` === 'true' ? (
            <Grid container>
              <Grid item md={4}>
                <RHFRadio
                  name={`${fieldFor}.msmeClassification`} 
                  title="MSME_CLASSIFICATION"
                  labels={[
                    { name: 'MICRO', value: 'MICRO' },
                    { name: 'SMALL', value: 'SMALL' },
                    { name: 'MEDIUM', value: 'MEDIUM' },
                  ]}
                  required
                />
                 </Grid>
                 <Grid item md={4}>
                <RHFRadio
                  name={`${fieldFor}.isTheMSMEUdyamRegistered`}
                  title="UDHYAM_REGISTRATION"
                  labels={[
                    { name: 'YES', value: true },
                    { name: 'NO', value: false },
                  ]}
                  showTooltip
                  required
                  tooltipOpen={showTooltipState}
                  // onChange={handleRadioChange.bind(this, { setTooltip: setShowUdhynamTooltip.bind(this), type: 'uddhyam' })}
                  // setShowTooltip={setShowUdhynamTooltip}
                  tooltipText={
                    <>
                      <span className="tooltiphead">{t('UDHYAM_REG_UPLOAD')}</span>
                    </>
                  }
                  // tooltipPlacement={tooltipPlacement}
                />
                </Grid>
            </Grid>
          ) : null,
        [watchMSME]
      );
  

      return (
        <>
        <Grid container spacing={3} mt={2}>
          <Grid item xs={12} sm={6} md={4}>
            <RHFSelectbox
              name={`${formfor}.applicantMyDetails.identification`}
              label="IDENTIFICATION_DOCUMENT"
              menus={formDefaultValues.Identification}
              required />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            {watch(`${formfor}.applicantMyDetails.identification`) === 'AADHAR_CARD_NUMBER' && (
              <RHFTextField
                name={`${formfor}.applicantMyDetails.aadharCardNumber`}
                label="AADHAR_CARD_NUMBER"
                type="number"
                maxLength={12}
                disabled={getValues().applicant?.applicantMyDetails?.aadharCardNumber?.length > 0
                  ? isApplicantDetailsDisabledAfterPANVerification
                  : false} />
            )}
            {watch(`${formfor}.applicantMyDetails.identification`) === 'VOTER_ID' && (
              <RHFTextField
                name={`${formfor}.applicantMyDetails.voterId`}
                label="VOTER_ID"
                alphaNumeric
                voterCardNo
                inputProps={{ maxLength: 10 }}
                hanleBlur={convertTextToUpperCase.bind(this, `${formfor}.applicantMyDetails.voterId`)}
                allcaps
                disabled={getValues().applicant?.applicantMyDetails?.voterId?.length > 0
                  ? isApplicantDetailsDisabledAfterPANVerification
                  : false} />
            )}
            {watch(`${formfor}.applicantMyDetails.identification`) === 'DRIVING_LICENSE' && (
              <RHFTextField
                name={`${formfor}.applicantMyDetails.drivingLicense`}
                label="DRIVING_LICENSE"
                alphaNumericSlash
                inputProps={{ maxLength: 16 }}
                maxLength={16}
                disabled={getValues().applicant?.applicantMyDetails?.drivingLicense?.length > 0
                  ? isApplicantDetailsDisabledAfterPANVerification
                  : false} />
            )}
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            {/* blank */}
          </Grid>

          {/* Second Row */}
          <Grid item xs={12} sm={6} md={4}>
            <RHFSelectbox
              name={`${formfor}.applicantMyDetails.residencyStatus`}
              label="RESIDENCY_STATUS"
              menus={formDefaultValues.RESIDENCY_STATUS}
              required />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <RHFSelectbox
              name={`${formfor}.applicantMyDetails.occupation`}
              label="OCCUPATION"
              menus={formDefaultValues.OCCUPATION}
              required />
          </Grid>
          {!(['STUDENT', 'NOT_WORKING', 'HOME_MAKER', 'RETIRED_PENSIONER', undefined].includes(watchOccupation)) ?
          <Grid item xs={12} sm={12} md={12}>
            <Typography variant="h4">{t('EMPLOYMENT_INFORMATINOS')}</Typography>

            <Grid container mt={5}>
              {watchcustomerType === 'INDIVIDUAL' && (watchOccupation === null || watchOccupation === '') ? null : (
                watchAccountId === '' &&
                !['STUDENT', 'NOT_WORKING', 'HOME_MAKER', 'RETIRED_PENSIONER'].includes(watchOccupation) && (
                  watchcustomerType === 'NONINDIVIDUAL' && (
                    <Grid item xs={12} sm={12} md={12}>
                      <Grid item xs={12} sm={12} md={4}>
                        <RHFTextField
                          name={`${formfor}.NONINDIVIDUAL.entityName`}
                          label="ENTITY_NAME"
                          required />
                      </Grid>
                      <Grid item xs={12} sm={12} md={4}>
                        <RHFSelectbox
                          name={`${formfor}.NONINDIVIDUAL.constitutionOfCompany`}
                          label="CONSTITUTION_OF_COMPANY"
                          menus={[
                            { name: 'PUBLIC_PRIVATE_PARTNERSHIP', value: 'PUBLIC_PRIVATE_PARTNERSHIP' },
                            { name: 'JOINT_VENTURE', value: 'JOINT_VENTURE' },
                            ...formDefaultValues.constitutionOfCompanyValue,
                          ]}
                          required />
                      </Grid>
                      <Grid item xs={12} sm={12} md={4}>
                        <RHFSelectbox
                          name={`${formfor}.NONINDIVIDUAL.sector`}
                          label="SECTOR"
                          menus={formDefaultValues.sectorValues}
                          required />
                      </Grid>
                      <Grid item xs={12} sm={12} md={4}>
                        <RHFSelectbox
                          name={`${formfor}.NONINDIVIDUAL.industry`}
                          label="INDUSTRY"
                          menus={formDefaultValues.INDUSTRY}
                          required />
                      </Grid>
                      <Grid item xs={12} sm={12} md={4}>
                        <RHFSelectbox
                          name={`${formfor}.NONINDIVIDUAL.subIndustry`}
                          label="SUB_INDUSTRY"
                          menus={formDefaultValues.SUB_INDUSTRY[0][watchIndustry]}
                          required />
                      </Grid>
                      <Grid item xs={12} sm={12} md={4}>
                        <RHFTextField
                          name={`${formfor}.NONINDIVIDUAL.officialEmailId`}
                          label="OFFICIAL_EMAIL" />
                      </Grid>
                      <Grid item xs={12} sm={12} md={4}>
                        <RHFPhoneNumberInput
                          sx={{
                            height: '38px',
                            padding: '5px 15px',
                            boxSizing: 'border-box',
                            marginTop: '0!important',
                            borderRadius: '10px'
                          }}
                          hideFlag
                          name={`${formfor}.NONINDIVIDUAL.officialPhoneNumber`}
                          label="OFFICIAL_PHONE" />
                      </Grid>
                      <Grid item xs={12} sm={12} md={4}>
                        <RHFTextField
                          name={`${formfor}.NONINDIVIDUAL.monthlyNetIncome`}
                          label="MONTHLY_NET_INCOME"
                          type="number"
                          required />
                      </Grid>
                      <Grid item xs={12} sm={12} md={4}>
                        <RHFTextField
                          name={`${formfor}.NONINDIVIDUAL.gstNo`}
                          label="GST_NO"
                          alphaNumeric
                          inputProps={{ maxLength: 15 }}
                          hanleBlur={convertTextToUpperCase.bind(this, `${formfor}.NONINDIVIDUAL.gstNo`)}
                          allcaps
                          required />
                      </Grid>
                      <Grid item xs={12} sm={12} md={4}>
                        <RHFTextField
                          name={`${formfor}.NONINDIVIDUAL.companyWebsite`}
                          label="COMPANY_WEBSITE"
                          required />
                      </Grid>
                      <Grid item xs={12} sm={12} md={4}>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <MobileDatePicker
                            inputFormat="dd/MM/yyyy"
                            maxDate={new Date(new Date().getTime()).setDate(new Date().getDate() - 1)}
                            value={watch(`${formfor}.NONINDIVIDUAL.dateOfEstablishment`) || null}
                            onChange={onDateChange.bind(this, `${formfor}.NONINDIVIDUAL.dateOfEstablishment`)}
                            renderInput={(params) => (
                              <RHFTextField
                                {...params}
                                name={`${formfor}.NONINDIVIDUAL.dateOfEstablishment`}
                                label="DATE_OF_ESTABLISHMENT"
                                dateField
                                required />
                            )} />
                        </LocalizationProvider>
                      </Grid>
                      <Grid item xs={12} sm={12} md={4}>
                        <RHFRadio
                          name={`${formfor}.NONINDIVIDUAL.msmeBlock`}
                          label="MSME_BLOCK"
                          required
                          menus={MsmeBlockOptions} />
                      </Grid>
                      {watchMSMENonIndividual === 'MSME' && (
                        <Grid item xs={12} sm={12} md={4}>
                          <RHFRadio
                            name={`${formfor}.NONINDIVIDUAL.msme`}
                            label="MSME_CLASSIFICATION"
                            required
                            menus={formDefaultValues.MSME} />
                        </Grid>
                      )}
                      {watchMSME !== 'MEDIUM' && <DisplayOtherContents formfor={formfor} t={t} />}
                    </Grid>
                  )

                )
              )}
              {watchcustomerType === 'INDIVIDUAL' && watchOccupation === 'SELF_EMPLOYED_BUSINESSPERSON' ? (
                <Grid item xs={12} sm={12} md={12}>
                  {/* employement - self employed Non-professional (Businessman) */}
                  <Grid container spacing={3}>

                    <Grid item md={4}>
                      <RHFSelectbox
                        name={`${formfor}.SELF_EMPLOYED_BUSINESSPERSON.constitutionOfCompany`}
                        label="CONSTITUTION_OF_COMPANY"
                        menus={formDefaultValues.constitutionOfCompanyValue}
                        required />
                    </Grid>

                    <Grid item md={4}>
                      <RHFTextField
                        name={`${formfor}.SELF_EMPLOYED_BUSINESSPERSON.nameOfEnterprise`}
                        label="NAME_OF_ENTERPRISE"
                        required />
                    </Grid>

                    <Grid item md={4}>
                      <RHFSelectbox
                        name={`${formfor}.SELF_EMPLOYED_BUSINESSPERSON.sector`}
                        label="SECTOR"
                        menus={formDefaultValues.sectorValues}
                        required />
                    </Grid>

                    <Grid item md={4}>
                      <RHFSelectbox
                        name={`${formfor}.SELF_EMPLOYED_BUSINESSPERSON.subSector`}
                        label="SUB_SECTOR"
                        menus={formDefaultValues.subSectorValuesForSENP}
                        required />
                    </Grid>

                    <Grid item md={4}>
                      <RHFTextField
                        name={`${formfor}.SELF_EMPLOYED_BUSINESSPERSON.officialEmailId`}
                        label="OFFICIAL_EMAIL" />
                    </Grid>

                    <Grid item md={4}>
                      <RHFPhoneNumberInput
                        sx={{
                          height: '38px',
                          padding: '5px 15px',
                          boxSizing: 'border-box',
                          marginTop: '0!important',
                          borderRadius: '10px'
                        }}
                        hideFlag
                        name={`${formfor}.SELF_EMPLOYED_BUSINESSPERSON.officialPhoneNumber`}
                        label="OFFICIAL_PHONE" />
                    </Grid>

                    <Grid item md={4}>
                      <RHFTextField
                        name={`${formfor}.SELF_EMPLOYED_BUSINESSPERSON.monthlyNetIncome`}
                        label="MONTHLY_NET_INCOME"
                        type="number"
                        required />
                    </Grid>

                    <Grid item md={4}>
                      <RHFTextField
                        name={`${formfor}.SELF_EMPLOYED_BUSINESSPERSON.gstNo`}
                        label="GST_NO"
                        alphaNumeric
                        inputProps={{ maxlength: 15 }}
                        hanleBlur={convertTextToUpperCase.bind(this, `${formfor}.SELF_EMPLOYED_BUSINESSPERSON.gstNo`)}
                        allcaps
                        required />
                    </Grid>

                    <Grid item md={4}>
                      <RHFRadio
                        name={`${formfor}.SELF_EMPLOYED_BUSINESSPERSON.isThisAnMSME`}
                        title="MSME"
                        labels={[
                          { name: 'YES', value: true },
                          { name: 'NO', value: false },
                        ]}
                        required />
                    </Grid>

                    <Grid item md={4}>
                      <RHFRadio
                        name={`${formfor}.SELF_EMPLOYED_BUSINESSPERSON.itrFiledForIncomeDeclared`}
                        title="ITR_FIELD"
                        labels={[
                          { name: 'YES', value: true },
                          { name: 'NO', value: false },
                        ]}
                        required />
                    </Grid>

                    <MsmeBlockOptions
                      watcForhMSME={watchMSME}
                      fieldFor={`${formfor}.SELF_EMPLOYED_BUSINESSPERSON`} />
                  </Grid>
                </Grid>
              ) : (
                <DisplayOtherContents />
              )}
            </Grid>
          </Grid>
          : null
        } 

          {/* Third Row */}

        </Grid>
        
        
        
        <Stack
          direction="row"
          justifyContent="space-between"
          mt={5}
          sx={{
            position: 'fixed',
            bottom: 0,
            left: '225px',
            right: 0,
            bgcolor: 'white',
            padding: '10px',
          }}
        >
            <Button
              onClick={() => { setTabValue(tabValue - 1); } }
              variant="outlined"
              sx={{
                minWidth: 120,
                color: "#1B2A68",
                borderColor: "#1B2A68",
                '&:hover': {
                  bgcolor: "#2A3C8B",
                  color: "#FFFFFF",
                }
              }}
            >
              {t('PREVIOUS')}
            </Button>
            <LoadingButton
              size="large"
              variant="contained"
              disabled={identifyVerificationNextButton}
              onClick={() => { setTabValue(tabValue + 1); } }
              sx={{ minWidth: 120, bgcolor: "#1B2A68" }}
            >
              {t('NEXT')} ➜
            </LoadingButton>
          </Stack></>
  )}
      
    
    export default IdentityVerification;