import { Box, Button, Grid, IconButton, InputAdornment, Menu, MenuItem, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { FormProvider, RHFTextField } from "../components/hook-form";
import { ContentContainer, FooterLogoStyle, GridContainer, ImageContainer, LanguageIconStyle, StyledImage } from "../styled-components/loginForm";
import LoginImageOverlap from '../assets/images/overlayLoginImage.png';
import LanguageIcon from '../assets/images/languageIcon.svg';
import Logo from '../assets/images/newCompanyLogo.png';
import FooterLogo from '../assets/images/footerLogo.svg';
import { resetPassword } from "../services";
import { toasterService } from "../helper";

export const ResetPassword = () => {
    const { t } = useTranslation();
    const [showPassword, setShowPassword] = useState(false);
    const [touchedFields, setTouchedFields] = useState({ password: false, confirmPassword: false });
    const methods = useForm();
    const navigate = useNavigate();
    const { handleSubmit, watch, setError, clearErrors, formState: { errors } } = methods;
    const [anchorEl, setAnchorEl] = useState(null);

    const location = useLocation();
    const code = new URLSearchParams(location.search).get('code');

    const handleTogglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const handleLanguageMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleLanguageMenuClose = () => {
        setAnchorEl(null);
    };

    const onSubmit = async (data) => {
        try {
            const res = await resetPassword({ data, uniqueId: code });
            if (res && res.data) {
                toasterService(res.data.message || 'Application data submitted successfully');
                navigate('/login')
            }
            toasterService(res.error.error.message);

        } catch (error) {
            console.error("Error resetting password:", error);
        }
    };

    const password = watch('password');
    const confirmPassword = watch('confirmPassword');

    const validatePassword = (value) => {
        if (touchedFields.password) {
            if (!value) {
                setError('password', {
                    type: 'manual',
                    message: 'Password is required',
                });
            } else if (value.length < 6) {
                setError('password', {
                    type: 'manual',
                    message: 'Password must be at least 6 characters long',
                });
            } else if (!/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{6,}$/.test(value)) {
                setError('password', {
                    type: 'manual',
                    message: 'Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character',
                });
            } else {
                clearErrors('password');
            }
        }
    };

    useEffect(() => {
        validatePassword(password);

        if (touchedFields.confirmPassword) {
            if (confirmPassword && password !== confirmPassword) {
                setError('confirmPassword', {
                    type: 'manual',
                    message: 'Passwords do not match',
                });
            } else {
                clearErrors('confirmPassword');
            }
        }
    }, [password, confirmPassword, touchedFields, setError, clearErrors]);

    const handleFieldBlur = (field) => {
        setTouchedFields((prev) => ({ ...prev, [field]: true }));
    };

    const isButtonDisabled = 
        !password ||
        !confirmPassword ||
        password !== confirmPassword ||
        !!errors.password ||
        !!errors.confirmPassword;

    return (
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <GridContainer container>
                <ImageContainer item xs={6}>
                    <StyledImage src={LoginImageOverlap} alt="Overlay" />
                </ImageContainer>
                <Grid item xs={6} sx={{ position: 'relative' }}>
                    <ContentContainer container>
                        <Grid
                            item
                            xs={12}
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                        >
                            <Box
                                component="img"
                                src={Logo}
                                alt="Logo"
                                sx={{
                                    maxWidth: '100px',
                                    marginTop: '20px',
                                }}
                            />
                           <LanguageIconStyle src={LanguageIcon} alt="Language" onClick={handleLanguageMenuOpen} />
                                <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleLanguageMenuClose}>
                                    <MenuItem onClick={() => handleLanguageMenuClose}>English</MenuItem>
                                    <MenuItem onClick={() => handleLanguageMenuClose}>हिंदी</MenuItem>
                                </Menu>
                           
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            sx={{
                                paddingTop: '20px',
                                maxHeight: 'calc(100vh - 130px)',
                                overflow: 'auto',
                                '&::-webkit-scrollbar': { display: 'none' },
                            }}
                        >
                            <Grid item xs={12} display="flex" justifyContent="center">
                                <Box>
                                <Box
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                                >
                                <Box width="100%" maxWidth="400px" padding="20px" >
                                  
                                        <Typography variant="h5" color="#272727" component="h1" gutterBottom>
                                            Reset Password
                                        </Typography>
                                        <Typography variant="body2" gutterBottom sx={{ color: '#272727', opacity: '0.85' }}>
                                            Please choose a strong password
                                        </Typography>
                                        <Grid container spacing={2} mt={2}>
                                            <Grid item xs={12}>
                                                <RHFTextField
                                                    placeholder={t('PASSWORD')}
                                                    type={showPassword ? 'text' : 'password'}
                                                    name="password"
                                                    fullWidth
                                                    sx={{
                                                        marginBottom: '10px',
                                                        backgroundColor: '#F5F6F8',
                                                        '& .MuiInputBase-input::placeholder': {
                                                            color: 'rgba(39, 39, 39, 0.4)',
                                                        },
                                                    }}
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                <IconButton
                                                                    edge="end"
                                                                    sx={{
                                                                        cursor: 'pointer',
                                                                        '&:hover': {
                                                                            backgroundColor: 'transparent',
                                                                        },
                                                                    }}
                                                                    onClick={handleTogglePasswordVisibility}
                                                                >
                                                                    {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                                                </IconButton>
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                    onBlur={() => handleFieldBlur('password')}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <RHFTextField
                                                    placeholder="Confirm Password"
                                                    type={showPassword ? 'text' : 'password'}
                                                    name="confirmPassword"
                                                    fullWidth
                                                    sx={{
                                                        marginBottom: '10px',
                                                        backgroundColor: '#F5F6F8',
                                                        '& .MuiInputBase-input::placeholder': {
                                                            color: 'rgba(39, 39, 39, 0.4)',
                                                        },
                                                    }}
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                <IconButton
                                                                    edge="end"
                                                                    sx={{
                                                                        cursor: 'pointer',
                                                                        '&:hover': {
                                                                            backgroundColor: 'transparent',
                                                                        },
                                                                    }}
                                                                    onClick={handleTogglePasswordVisibility}
                                                                >
                                                                    {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                                                </IconButton>
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                    onBlur={() => handleFieldBlur('confirmPassword')}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Button
                                                    type="submit"
                                                    variant="contained"
                                                    color="primary"
                                                    fullWidth
                                                    disabled={isButtonDisabled}
                                                    sx={{
                                                        mt: 2,
                                                        background: '#192C79',
                                                        padding: '10px !important',
                                                        borderRadius: '3px',
                                                        boxShadow: 'none',
                                                        '&:hover': {
                                                            background: '#192C79',
                                                        },
                                                    }}
                                                >
                                                    Save Password
                                                </Button>
                                            </Grid>
                                        </Grid>
                                        </Box>
                                        </Box>
                                   
                                </Box>
                            </Grid>
                        </Grid>
                    </ContentContainer>
                    <Grid item xs={12} sx={{ position: 'absolute', bottom: '20px', width: '100%' }}>
                        <FooterLogoStyle src={FooterLogo} alt="Footer Logo" />
                    </Grid>
                </Grid>
            </GridContainer>
        </FormProvider>
    );
};
