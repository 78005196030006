import { httpService } from '../helper';
import { constants } from '../constants';

export const doLogin = async (data) => httpService().post(`${constants.API.LOGIN}`, data, false);

export const doLoginViaEmail =  async (data) => httpService().post(`${constants.API.LOGINVIAEMAIL}`, data, false);

export const forgotPassword = async (data) => httpService().post(`${constants.API.FORGOT_PASSWORD}`, data, false);

export const resetPassword = async ({data, uniqueId}) => {
    const endpoint = `${constants.API.RESET_PASSWORD}/${uniqueId}`;
    return httpService().post(endpoint, data, false);
  };
  



