import { useEffect, useState } from 'react';
import constantObject from '../constants/formDefaultValues';

const { PROVISIONAL_OFFER_MATRIX } = constantObject;
const useProvisionalCalculation = (offerDataInitial, dataForCalculation, applicableInterestRate, minimumLoanAmount, minimumLoanTenure) => {

  const [value1, setValue1] = useState('');
  const [value2, setValue2] = useState('');
  const [result, setResult] = useState(''); 

  const offerData = {};

  useEffect(() => {
    if (value1 && offerDataInitial) {
      offerDataInitial.applicableLoanAmount = value1;
    }

    if (value2) {
      offerDataInitial.loanTenureInMonthsC4 = value2;
    }

    calculateOfferValue(
      dataForCalculation,
      !value1 || value1 === "" ? minimumLoanAmount : value1,
      !value2 || value2 === "" ? minimumLoanTenure : value2,
      applicableInterestRate
    );
    setResult(offerData);
  }, [value1, value2, offerDataInitial]);

  const handleValue1Change = (event) => {
    setValue1(event);
  };

  const handleValue2Change = (event) => {
    setValue2(event);
  };
 
  const calculateOfferValue = (dataForCalculation, value1, value2, applicableInterestRate) => {
    const roiAsPerGridForResponse = getROIFromMatrix(dataForCalculation)?.roi;
    const roiAsPerGridC18 = ((roiAsPerGridForResponse?.split('%')[0] / 100) / 12);
    const calculateEMIByLoanAndTenure = Math.round(
      (value1 * roiAsPerGridC18 * ((1 + roiAsPerGridC18) ** parseInt(value2, 10))) /
      (((1 + roiAsPerGridC18) ** parseInt(value2, 10)) - 1)
    );
    offerData.applicableTenure = parseInt(value2, 10);
    offerData.applicableLoanAmount = parseInt(value1, 10);
    offerData.applicableEmiForLoanAmountAndTermOffered = calculateEMI(value1, applicableInterestRate, value2);
    offerData.applicableAplicationFee = getApplicationFee(value1);
    offerData.applicableInterest = roiAsPerGridForResponse;
  };

  const getROIFromMatrix = (dataForCalculation) => {
    const {
      productClassificationC6,
      propertyCategoryC13,
      occupationC7,
      itrFiledForIncomeDeclaredC10,
      requiredLoanAmountC3,
      declaredMonthlyEmiC11,
      loanTenureInMonthsC4,
      monthlyNetIncomeC9,
    } = dataForCalculation;

    const roiAndTenureData = {
      roi: '',
      tenure: '',
    };

    if (productClassificationC6 === 'HL') {
      roiAndTenureData.roi =
        PROVISIONAL_OFFER_MATRIX[productClassificationC6][propertyCategoryC13][itrFiledForIncomeDeclaredC10].ROI[
        occupationC7
        ];

      roiAndTenureData.tenure =
        PROVISIONAL_OFFER_MATRIX[productClassificationC6][propertyCategoryC13][itrFiledForIncomeDeclaredC10].TENURE[
        occupationC7
        ];
    }

    if (productClassificationC6 === 'NHL') {
      const amountCategory = loanAmountCategory(requiredLoanAmountC3);

      if (productClassificationC6 && propertyCategoryC13 && amountCategory && occupationC7) {
        roiAndTenureData.roi =
          PROVISIONAL_OFFER_MATRIX[productClassificationC6][propertyCategoryC13][amountCategory].ROI[occupationC7];

        roiAndTenureData.tenure =
          PROVISIONAL_OFFER_MATRIX[productClassificationC6][propertyCategoryC13][amountCategory].TENURE[occupationC7];
      }
    }

    return roiAndTenureData;
  };

  const loanAmountCategory = (loanAmount) => {
    let category = null;

    if (loanAmount <= 1000000) {
      category = 'UPTO_10_LAC';
    } else if (loanAmount > 1000000 && loanAmount <= 2500000) {
      category = '10_TO_25_LAC';
    } else if (loanAmount > 2500000 && loanAmount <= 5000000) {
      category = '25_TO_50_LAC';
    } else {
      category = 'ABOVE_50_LAC';
    }

    return category;
  };

  const calculateEMI = (principal, annualRate, tenureYears) => {
    const monthlyRate = annualRate / (12 * 100); 
    const tenureMonths = tenureYears * 12; 
    const base = 1 + monthlyRate;
    const numerator = principal * monthlyRate * (base ** tenureMonths);
    const denominator = (base ** tenureMonths) - 1;
    const emi = numerator / denominator;
    return emi;
  };

  const getApplicationFee = (loanAmount) => {
    let amount = null;

    if (loanAmount <= 1500000) {
      amount = 1770;
    } else if (loanAmount > 1500000 && loanAmount <= 4000000) {
      amount = 2950;
    } else if (loanAmount > 4000000) {
      amount = 5900;
    }

    return amount;
  };

  return {
    result,
    handleValue1Change,
    handleValue2Change,
  };
};

export { useProvisionalCalculation };
