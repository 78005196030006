/* eslint-disable react/display-name */
import React, { useEffect, useState, useContext, useMemo, Fragment, useRef } from 'react';
import PropTypes from 'prop-types';
// @mui
import {
  Box,
  Stack,
  Button,
  Stepper,
  Step,
  StepLabel,
  Typography,
  DialogContent,
  InputAdornment,
  DialogContentText,
  useMediaQuery,
  Rating,
  TextField,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import { useForm } from 'react-hook-form';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import jwtDecode from 'jwt-decode';
import { useNavigate } from 'react-router-dom';
import { RHFTextField, FormProvider } from '../components/hook-form';
import {
  applicationStatusCardContainerStyle as CardContainerStyle,
  UploadDocumentsContainerStyle as UploadCardContainerStyle,
  AppStatusMobileColorlibConnector as MobileColorlibConnector,
  AppStatusColorlibConnector as ColorlibConnector,
  photosForOcrDialogStyle as DialogStyle,
  applicationFeedbackModuleStyle as FeedbackContainer,
} from '../styled-components';
import { formDefaultValues, constants } from '../constants';
import { CContext } from '../components/utils';
import { getApplicationStatus, getPendingDocList, postPendingDocList, postFeedBackForm } from '../services';
import { filterErrorMessage, getUniqueArray } from '../helper';

const { allAppliicationStatus, applicationStatus, DOC_TYPE } = formDefaultValues;

const PureInputFieldComponent = React.memo(({ seperator, value, fileName, getValues, ...item }) => {
  const ref = useRef();
  const { t } = useTranslation('translation');

  return (
    <Fragment key={item.name}>
      <Stack spacing={3} mb={5} width="100%">
        <RHFTextField
          inputRef={ref}
          disabled={item?.type === 'text' && item?.name.includes('CSR')}
          valueField={item?.type === 'text' && item?.name.includes('CSR') ? item?.remarks : undefined}
          {...item}
          {...{
            ...(item.type === 'file' && {
              InputProps: {
                endAdornment: (
                  <InputAdornment position="end">
                    <span className="fileIcon" onClick={() => ref.current.click()}>
                      <FileUploadIcon />
                    </span>
                  </InputAdornment>
                ),
              },
              InputLabelProps: { shrink: !!(fileName && value) },
            }),
          }}
        />

        {item.type === 'file' && fileName && value && (
          <Box
            sx={{
              position: 'absolute',
              marginTop: '17px !important',
              paddingLeft: '15px',
            }}
          >
            {fileName.name.length > 10 ? `${fileName.name.substr(0, 10)}..` : fileName.name}
          </Box>
        )}
        {item.type === 'text' && fileName && value && (
          <Box
            sx={{
              position: 'absolute',
              marginTop: '17px !important',
              paddingLeft: '15px',
            }}
          >
            {fileName}
          </Box>
        )}
      </Stack>
      {seperator && <Stack spacing={3} mb={5} className="divider" />}
    </Fragment>
  );
});

PureInputFieldComponent.propTypes = {
  seperator: PropTypes.bool,
  setAllDocs: PropTypes.func,
  getValues: PropTypes.func,
  value: PropTypes.any,
  fileName: PropTypes.any,
};

export default React.memo(() => {
  const { t } = useTranslation('translation');
  const [showSuccessModal, setShowSuccessoModal] = useState(false);
  const [applicantName, setApplicantName] = useState('');
  const [applicantId, setApplicantId] = useState('');
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [activeStep, setActiveStep] = useState(-1);
  const [showUploadDocuments, setShowUploadDocument] = useState(false);
  const [showUploadDocumentsButton, setShowUploadDocumentsButton] = useState(false);
  const [allDocs, setAllDocs] = useState(true);
  const [isFistTime, setIsFirstTime] = useState(true);
  const [reload, setReload] = useState(true);
  const [timeoutState, setTimeoutState] = useState(0);
  const [newFormCheckData, setNewForm] = useState(null);

  const [applicantNameSlice, setApplicantNameSlice] = useState(null);
  const isNonMobileScreens = useMediaQuery('(min-width:600px)');
  const [showFeedbackBox, setShowFeedbackBox] = useState(false);
  const [feedbackData, setFeedbackData] = useState({ rating: 0, comment: '' });

  const [isDocumentUploadOnly, setIsDocumentUploadOnly] = useState(false);
  const [remarksValue, setRemarksValue] = useState([]);
  const [showApplicationStatusProgress, setShowApplicationStatusProgress] = useState(false)
  const [ isFeedBackFormSubmitted , setIsFeedBackFormSubmitted] = useState(false)
  const getApplicationId = () => {
    const token = sessionStorage.getItem('authToken');
    const { applicationId } = jwtDecode(token);
    if (!applicationId) {
      return ` `;
    }
    return applicationId;
  };
  const [applicationId] = useState(getApplicationId);

   const {
    applicationProgress: { completed, allStepsCount },
    setErrorMessage,
    setShowLoader,
    setUpdateApplicationProgress,
  } = useContext(CContext);

  const navigate = useNavigate();


  // useEffect(() => {
  //   if (
  //     completed.length > 0 &&
  //     getUniqueArray([...completed, 'isOcrDocumentSaved']).length !== allStepsCount &&
  //     isFistTime
  //   ) {
  //     setUpdateApplicationProgress((prev) => !prev);
  //     setIsFirstTime(false);
  //   }
  // }, [completed]);

  const methods = useForm({
    mode: 'all',
    defaultValues: { applicant: {}, coApplicant: [] },
  });

  const handleFileInputChange = (name, e) => {
    const file = e.target.files[0];

    const dataCheck = newFormCheckData.map((item, index) => {
      if (Array.isArray(item)) {
        const findIndex = item.findIndex((ele) => ele.name === name);
        if (findIndex > -1) {
          const prev = [...item];
          prev[findIndex].value = file;
        }
      }
      return item;
    });

    setNewForm(dataCheck);

    // const index = newFormCheckData.findIndex((ele) => ele.name === name);
  };

	const { ROUTE } = constants;

  const handleFeedBackSubmitButton = async (applicationId) => {
    setShowLoader(true);
    const res = await postFeedBackForm(applicationId);
    if (res.status) {
      setIsFeedBackFormSubmitted(true)
    } else {
      setErrorMessage({ title: filterErrorMessage(res.error), show: true });
    }
    setShowLoader(false);
  }

  const setFormData = (applicant, coApplicant, pendingDoc) => {
    const formData = [
      {
        name: 'applicant.name',
        label: 'APPLICANT',
        type: 'text',
        disabled: true,
        value: applicant?.name,
      },
      ...(applicant?.pendingDocumentArray?.length > 0 &&
        applicant.pendingDocumentArray.map((item, index) => ({
          name: `applicant.${item.documentRequired}`,
          label: DOC_TYPE[item.documentRequired] || item.documentRequired,
          type: 'file',
          documentChecklistID: item?.documentChecklistId,
          applicantId: item.applicantId,
          remarks: item?.remarks,
          ...(index === applicant.pendingDocumentArray.length - 1 && !!coApplicant.length && { seperator: true }),
        }))),
    ];

    coApplicant.forEach((item, index) => {
      formData.push({
        name: `coApplicant.${index}.name`,
        label: 'COAPPLICANT',
        type: 'text',
        disabled: true,
        value: item.name,
      });
      if (item.pendingDocumentArray && !!item.pendingDocumentArray.length) {
        item.pendingDocumentArray.forEach((item2, index2) =>
          formData.push({
            name: `coApplicant.${index}.${item2.documentRequired}`,
            label: DOC_TYPE[item2.documentRequired] || item2.documentRequired,
            type: 'file',
            documentChecklistID: item2?.documentChecklistId,
            applicantId: item2.applicantId,

            ...(index < coApplicant.length - 1 &&
              index2 === applicant.pendingDocumentArray.length - 1 && { seperator: true }),
          })
        );
      }
    });
    const formValueToReset = { applicant: { name: applicant.name }, coApplicant: [] };
    applicant.pendingDocumentArray.forEach((element) => {
      formValueToReset.applicant[element.documentRequired] = null;
    });
    coApplicant.forEach((item, index) => {
      formValueToReset.coApplicant[index] = {};
      formValueToReset.coApplicant[index].name = item.name;
      item.pendingDocumentArray.forEach((element) => {
        formValueToReset.coApplicant[index][element.documentRequired] = null;
      });
    });
    methods.reset(formValueToReset);

    const newFormData = [];
    formData.forEach((item, index) =>
      newFormData.push(
        item?.value && item,
        item?.type === 'file' && [
          item,
          {
            applicantId: item?.applicantId,
            documentChecklistID: item?.documentChecklistID,
            label: 'CSR Remarks',
            name: `${item?.name}_CSR_Remarks`,
            remarks: item?.remarks,
            type: 'text',
          },
          {
            applicantId: item?.applicantId,
            documentChecklistID: item?.documentChecklistID,
            label: 'Remarks',
            name: `${item?.name}_Remarks`,
            type: 'text',
          },
        ]
      )
    );

    const checkFormData = newFormData.filter((el) => el !== undefined).filter((el) => el !== false);

    // const firstElement = checkFormData.shift();

    setNewForm(checkFormData);
    const filterRemarksValue = checkFormData
      .filter((arr) => Array.isArray(arr))
      .map((elem) => elem.filter((data) => data.name.includes('CSR')))
      .flat();
    setRemarksValue(filterRemarksValue);
    // setApplicantNameSlice(firstElement);
    setAllDocs(formData);
    setShowUploadDocumentsButton(Object.keys(pendingDoc.data || {}).length > 0);
    // if (condition) {
    //   setShowUploadDocument(Object.keys(pendingDoc.data || {}).length > 0);
    // } else {

    // }
  };

	// const getApplicationStatus = async () => {
	// 	try {
	// 		const res = await getRequiredDocument();
	// 		console.log('res :', res);
	// 		const modifiedData = res?.data?.map((item) => ({
	// 			label: item?.documentType,
	// 			name: item?.documentName,
	// 			fileData: item?.fileData,
	// 			uploaded: item?.uploaded,
	// 		}));
	// 		setDocumentArray(modifiedData);
	// 		let documentUploadedCount = 0
	// 		for (let index = 0; index < res?.data.length; index++) {
	// 			const document = res?.data[index];
	// 			if (document.uploaded === true) {
	// 				documentUploadedCount += 1
	// 			}
	// 		}
	// 		if (documentUploadedCount === 6) {
	// 			setIsApplicationPreviewDisabled(false)
	// 		}else{
	// 			setIsApplicationPreviewDisabled(true)
	// 		}
	// 	} catch (error) {
	// 		console.error('Error fetching documents:', error);
	// 	}
	// };

  const fetchApplicationStatus = async () => {
    setShowLoader(true);
		// await getApplicationStatus()

    const res = await getApplicationStatus()
    // const [res, pendingDoc] = await Promise.all([getApplicationStatus(), getPendingDocList()]);
    // if (pendingDoc.status) {
    //   const applicant = Object.values(pendingDoc.data).find((item) => !item.isCoapplicant);
    //   const coApplicant = Object.values(pendingDoc.data).filter((item) => item.isCoapplicant);

    //   let id = '';
    //   Object.keys(pendingDoc.data).forEach((x) => {
    //     if (pendingDoc.data[x].isCoapplicant === false) {
    //       id = x;
    //     }
    //   });
    //   setApplicantId(id);
    //   if (applicant) {
    //     setFormData(applicant, coApplicant, pendingDoc);
    //   }
    // } else {
    //   setErrorMessage({ title: pendingDoc.error.msg, show: true });
    // }
		try {
			if (res.status) {	
				const data = res?.data;
				const isDocumentUploadOnly = res?.data?.isDocumentUploadOnly;
				setIsFeedBackFormSubmitted(res?.data?.isFeedBackFormSubmitted)
				if (isDocumentUploadOnly === true) {
					setIsDocumentUploadOnly(true);
					setShowUploadDocument.call(null, true);
					if (isDocumentUploadOnly) {
							const x = document.getElementsByClassName("status-title MuiBox-root css-0")[0]
							if (x) {
								x.innerText = "Upload Documents"
								console.log(`---------x-------------`)
								console.log(x)
							}
					}
				} else {
					// setShowFeedbackBox(true);
					setShowApplicationStatusProgress(true)
				}
				setApplicantName(data.applicantFirstName);
				setActiveStep(
					applicationStatus.indexOf(data.loanStage.toLowerCase()) > -1
						? applicationStatus.indexOf(data.loanStage.toLowerCase())
						: 0
				);
			} else {
				setErrorMessage({ title: res.error.msg, show: true });
			}
		} catch (error) {
			console.log(error)
		}

    setShowLoader(false);
  };

  useEffect(() => {
    // setShowUploadDocument(false);
    // setShowSuccessoModal(false);
    fetchApplicationStatus();
    // setFormSubmitted(false);
    setTimeoutState(0);
    return setAllDocs.bind(null, null);
  }, [reload]);

  const title = useMemo(
    () => (!showUploadDocuments ? 'UPLOAD_DOCUMENTS' : 'APPLICATION_STATUS'),
    [showUploadDocuments]
  );

  const filterValueRemark = (keyName, arr) => {
    const requiredJSON = arr.filter((item) => item.name.includes(keyName));
    return requiredJSON[0].remarks;
  };
  // this logic is used to check whether all the file upload inputField have been uploaded with documents or to check where all the field have value inside it ot not
  // const checkButtonUpload = (arr) => {
  //   let result = arr
  //     .filter((arr) => Array.isArray(arr))
  //     .map((elem) => elem.filter((data) => data.type === 'file'))
  //     .flat()
  //     .every((elemArray) => elemArray.value !== undefined);
  //   return result;
  // };

  const handleSubmitUploadButton = async (data) => {
    setShowLoader(true);
    setFormSubmitted(true);
    const applicant = { docType: [], applicantDetailId: applicantId };
    const coApplicant = [];
    const formData = new FormData();
    newFormCheckData.forEach(
      (itemData) =>
        itemData?.disabled !== true &&
        itemData.forEach((item) => {
          if (item.type === 'file') {
            formData.append(item.name, item.value);

            const splitName = item.name.split('.');

            if (splitName[0] === 'applicant') {
              applicant.docType.push({
                documentType: splitName[1],
                documentChecklistID: item.documentChecklistID,
                CSR_Remarks: filterValueRemark(`${splitName[1]}_CSR_Remarks`, remarksValue),
                Remarks: data?.applicant[`${splitName[1]}_Remarks`],
              });
            } else {
              const ind = coApplicant.findIndex((ele) => ele.applicantId === item.applicantId);

              if (ind > -1) {
                coApplicant[ind].docType.push({
                  documentType: splitName[2],
                  documentChecklistID: item.documentChecklistID,
                });
              } else {
                coApplicant.push({
                  applicantDetailId: applicantId,
                  docType: [
                    {
                      documentType: splitName[2],
                      documentChecklistID: item.documentChecklistID,
                      CSR_Remarks: data?.coApplicant[0][`${splitName[2]}_CSR_Remarks`],
                      Remarks: data?.coApplicant[0][`${splitName[2]}_Remarks`],
                    },
                  ],
                });
              }
            }
          }
        })
    );
    console.log(applicant);
    formData.append('applicant', JSON.stringify(applicant));
    formData.append('co_applicant', JSON.stringify(coApplicant));

    const res = await postPendingDocList(formData);
    if (res.status) {
      setShowSuccessoModal(true);
      if (timeoutState !== 0) clearTimeout(timeoutState);
      const time = setTimeout(() => {
        setReload((prev) => !prev);
        if (isDocumentUploadOnly) {
          window.location.href = 'https://www.aavas.in/';
        }
      }, 5000);
      setTimeoutState(time);
    } else {
      setFormSubmitted(false);
      setErrorMessage({ title: res.error.msg, show: true });
    }
    setShowLoader(false);
  };

  const handleClickAction = () => {
    if (showUploadDocumentsButton) {
      setShowUploadDocument.call(null, true);
    } else {
			sessionStorage.clear()
      navigate('/login')
    }
  };


  return (
    <>
      {showUploadDocuments && allDocs && false && (
        <UploadCardContainerStyle>
          <FormProvider methods={methods}>
            <Box className="inputContainer">
              <Stack spacing={3} className="fullWidth">
                {/* {newFormCheckData.map((itemArray) => (
                  <>
                    {itemArray?.disabled === true && (
                      <Fragment key={itemArray.name}>
                        <Stack spacing={3} mb={5} className={itemArray.type === 'text' && 'inputTextField'}>
                          <Box
                            sx={{
                              fontSize: 18,
                              fontWeight: 500,
                            }}
                          >
                            {t(itemArray.label)}
                            {' - '}
                            {methods.getValues(itemArray.name)}
                          </Box>
                        </Stack>
                        {isDocumentUploadOnly && isNonMobileScreens && (
                          <Stack spacing={3} mb={5}>
                            <Box display="flex" justifyContent="space-around" alignItems="center">
                              <Typography
                                sx={{
                                  fontSize: 18,
                                  fontWeight: 500,
                                  textDecoration: 'underline',
                                }}
                              >
                                Document Name
                              </Typography>
                              <Box
                                sx={{
                                  fontSize: 18,
                                  fontWeight: 500,
                                  textDecoration: 'underline',
                                }}
                              >
                                CSR Remarks{' '}
                              </Box>
                              <Box
                                sx={{
                                  fontSize: 18,
                                  fontWeight: 500,
                                  textDecoration: 'underline',
                                }}
                              >
                                Remarks (if any)
                              </Box>
                            </Box>
                          </Stack>
                        )}
                      </Fragment>
                    )}

                    {!isDocumentUploadOnly ? (
                      <Box>
                        {itemArray?.disabled !== true &&
                          itemArray.map((item, index) => {
                            return (
                              item?.type === 'file' && (
                                <PureInputFieldComponent
                                  {...item}
                                  key={item.name}
                                  fileName={itemArray[index].value}
                                  {...(item.type === 'file' && {
                                    onChange: handleFileInputChange.bind(this, item.name),
                                  })}
                                  getValues={methods.getValues}
                                />
                              )
                            );
                          })}
                      </Box>
                    ) : (
                      <Box display={isNonMobileScreens ? 'flex' : ''}>
                        {itemArray?.disabled !== true &&
                          itemArray.map((item, index) => {
                            return (
                              <PureInputFieldComponent
                                {...item}
                                key={item.name}
                                fileName={itemArray[index].value}
                                {...(item.type === 'file' && {
                                  onChange: handleFileInputChange.bind(this, item.name),
                                })}
                                getValues={methods.getValues}
                              />
                            );
                          })}
                      </Box>
                    )}
                  </>
                ))} */}
              </Stack>
            </Box>
          </FormProvider>
          <Box className="buttonBox">
            {/* <Button
              disabled={
                !newFormCheckData
                  .filter((arr) => Array.isArray(arr))
                  .map((elem) => elem.filter((data) => data.type === 'file'))
                  .flat()
                  .every((elemArray) => elemArray.value !== undefined) || formSubmitted
              }
              type="button"
              onClick={methods.handleSubmit(handleSubmitUploadButton)}
              className="saveButton lowerCase"
            >
              {t('UPLOAD_DOCUMENTS')}
            </Button> */}
          </Box>
        </UploadCardContainerStyle>
      )}
      {!showUploadDocuments && showApplicationStatusProgress && (
        <CardContainerStyle>
          <Stack spacing={3} mb={1} className="headerTitle">
            {t('WELCOME')}
            {', '}
            {applicantName}
          </Stack>
          <Stack spacing={3} mb={5} className="application-status-text">
            {t('LOAN_APPLICATION_STATUS')}
          </Stack>
          <Stack spacing={3} className="progress-bar-stack web-bar">
            <Stepper activeStep={activeStep} alternativeLabel connector={<ColorlibConnector />}>
              {allAppliicationStatus.map((item) => {
                const stepProps = {};
                const labelProps = {};
                return (
                  <Step key={item} {...stepProps}>
                    <StepLabel {...labelProps}>{t(item)}</StepLabel>
                  </Step>
                );
              })}
            </Stepper>
          </Stack>
          <Stack spacing={3} className="progress-bar-stack mobile-bar">
            <Stepper activeStep={activeStep} orientation="vertical" connector={<MobileColorlibConnector />}>
              {allAppliicationStatus.map((item) => (
                <Step key={item}>
                  <StepLabel>{t(item)}</StepLabel>
                </Step>
              ))}
            </Stepper>
          </Stack>
          <Stack spacing={3} mb={4} className="thanks-message-stack">
            {t('THANKS_MESSAGE')}
          </Stack>
        </CardContainerStyle>
      )}
      {showUploadDocuments && showSuccessModal && false && (
        <DialogStyle open={showSuccessModal}>
          <Box
            sx={(theme) => ({
              display: 'flex',
              minWidth: 280,
              justifyContent: 'center',
              padding: 2,
              '& > svg': {
                fill: theme.palette.success.darker,
                fontSize: 70,
              },
            })}
          >
            <CheckCircleOutlinedIcon />
          </Box>
          <DialogContent>
            <DialogContentText sx={{ display: 'flex', justifyContent: 'center' }}>
              {t('DOCUMENT_UPLOADED_SUCCESSFULLY')}
            </DialogContentText>
          </DialogContent>
        </DialogStyle>
      )}
      {showFeedbackBox && !isFeedBackFormSubmitted && false && (
        <FeedbackContainer>
          <Box
            sx={{
              position: 'absolute',
              right: 10,
              top: 10,

              cursor: 'pointer',
            }}
          >
            <CloseIcon onClick={() => setShowFeedbackBox(false)} />
          </Box>

          <Box className="feedbackBox">
            <Typography>Your feedback is important to us</Typography>
            <Typography>Rate the experience of your loan journey !</Typography>
            <Box className="feedbackInput">
              <Rating
                name="simple-controlled"
                value={feedbackData?.rating}
                sx={{
                  fontSize: '2rem',
                  '& .MuiRating-icon': {
                    width: '2.5rem',
                  },
                }}
                onChange={(event, value) =>
                  setFeedbackData((prev) => ({
                    ...prev,
                    rating: value,
                  }))
                }
              />
              <TextField
                placeholder="leave your comment here"
                value={feedbackData?.comment}
                onChange={(event) =>
                  setFeedbackData((prev) => ({
                    ...prev,
                    comment: event.target.value,
                  }))
                }
                variant="outlined"
              />
            </Box>
            <Box
              sx={{
                display: 'flex',

                justifyContent: 'center',
                alignItems: 'center',
                gap: '10px',
              }}
            >
              <Button
                type="button"
                className="feedbackButton"
                onClick={() => setShowFeedbackBox(false)}
                variant="contained"
              >
                Later
              </Button>
            </Box>
          </Box>
        </FeedbackContainer>
      )}
    </>
  );
});

