console.log("process.env.REACT_APP_HOST", process.env.REACT_APP_HOST)
export default {
  API_HOST: process.env.REACT_APP_HOST || '',
  API_BASE: '/api',
  API_VERSION: '/v1',
  ROUTE: {
    ALL: '*',
    ROOT: '/',
    LOGIN: '/login',
    REGISTER: '/register',
    BASIC_INFORMATION: '/basic-information',
    OCR: '/ocr',
    APPLICATION_PROGRESS: '/application-progress',
    APPLICATION_STATUS: '/application-status',
    NOT_FOUND: '/404',
    APPLICANT_DETAILS: '/applicant-details',
    ASSET_DETAILS: '/asset-details',
    FEE_PAYMENT: '/fee-payment',
    REQUIRED_DOCUMENTS: '/required-documents',
    DATA_LOGS: '/unasisstedlogs',
    LOAN_APPLICAIONT: '/loan-application',
    INITIAL_SCREEN: '/initial',
    PROVISIONAL_OFFER: '/provisional-offer',
  },
  API: {
    GET_OCR_DOC: 'applicant-documents',
    GET_FEE_DETAILS: '/fee-payment/create-razorpay-order',
    CHECK_PAYMENT_STATUS: '/payment-status',
    SAVE_OCR_DOC: '/applicant-documents/ocr-documents',
    DELETE_OCR_DOC: '/applicant-documents',
    GET_IP_LOCATION: '/getLocalityInfo',
    BASIC_LOAN_INFO: '/application-info',
    UPDATE_BASIC_INFO: '/application-info',
    LOGIN: '/login',
    LOGINVIAEMAIL: '/applicant-login',
    REGISTER: '/register',
		FORGOT_PASSWORD: '/forgot-password',
    RESET_PASSWORD: '/reset-password',
    GET_CURRENT_ADDRESS: '/applicant-current-address',
    GET_ALL_APPLICANT: '/get-all-applicant-name',
    APPLICATION_PROGRESS: '/application-progress',
    DOCUMENT_UPLOAD_AAVAS: '/submit-final-application-data',
    UPDATE_APPLICATION_PROGESS: '/update-application-progress',
    APPLICANT_DETAILS: '/applicant-info',
    ASSET_DETAILS: '/asset-info',
    SAVE_REQUIRED_DOC: '/applicant-documents',
    GET_REQUIRED_DOC: '/applicant-documents',
    DELETE_REQUIRED_DOC: '/applicant-documents',
    PAYMENT_LINK: '/payment-link',
    APPLICATION_STATUS: '/application-status',
    SAVE_BANKING_DETAILS: '/save-banking-details',
    UPDATE_PAYMENT_STATUS: '/fee-payment/payment-status',
    GET_PENDING_DOCUMENT_LIST: '/document-list-postdisbursement',
    POST_PENDING_DOCUMENT_LIST: '/document-list-postdisbursement',
    DELETE_ASSETS: '/applicant-assets',
    GET_PAN_DETAILS: '/applicant-by-pan',
    SEND_OTP: '/send-otp',
    VERIFY_OTP: 'verify-otp',
    DATA_LOGS: '/admin/logs',
    PROVISIONAL_LETTER: '/provision-letter',
    PAN_VERIFICATION: '/applicant-pan-verification',
    GET_ACCOUNT_DISABLED_FIELD: '/get-account-disabled-field',
    POST_FEEDBACK_FORM : '/feedback-form'
  },
};
