// ----------------------------------------------------------------------

export default function Menu() {
  return {
    MuiMenu: {
      styleOverrides: {
        root: {
          '& .MuiMenuItem-root': {
            whiteSpace: 'normal',
          },
        },
      },
    },
  };
}
