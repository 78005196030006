import React, { useState, useContext } from 'react';
import { Box, Button, TextField, Typography, Grid } from '@mui/material';
/* eslint-disable import/no-unresolved */
import { forgotPassword } from 'src/services';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import { notification } from "antd";
import EmailSent from './EmailSent';
import { CContext } from '../components/utils';

const PasswordReset = ({ selectedOption, setShowForgotPassword }) => {
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [contactInfo, setContactInfo] = useState('');
  const {errorMessage, setErrorMessage, setShowLoader } = useContext(CContext);
  const navigate = useNavigate(); // Initialize navigate


  const handleSubmit = async () => {
		const res = await forgotPassword({emailId : contactInfo});
		if (res.status === true) {
			setIsSubmitted(true);
		}else{
			// setErrorMessage({ title: "Error occured", show: true });
			notification.error({message: 'something went wrong' , description: res?.error?.error?.message,});
		}
  };

  if (isSubmitted) {
    return <EmailSent selectedOption={selectedOption} contactInfo={contactInfo} setShowForgotPassword={setShowForgotPassword}/>;
  }

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <Box width="100%" maxWidth="400px" padding="20px" >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h5" component="h1" gutterBottom>
              Password Reset
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body2" gutterBottom>
              Enter your {selectedOption === 'email' ? 'email' : 'phone number'} to get a reset link
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              fullWidth
              placeholder={selectedOption === 'email' ? 'Email Address' : 'Phone Number'}
              type={selectedOption === 'email' ? 'email' : 'tel'}
              value={contactInfo}
              onChange={(e) => setContactInfo(e.target.value)}
              sx={{
                '& .MuiOutlinedInput-root': {
                  borderColor: '#F5F6F8',
                  backgroundColor: '#DEDEDE',
                },
                '& .MuiOutlinedInput-input': {
                  color: 'inherit',
                  padding: '12px 14px'
                },
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={handleSubmit}
              sx={{
                mt: 2,
                background: '#192C79',
                padding: '10px !important',
                borderRadius: '3px',
                boxShadow: 'none',
                '&:hover': {
                  background: '#192C79',
                },
              }}
            >
              Submit
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Box mt={2}>
              <Typography
                variant="body2"
                onClick={() => setShowForgotPassword(false)} // Navigate to login page
                sx={{
                  textAlign: 'center',
                  color: '#192C79',
                  cursor: 'pointer',
                  '&:hover': {
                    textDecoration: 'underline',
                    backgroundColor: 'transparent',
                  },
                }}
              >
                Back to login
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default PasswordReset;
