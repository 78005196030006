// ----------------------------------------------------------------------

export default function Chip(theme) {
  return {
    MuiChip: {
      styleOverrides: {
        root: {
          flexDirection: 'row-reverse',
          justifyContent: 'flex-end',
          '& span': {
            paddingLeft: 0,
            paddingRight: 6,
            fontSize: 16,
            fontWeight: 500,
          },
          '& svg': {
            fill: theme.palette.navyBlue,
            cursor: 'pointer',
          },
        },
      },
    },
  };
}
