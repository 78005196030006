import React, { useContext, useEffect, useState } from 'react';
import { Box, Button, Stack } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { notification } from 'antd';
import { constants, formDefaultValues } from '../constants';
import { photosForOcrCardContainerStyle as CardContainerStyle } from '../styled-components';
import { OCRCard, CommonHeader, DeleteModal } from '../components/common-components';
import { deleteDocument, getDocument, postApplicationProgress, uploadDocument } from '../services';
import { CContext } from '../components/utils';
import { useDocumentData } from '../custom-hooks';

const { ROUTE } = constants;
const { photoOfOcrDocType, JPG_JPEG_PDF_REGEX } = formDefaultValues;

export default React.memo(() => {
  const [deleteModal, setDeleteModal] = useState({ state: false, deleteDocType: null });
  const [fileSize, setFileSize] = useState();
  const { activeStep, setActiveStep, isMobile, setShowLoader } = useContext(CContext);
  const [uploaded, setUploaded] = useState({});
  const { photoData, setPhotosData } = useDocumentData({}, uploaded);
  const [allDocumentsUploaded, setAllDocumentsUploaded] = useState(false);
  const { t } = useTranslation('translation');
  const navigate = useNavigate();

  const handleNextStep = () => {
    if (isMobile) {
      navigate(ROUTE.BASIC_INFORMATION, { replace: true });
    } else {
      setActiveStep(activeStep + 1);
    }
  };

  const updateApplicationProgress = async () => {
    setShowLoader(true);
    handleNextStep();
    setShowLoader(false);
  };

  useEffect(() => {
    const allUploaded = Object.values(uploaded).every(doc => doc.state === true);
    setAllDocumentsUploaded(allUploaded);
  }, [uploaded]);

  useEffect(() => {
    (async () => {
      setShowLoader(true);
      const res = await getDocument();
      if (res.status && res.data && res.data.length > 0) {
        const status = {};
        const uploadedStatus = {};

        res.data.forEach((item) => {
          item.fileMetaData = {
            name: item?.documentName,
            size: item?.fileData?.sizeInKB,
          };

          status[item.documentType] = {
            state: item.uploaded,
            status: item.fileData.status,
            file: item?.fileMetaData || {},
          };

          uploadedStatus[item.documentType] = {
            state: item.uploaded,
            file: item?.fileMetaData || null,
          };
        });

        setPhotosData(status);
        setUploaded(uploadedStatus);
      }
      setShowLoader(false);
    })();
    return () => setShowLoader(false);
  }, []);

  const handleModalClose = () => {
    setDeleteModal({ state: false, deleteDocType: null });
  };

  const openNotification = (type, message) => {
    notification[type]({
      message,
      placement: 'topRight',
    });
  };

  const onCancelUpload = async (controller, name) => {
    setPhotosData((prev) => ({ ...prev, [name]: { file: null, status: 0 } }));
    controller.cancel();
  };

  const deleteFile = async (name) => {
    setDeleteModal({ state: true, deleteDocType: name });
  };

  const onDeleteFileModal = async () => {
    const res = await deleteDocument({ DOCUMENT_TYPE: photoOfOcrDocType[deleteModal.deleteDocType] });
    
    if (res.status) {
      // Successfully deleted the document
      setPhotosData((prev) => ({ ...prev, [deleteModal.deleteDocType]: { file: null, status: 0 } }));
      setUploaded((prev) => ({ ...prev, [deleteModal.deleteDocType]: { state: false, file: null } }));
      setDeleteModal({ state: false, deleteDocType: null });
      
      // Show success notification
      openNotification('success', 'File deleted successfully.');
    } else {
      // Handle delete error
      openNotification('error', res.error.msg);
    }
  };
  

  const onChange = async (controller, e) => {
    const file = e.target.files[0];
    setFileSize(file?.size);
    const { type, size } = file;
  
    const validTypes = ['image/jpeg', 'image/png', 'application/pdf'];
    if (!validTypes.includes(type)) {
      return openNotification('error', 'Accepted file types are JPG, JPEG, PNG, and PDF.');
    }
  
    const MAX_FILE_SIZE = 10 * 1024 * 1024;
    if (size > MAX_FILE_SIZE) {
      return openNotification('error', 'File size should not exceed 10MB.');
    }
  
    setPhotosData((prev) => ({ ...prev, [e.target.name]: { file, status: 1 } }));
    const formData = new FormData();
    formData.append('DOCUMENT', file);
    formData.append('DOCUMENT_TYPE', photoOfOcrDocType[e.target.name]);
    e.target.value = '';
    const res = await uploadDocument(formData, controller);
    if (res.status) {
      openNotification('success', 'File uploaded succcessfully.');
      setUploaded((prev) => ({ ...prev, [e.target.name]: { file, state: true } }));
    } else {
      setUploaded((prev) => ({ ...prev, [e.target.name]: { file: null, state: false } }));
      setPhotosData((prev) => ({ ...prev, [e.target.name]: { file: null, status: 0 } }));
      if (res.error.name !== 'CanceledError') {
        openNotification('error', res.error.error.details.message);
      }
    }
  };

  return (
    <>
      <CardContainerStyle>
        <Box className="contentBox">
          {Object.entries(photoData).map(([key, value]) => (
            <OCRCard
              key={key}
              fileSize={fileSize}
              deleteFile={deleteFile}
              onCancelUpload={onCancelUpload}
              onChange={onChange}
              uploaded={uploaded[key]?.state}
              name={key}
              fileData={value || { status: 1 }}
              label={key.toUpperCase()}
            />
          ))}
        </Box>
        <Stack
          direction="row"
          justifyContent="end"
          mt={5}
          sx={{
            position: 'fixed',
            bottom: 0,
            left: '225px',
            right: 0,
            bgcolor: 'white',
            padding: '10px',
          }}
        >
        <Box>
          <LoadingButton
                  size="large"
                  type="submit"
                  variant="contained"
                  onClick={updateApplicationProgress}
                  disabled={!allDocumentsUploaded}
                  sx={{ minWidth: 120, bgcolor: "#1B2A68" }}
                >
                  {t('NEXT')} ➜
                </LoadingButton>
        </Box>
        </Stack>
      </CardContainerStyle>
      <DeleteModal
        deleteModal={{ ...deleteModal, txtMessage: 'DELETE_DOCUMENT', question: 'FILE_DELETE_WARNING_REQ_DOC' }}
        handleModalClose={handleModalClose}
        onDeleteFileModal={onDeleteFileModal}
      />
    </>
  );
});
