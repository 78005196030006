import React from "react";

const SelectableCard = ({
  icon,
  text,
  selectedColor = "#1A3C78",
  defaultColor = "#FFFFFF",
  isSelected,
  onClick,
  width = "150px",
  height = "150px"
}) => {
  return (
    <div
      className={`selectable-card ${isSelected ? 'selected' : ''}`}
      onClick={onClick}
      style={{
        backgroundColor: isSelected ? selectedColor : defaultColor,
        border: `1px solid ${isSelected ? selectedColor : "#ccc"}`,
        padding: "16px",
        borderRadius: "8px",
        textAlign: "center",
        cursor: "pointer",
        transition: "background-color 0.3s, border-color 0.3s",
        width,
        height,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center"
      }}
    >
      <img src={icon} alt="icon" style={{ width: "40px", height: "40px" }} />
      <div style={{ marginTop: "8px", color: isSelected ? "#FFF" : "#000", textAlign: "center" }}>
        {text}
      </div>
    </div>
  );
};

export default SelectableCard;
