import React from 'react';
import { LoadingButton } from '@mui/lab';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { Stack, Box, Grid, InputAdornment, Typography,IconButton } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { RHFTextField, RHFPhoneNumberInput, FormProvider } from '../components/hook-form';

const Register = ({
  showPassword, handleTogglePasswordVisibility, isSubmitting, onSubmit, methods, isValid
}) => {
  const { t } = useTranslation();
  
  return (
    <FormProvider methods={methods} onSubmit={onSubmit}>
    <Box>
      <Grid container spacing={1}>
        <Grid item xs={6}>
          <RHFTextField
            sx={{
              cursor: 'pointer',
              marginBottom: '20px',
              '& .MuiOutlinedInput-root': {
                backgroundColor: '#F5F6F8',
              },
            }}
            name="firstName"
            placeholder={t('FIRST_NAME')}
            fullWidth
          />
        </Grid>
        <Grid item xs={6}>
          <RHFTextField
            sx={{
              cursor: 'pointer',
              marginBottom: '20px',
              '& .MuiOutlinedInput-root': {
                backgroundColor: '#F5F6F8',
              },
            }}
            name="lastName"
            placeholder={t('LAST_NAME')}
            fullWidth
          />
        </Grid>
      </Grid>

      <RHFPhoneNumberInput
        showLoginUi
        name="mobile"
        placeholder={t('MOBILE_NUMBER')}
        hideFlag
        sx={{
          marginBottom: '20px',
          '& .MuiOutlinedInput-root': {
            backgroundColor: '#F5F6F8',
          },
        }}
      />

      <RHFTextField
        sx={{
          cursor: 'pointer',
          marginBottom: '20px',
          '& .MuiOutlinedInput-root': {
            backgroundColor: '#F5F6F8',
          },
        }}
        name="email"
        placeholder={t('EMAIL')}
        fullWidth
      />
      <RHFTextField
        name="password"
        type={showPassword ? 'text' : 'password'}
        placeholder={t('PASSWORD')}
        sx={{
          cursor: 'pointer',
          marginBottom: '20px',
          '& .MuiOutlinedInput-root': {
            backgroundColor: '#F5F6F8',
          },
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                edge="end"
                sx={{ cursor: 'pointer', 
                '&:hover': {
                  backgroundColor: 'transparent',
                },
              }}
                onClick={handleTogglePasswordVisibility}
              >
              {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}

              </IconButton>
            </InputAdornment>
          ),
        }}
        fullWidth
      />
      <LoadingButton
        loading={isSubmitting}
        variant="contained"
        fullWidth
        type="submit"
        disabled={!isValid}
        sx={{ 
          '&.MuiButtonBase-root': {
              backgroundColor: '#192C79',
              padding:'12px 16px',
              borderRadius: '3px',
          }, 
        }}
      >
        {t('REGISTER')}
      </LoadingButton>
    </Box>
  </FormProvider>
  );
};
  
  

export default Register;
