import { transformAddressResponseAsPerHTMLFormFields, transformEmploymentPayloadAsPerHTMLFormFields } from "./responseTransformUtilities";

export default function responseTransform(response) {
  const responseKey = response?.config?.responseKey;
  const data = response?.data?.data || response?.data;
  let transformedResponse = {};

  function extractPhoneNumber(phoneNumber) {
      const cleanedNumber = phoneNumber.replace(/\D/g, '');
      if (cleanedNumber.startsWith('91')) {
          return cleanedNumber.slice(2);
      }
      return cleanedNumber;
  }
  function camelToFlat(input) {
        const flatObject = {};
        
        Object.keys(input).forEach(camelCaseKey => {
            const flatKey = camelCaseKey.replace(/([a-z])([A-Z])/g, '$1 $2'); 
            const capitalizedKey = flatKey.charAt(0).toUpperCase() + flatKey.slice(1); 
            flatObject[capitalizedKey] = input[camelCaseKey];
        });      
        return flatObject;
      };

  try {
      switch (responseKey) {
          case 'GET_BASIC_INFO':
              if (data) {
                  transformedResponse = {
                      ...data,
                      applicant: {
                          references: data.references?.map(ref => ({
                              id: ref.id,
                              phoneNumber: extractPhoneNumber(ref?.phoneNumber),
                              name: ref.fullName,
                              address: ref.address,
                              referenceCity: ref.city
                          })),
                          requiredLoanAmount: data.loanAmount,
                          otherEmiPaidMonthly: data.otherMonthlyEMI?.toString(),
                          assistanceByOthers: !!data.assistedByAssociateDetails?.userId ,
                          associateVendorName: data.assistedByAssociateDetails?.vendorName,
                          associateUserId: data.assistedByAssociateDetails?.userId,
                          assistanceByEmployee: !!data.assistedByEmployeeDetails?.employeeCode,
                          aavasEmployeeCode: data.assistedByEmployeeDetails?.employeeCode,
                          aavasEmployeeName: data.assistedByEmployeeDetails?.employeeName,
                          isAssistedByEmployeeOfAavas: data.isAssistedByEmployeeOrAssociate ,
                          product: data.loanType,
                          loanTenureInMonths: (data.loanTenureInMonths)?.toString(),
                          emailId: data.applicants?.[0]?.emailId,
                          loanType: "PERSONAL",
                          typeOfLoan: typeof data.isNewLoan === 'undefined' ? "" : data.isNewLoan ? "NEW_LOAN" : "BALANCE_TRANSFER",
                          endUseForMSME: data.endUserMsme
                      }
                  };

                  delete transformedResponse.references;
                  delete transformedResponse.loanAmount;
                  delete transformedResponse.otherMonthlyEMI;
                  delete transformedResponse.isAssistedByEmployeeOrAssociate;
                  delete transformedResponse.assistedByAssociateDetails;
                  delete transformedResponse.assistedByEmployeeDetails;
                  delete transformedResponse.loanType;
                  delete transformedResponse.isNewLoan;
              }
              break;

          case 'GET_ASSET_INFO':
              try {
                  transformedResponse = {
                      assetDetails: data ? [{
                          address: {
                              plotNumber: data.assetInfo[0]?.addressReference?.plotNumber || "",
                              floorNumber: data.assetInfo[0]?.addressReference?.floorNumber || "",
                              streetAndBuildingName: data.assetInfo[0]?.addressReference?.streetAndBuildingName || "",
                              locality: data.assetInfo[0]?.addressReference?.locality || "",
                              landmark: data.assetInfo[0]?.addressReference?.landmark || "",
                              pinCode: data.assetInfo[0]?.addressReference?.pinCode || "",
                              villageOrTown: data.assetInfo[0]?.addressReference?.villageOrTown || "",
                              tehsil: data.assetInfo[0]?.addressReference?.tehsil || "",
                              state: data.assetInfo[0]?.addressReference?.state || "",
                          },
                          typeOfProperty: data.assetInfo[0]?.typeOfProperty || "",
                          propertyOwnership: data.assetInfo[0]?.propertyOwnership || "",
                          propertyOwners: data.assetInfo[0]?.propertyOwners || []
                      }] : []
                  };
              } catch (error) {
                  console.error('An error occurred while processing the action:', error);
              }
              break;

          case 'GET_APPLICANT_INFO':
              if (data) {
									const coAppliantArray = [] 
									if (data?.coApplicant?.length > 0) {

										if (data?.coApplicant?.length > 0) {
											for (let index = 0; index < data.coApplicant.length; index++) {
												const coApplicantObject = {
													applicantMyDetails : {},
													applicantAddress : [],
													employmentInfo : {}
												}
												const activeCoApplicant = data.coApplicant[index];
												coApplicantObject.applicantMyDetails = {
													...activeCoApplicant.applicantMyDetails,
													email : activeCoApplicant.applicantMyDetails.emailId,
                                                    residencyStatus: data.applicant.applicantMyDetails.indianResident ? "RESIDENCE" : "NRI",
                                                    identification: data.applicant.applicantMyDetails.aadharCardNumber 
                                                    ? 'AADHAR_CARD_NUMBER' 
                                                    : data.applicant.applicantMyDetails.drivingLicenseNumber 
                                                    ? 'DRIVING_LICENSE' 
                                                    : data.applicant.applicantMyDetails.voterCardNumber 
                                                    ? 'VOTER_ID'
                                                    : null  ,
													voterId : activeCoApplicant.applicantMyDetails.voterCardNumber,
													drivingLicense : activeCoApplicant.applicantMyDetails.drivingLicenseNumber
												}
												coApplicantObject.applicantAddress = [...transformAddressResponseAsPerHTMLFormFields(activeCoApplicant.applicantAddress)]
												coApplicantObject.employmentInfo = {...transformEmploymentPayloadAsPerHTMLFormFields(activeCoApplicant.employmentInfo)}
	
												delete coApplicantObject?.applicantMyDetails?.emailId
												delete coApplicantObject?.applicantMyDetails?.voterCardNumber
												delete coApplicantObject?.applicantMyDetails?.drivingLicenseNumber
	
												coAppliantArray.push(coApplicantObject)
											}
										}

									}
		
                  transformedResponse = {
                      applicant: {
												applicantMyDetails : {
													...data.applicant.applicantMyDetails,
													email : data.applicant.applicantMyDetails.emailId,
                                                    residencyStatus: data.applicant.applicantMyDetails.indianResident ? "RESIDENCE" : "NRI",
                                                    identification: data.applicant.applicantMyDetails.aadharCardNumber 
                                                    ? 'AADHAR_CARD_NUMBER' 
                                                    : data.applicant.applicantMyDetails.drivingLicenseNumber 
                                                    ? 'DRIVING_LICENSE' 
                                                    : data.applicant.applicantMyDetails.voterCardNumber 
                                                    ? 'VOTER_ID'
                                                    : null  ,
													voterId : data.applicant.applicantMyDetails.voterCardNumber,
													drivingLicense : data.applicant.applicantMyDetails.drivingLicenseNumber
												},
												applicantAddress : [...transformAddressResponseAsPerHTMLFormFields(data.applicant.applicantAddress)],
												employmentInfo : {...transformEmploymentPayloadAsPerHTMLFormFields(data.applicant.employmentInfo)},
                      },
											coApplicant : coAppliantArray
                  };

									delete transformedResponse?.applicant?.applicantMyDetails?.emailId
									delete transformedResponse?.applicant?.applicantMyDetails?.voterCardNumber
									delete transformedResponse?.applicant?.applicantMyDetails?.drivingLicenseNumber
              }
              break;
              case 'GET_APPLICANT_DATA':
                if (data) {
                    if (data.mainApplicantDetails) {
                    transformedResponse = camelToFlat(data.mainApplicantDetails)

                }
                if (data.coApplicantDetailsArray) {
                    transformedResponse = camelToFlat(data?.coApplicantDetailsArray)
                }
                if (data.applicationDetails) {                
                    const updatedApplicationDetails = {
                        ...data.applicationDetails,
                        isNewLoan: data.applicationDetails.isNewLoan ? "yes" : "no",
                    };
                    if (data.applicationDetails.loanType === 'MSME') {
                        updatedApplicationDetails.endUserMsme = data.applicationDetails.endUserMsme;
                    }
                    else{
                        delete updatedApplicationDetails?.endUserMsme
                    }              
                    transformedResponse = camelToFlat(updatedApplicationDetails);
                }}
                break;
            
          default:
              transformedResponse = response;
              break;
      }
  } catch (error) {
      if (transformedResponse?.headers) {
          delete transformedResponse.headers;
      }
      return error;
  }

  if (transformedResponse?.headers) {
      delete transformedResponse.headers;
  }

  return transformedResponse;
}
