    /* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
    import React, { useContext, useState } from 'react';
    import { useTranslation } from 'react-i18next';
    import { Outlet } from 'react-router-dom'; // Import Outlet
    import { Box, Grid, Menu, MenuItem } from '@mui/material';
    import { CContext } from '../components/utils';
    import LoginImageOverlap from '../assets/images/overlayLoginImage.png';
    import LanguageIcon from '../assets/images/languageIcon.svg';
    import Logo from '../assets/images/newCompanyLogo.png';
    import FooterLogo from '../assets/images/footerLogo.svg';
    import {
    ContentContainer,
    GridContainer,
    ImageContainer,
    StyledImage,
    LanguageIconStyle,
    FooterLogoStyle,
    } from '../styled-components/loginForm';

    const LoginPageLayout = () => {
    const [anchorEl, setAnchorEl] = useState(null);
    const { changeLanguage } = useContext(CContext);

    const openMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const closeMenu = () => {
        setAnchorEl(null);
    };
    const { t, i18n } = useTranslation('translation');

    const handleLanguageChange = (lang) => {
        if (lang !== i18n.language) {  // Check if the selected language is different
          changeLanguage(lang);  // Only change if it's different
        }
        setAnchorEl(null);  // Close the Menu
      };

    return (
        <>
        <GridContainer container>
            <ImageContainer item xs={6}>
            <StyledImage src={LoginImageOverlap} alt="Overlay" />
            </ImageContainer>
            <Grid item xs={6} sx={{ position: 'relative' }}>
            <ContentContainer container>
                <Grid
                item
                xs={12}
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
                >
               <Box
                    component="img"
                    src={Logo}
                    alt="Logo"
                    sx={{
                    maxWidth: '100px',
                    marginTop: '20px',
                    }}
                /> 
                <LanguageIconStyle src={LanguageIcon} alt="Language" onClick={openMenu} />
                <Menu disableScrollLock anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={closeMenu}>
                    <MenuItem onClick={() => handleLanguageChange('en')}>English</MenuItem>
                    <MenuItem onClick={() => handleLanguageChange('hi')}>हिंदी</MenuItem>
                </Menu>
                </Grid>
                <Grid
                item
                xs={12}
                sx={{
                    paddingTop: '20px',
                    maxHeight: 'calc(100vh - 140px)',
                    overflow: 'auto',
                    '&::-webkit-scrollbar': { display: 'none' },
                }}
                >
                <Grid item xs={12} display="flex" justifyContent="center">
                    <Box>
                    <Outlet />
                    </Box>
                </Grid>
                </Grid>
            </ContentContainer>
            <Grid item xs={12} sx={{ position: 'absolute', bottom: '20px', width: '100%' }}>
                <FooterLogoStyle src={FooterLogo} alt="Footer Logo" />
            </Grid>
            </Grid>
        </GridContainer>
        </>
    );
    };

    export default LoginPageLayout;

