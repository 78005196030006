import { constants } from '../constants';
import interceptor from './interceptor';

const axios = require('axios');

axios.defaults.timeout = 100000;
/**
 * httpsService: function to ajax call from frontend
 * it take params formdata which is an instance of FormData class which is optional
 * if params isn't provided then it will considered as plain http request and process with application/json format
 * if called with multipart formdata then it will return an instance of multipart/form-data request
 * @param {*} formData multipart form data instance 
 * @param {boolean} includeAuthToken indicates whether to include auth token in headers or not
 * @returns {*} axios instance url
 */

export default (formData = null, controller = null, includeAuthToken = true) => {
  const instanceUrl = axios.create({
    baseURL: constants.API_HOST + constants.API_BASE + constants.API_VERSION,
    transformRequest: [
      (data, headers) => {
        if (includeAuthToken) {
          headers.Authorization = `${sessionStorage.authToken}`;
        }
        headers['Content-Type'] = formData ? 'multipart/form-data' : 'application/json';
        headers['Cache-Control'] = 'no-cache';
        headers.Pragma = 'no-cache';
        if (process.env.NODE_ENV === 'development' && window.location.hostname === 'localhost') {
          headers['react-development-server'] = true;
        }
        return formData ? data : JSON.stringify(data);
      },
    ],
    ...(controller && { cancelToken: controller.token }),
    ...(formData && { data: formData }),
  });
  instanceUrl.interceptors.request.use((request) => interceptor.requestHandler(request));
  instanceUrl.interceptors.response.use(
    (response) => interceptor.successHandler(response),
    (error) => interceptor.errorHandler(error)
  );

  return instanceUrl;
};
