import { httpService } from '../helper';
import { constants } from '../constants';

export const uploadDocument = async (data, controller) =>
  httpService(data, controller).post(constants.API.SAVE_OCR_DOC, data);


export const deleteDocument = async (data) => {
  const formData = new FormData();
  formData.append('DOCUMENT_TYPE', data.DOCUMENT_TYPE);
  return httpService(formData).delete(`${constants.API.DELETE_OCR_DOC}/${data.DOCUMENT_TYPE}`, {
    data: formData, 
  });
};

  

export const getDocument = async () => {
  return httpService().get(constants.API.GET_OCR_DOC, {
    params: {
      type: 'ocrDocuments',
    },
  });
};
