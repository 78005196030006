import React from 'react';
import { useEffect } from 'react';
import { Button, Grid, Stack } from '@mui/material';
import { LocalizationProvider, MobileDatePicker } from '@mui/x-date-pickers';
import { useTranslation } from 'react-i18next';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LoadingButton } from '@mui/lab';
import { formDefaultValues } from '../constants';
import { RHFPhoneNumberInput, RHFSelectbox, RHFTextField } from '../components/hook-form';

const PersonalDetails = ({
  watch,
  isApplicantDetailsDisabledAfterPANVerification,
  onDateChange,
  convertTextToUpperCase,
  callPANVerificationAPI,
  activeStep,
  setActiveStep,
  setTabValue,
  tabValue,
  setPersonalDetailsNextButton,
  personalDetailsNextButton

  }) => {
    const { t } = useTranslation('translation');
    const formfor = 'applicant';

const applicantDetails = watch(`${formfor}.applicantMyDetails`);

const {
  firstName,
  lastName,
  panCardNumber,
  phoneNumber,
  dateOfBirth,
  gender,
  maritalStatus,
  fatherName,
  motherName,
  religion,
  casteCategory,
  spouseName
} = applicantDetails || {};
 
const areRequiredFieldsFilled = Object.values({
  firstName,
  lastName,
  panCardNumber,
  phoneNumber,
  dateOfBirth,
  gender,
  maritalStatus,
  fatherName,
  motherName,
  religion,
  casteCategory,
}).every((value) => !!value);  
 
const isSpouseNameFilled = maritalStatus !== 'MARRIED' || (maritalStatus === 'MARRIED' && spouseName);
 
const isNextButtonDisabled = !areRequiredFieldsFilled || !isSpouseNameFilled;
 
useEffect(() => {
  setPersonalDetailsNextButton(!isNextButtonDisabled);
}, [firstName, lastName, panCardNumber, phoneNumber, dateOfBirth, gender, maritalStatus, fatherName, motherName, religion, casteCategory, spouseName, isNextButtonDisabled, setPersonalDetailsNextButton]);

    const watchMaritalStatus = watch('applicant.applicantMyDetails.maritalStatus');

  return (
    <><Grid container spacing={3} mt={2}>
      <Grid item xs={12} sm={6} md={4}>
        <RHFSelectbox
          name={`${formfor}.applicantMyDetails.customerType`}
          label="CUSTOMER_TYPE"
          menus={formDefaultValues.customerType}
          required />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <RHFTextField
          name={`${formfor}.applicantMyDetails.panCardNumber`}
          label="PAN_CARD_NUMBER"
          alphaNumeric
          inputProps={{ maxlength: 10 }}
          hanleBlur={convertTextToUpperCase.bind(this, `${formfor}.applicantMyDetails.panCardNumber`)}
          allcaps
          required
          disabled={isApplicantDetailsDisabledAfterPANVerification} />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>{/* blank */}</Grid>

      <Grid item xs={12} sm={6} md={4}>
        <RHFTextField
          name={`${formfor}.applicantMyDetails.firstName`}
          label="NAME_AS_PER_PAN_CARD"
          onlyText
          required
          disabled={isApplicantDetailsDisabledAfterPANVerification} />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <RHFTextField
          name={`${formfor}.applicantMyDetails.middleName`}
          label="MIDDLE_NAME"
          disabled={isApplicantDetailsDisabledAfterPANVerification}
          onlyText />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <RHFTextField
          name={`${formfor}.applicantMyDetails.lastName`}
          label="LAST_NAME"
          disabled={isApplicantDetailsDisabledAfterPANVerification}
          onlyText
          required />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <RHFPhoneNumberInput
          sx={{
            height: '38px',
            padding: '5px 15px',
            boxSizing: 'border-box',
            marginTop: '0!important',
            borderRadius: '10px'
          }}
          hideFlag
          name={`${formfor}.applicantMyDetails.phoneNumber`}
          label="PHONE_NUMBER" />
      </Grid>

      <Grid item xs={12} sm={6} md={4}>
        <RHFTextField
          name={`${formfor}.applicantMyDetails.email`}
          label="PERSONAL_EMAIL" />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <RHFSelectbox
          name={`${formfor}.applicantMyDetails.gender`}
          label="GENDER"
          menus={formDefaultValues.GENDER}
          required />
      </Grid>



      <Grid item xs={12} sm={6} md={4}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <MobileDatePicker
            inputFormat="dd/MM/yyyy"
            maxDate={new Date().setFullYear(new Date().getFullYear() - 18)}
            minDate={new Date().setFullYear(new Date().getFullYear() - 70)}
            value={watch(`${formfor}.applicantMyDetails.dateOfBirth`) || null}
            onChange={onDateChange.bind(this, `${formfor}.applicantMyDetails.dateOfBirth`)}
            renderInput={(params) => (
              <RHFTextField
                {...params}
                name={`${formfor}.applicantMyDetails.dateOfBirth`}
                label="DOB"
                dateField
                required />
            )}
            disabled
            hanleBlur={callPANVerificationAPI} />
        </LocalizationProvider>
      </Grid>

      <Grid item xs={12} sm={6} md={8}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={6}>
            <RHFSelectbox
              name={`${formfor}.applicantMyDetails.maritalStatus`}
              label="MARITAL_STATUS"
              menus={formDefaultValues.MARITAL_STATUS}
              required />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            {watchMaritalStatus === 'MARRIED' && (
              <RHFTextField
                name={`${formfor}.applicantMyDetails.spouseName`}
                label="SPOUSE_NAME"
                onlyText
                required />
            )}
          </Grid>
        </Grid>
      </Grid>




      <Grid item xs={12} sm={6} md={4}>
        <RHFTextField
          name={`${formfor}.applicantMyDetails.fatherName`}
          label="FATHER_NAME"
          onlyText
          required />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <RHFTextField
          name={`${formfor}.applicantMyDetails.motherName`}
          label="MOTHER_NAME"
          onlyText
          required />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>{/* blank */}</Grid>

      <Grid item xs={12} sm={6} md={4}>
        <RHFSelectbox
          name={`${formfor}.applicantMyDetails.religion`}
          label="RELIGION"
          menus={formDefaultValues.RELIGION}
          required />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <RHFSelectbox
          name={`${formfor}.applicantMyDetails.casteCategory`}
          label="CASTE_CATEGORY"
          menus={formDefaultValues.CASTE_CATEGORY}
          required />
      </Grid>
    </Grid>
    
    <Stack
      direction="row"
      justifyContent="space-between"
      mt={5}
      sx={{
        position: 'fixed',
        bottom: 0,
        left: '225px',
        right: 0,
        bgcolor: 'white',
        padding: '10px',
      }}
    >
        <Button
          onClick={()=>{setActiveStep (activeStep -1)}}
          variant="outlined"
          sx={{
            minWidth: 120,
            color: "#1B2A68",
            borderColor: "#1B2A68",
            '&:hover': {
              bgcolor: "#2A3C8B",
              color: "#FFFFFF",
            }
          }}
        >
          {t('PREVIOUS')}
        </Button>
        <LoadingButton
          size="large"
          variant="contained"
          onClick={() => {
            console.log('CLICKED:');
            setTabValue(tabValue + 1);
          }}
          
          sx={{ minWidth: 120, bgcolor: "#1B2A68" }}
          disabled={!personalDetailsNextButton}
        >
          {t('NEXT')} ➜
        </LoadingButton>
      </Stack></>
  );
};

export default PersonalDetails;
