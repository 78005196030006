import React, { useState } from 'react';
import { Box, Typography } from '@mui/material';
import { MuiOtpInput } from 'mui-one-time-password-input';
import { LoadingButton } from '@mui/lab';
import { FormProvider } from '../components/hook-form';

const OTPVerification = ({ isSubmitting, methods, onSubmit, setOtp, otp }) => {
  const [isValid, setIsValid] = useState(false);

  const handleOtpChange = (value) => {
    const numericValue = value.replace(/[^0-9]/g, '');
      setOtp(numericValue);
    if (value.length === 6) {
      setIsValid(true);
    } else {
      setIsValid(false);
    }
  };

  return (
    <FormProvider methods={methods} onSubmit={onSubmit}>
     <Box sx={{ padding: { xl: '0px 80px' } }}>
        <Typography style={{ fontSize: '24px', fontWeight: 'bold' }}>
          OTP Verification
        </Typography>
        <Typography variant="body2" style={{ color: '#666', marginTop: '15px', marginBottom: '20px' }}>
          Enter the OTP sent to <span style={{ color: '#272727D9', fontWeight: 'bold' }}>{methods.watch('mobile')}</span>
        </Typography>
        <MuiOtpInput
          value={otp}
          onChange={handleOtpChange}
          length={6}
          TextFieldsProps={{
            placeholder: '.',
            style: {
              backgroundColor: '#F5F6F8',
              color: '#ffffff',
              textAlign: 'center',
            },
            inputStyle: {
              textAlign: 'center',
            },
          }}
        />
        <LoadingButton
          loading={isSubmitting}
          variant="contained"
          sx={{ 
            '&.MuiButtonBase-root': {
              backgroundColor: '#192C79',
              padding:'12px 16px',
              borderRadius: '3px',
              marginTop: '37px', 
              marginBottom: '16px'
            }, 
          }}
          fullWidth
          type="submit"
          disabled={!isValid}
        >
          Login
        </LoadingButton>
        <Typography
          variant="body2"
          style={{ color: '#666', display: 'flex', justifyContent: 'center' }}
        >
          Didn't receive an OTP? <span style={{ color: '#192C79', cursor: 'pointer', marginLeft: '5px' }}>Resend OTP</span>
        </Typography>
      </Box>
    </FormProvider>
  );
};

export default OTPVerification;
