/* eslint-disable react/display-name */
import { useTranslation } from 'react-i18next';
import React, { useEffect, useState, useContext, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Typography, Stack, Box, Tabs, Tab, Switch, Button, Checkbox } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';
import { CContext } from '../components/utils';
import { getApplicantDetails, panVerification, postApplicantDetails } from '../services';
import { FormProvider } from '../components/hook-form';
import { AddressInformationsForm } from '../components/common-components';
import { useYupValidatoins } from '../custom-hooks';
import { formDefaultValues, constants } from '../constants';
import {
  getReccurssiveObjectKeys,
  getdescriptiveMessage,
  hanelMixPanelEvents,
  setReccurssiveObjectKeys,
} from '../helper';
import PersonalDetails from './PersonalDetails';
import IdentityVerification from './IdentityVerification';

export default React.memo(() => {
  const formfor = 'applicant';
  const { t } = useTranslation('translation');
  const { object, arrayOfObject, coApplicantDetils, appliantDetailsValidations } = useYupValidatoins();
  const { isMobile, errorMessage, setErrorMessage, setActiveStep, activeStep, setShowLoader, mixpanel } =
  useContext(CContext);
  const [isApplicantDetailsDisabledAfterPANVerification, setIsApplicantDetailsDisabledAfterPANVerification] = useState(false);
  const [coApplicants, setCoApplicants] = useState([]);
  const [tabValue, setTabValue] = React.useState(0);
  const [accordionStatus, setAccordionStatus] = useState([]);
  const [expanded, setExpanded] = useState(['applicant.applicantMyDetails']);
	const [payloadPutIdState, setPayloadPutIdState] = useState({});
  const navigate = useNavigate();
  const { ROUTE } = constants;

  const [personalDetailsNextButton, setPersonalDetailsNextButton] = useState(true);
  const [identifyVerificationNextButton, setIdentifyVerificationNextButton] = useState(true);
  const [currrentAddressNextButton, setCurrrentAddressNextButton] = useState(true);
  const [permanentAddressNextButton, setPermanentAddressNextButton] = useState(true);


  const ApplicantDetailsSchema = object.shape({
    applicant: object.shape({ ...appliantDetailsValidations }),
  });

  const methods = useForm({
    resolver: yupResolver(ApplicantDetailsSchema),
    defaultValues: {
      applicant: { ...formDefaultValues.ApplicantDetailsDefaultValues },
    },
    mode: 'all',
  });

  const {
    handleSubmit,
    reset,
    watch,
    getValues,
    trigger,
    setValue,
    clearErrors,
    formState: { isSubmitting, errors },
  } = methods;
  

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
    };

  const sanitizeObject = (obj) => {
    // sanitize null values from object
    Object.keys(obj || {}).forEach((item) => {
      if (obj[item] === null) {
        delete obj[item];
      }
    });
  };

  const formatValueObject = (values, applicantAddress, applicantDetails, emplInfo) => {
    const isSameAddress = applicantAddress.filter((item) => item.addressType === 'CURRENT')?.[0]
      ?.isCommunicationAddress;
    if (isSameAddress !== true && isSameAddress !== false) {
      if (values.applicantAddress.current.id) delete values.applicantAddress.current.id;
      values.applicantAddress.current.isCommunicationAddress = true;
    }

    if (!values?.applicantMyDetails?.dateOfBirth) {
      values.applicantMyDetails.dateOfBirth = new Date(new Date().setFullYear(new Date().getFullYear() - 44));
    }

    if (applicantDetails.customerType === 'INDIVIDUAL') {
      if (['SELF_EMPLOYED_BUSINESSPERSON', 'SELF_EMPLOYED_PROFESSIONAL'].includes(applicantDetails.occupation)) {
        values[applicantDetails.occupation] = {
          ...formDefaultValues.ApplicantDetailsDefaultValues[applicantDetails.occupation],
          ...emplInfo,
        };
      } else {
        values.bankOrCash = { ...formDefaultValues.ApplicantDetailsDefaultValues.bankOrCash, ...emplInfo };
      }
    } else {
      values.NONINDIVIDUAL = { ...formDefaultValues.ApplicantDetailsDefaultValues.NONINDIVIDUAL, ...emplInfo };
    }
  };

  const convertTextToUpperCase = (field, e) => {
    if (e?.target?.value) {
      e.target.value = e.target.value.toUpperCase();
    }
    if (field && getValues(field)) {
      setValue(field, getValues(field).toUpperCase());
    }
    return e;
  };

  const onDateChange = (field, value) => {

    setValue(field, value);
    clearErrors(field);
  };

  const handleSwithChange = (field) => {
    const val = getValues(field) !== true;
    let values = setReccurssiveObjectKeys({ ...getValues() }, field, val);
    if (field === `${formfor}.applicantAddress.permanent.isSameAsCurrentAddress`) {
      if (val) {
        values = setReccurssiveObjectKeys({ ...values }, `${formfor}.applicantAddress.permanent`, {
          ...getValues(`${formfor}.applicantAddress.current`),
          isSameAsCurrentAddress: true,
          isCommunicationAddress: false,
        });
      } else {
        values = setReccurssiveObjectKeys({ ...values }, `${formfor}.applicantAddress.permanent`, {
          ...formDefaultValues.ApplicantDetailsDefaultValues.applicantAddress.permanent,
        });
      }
    }
    reset(values, { keepErros: true });
  };

  const watchAddress = watch('applicant.applicantAddress');
  const isSameAsCurrentAddress = watch('applicant.applicantAddress.permanent.isSameAsCurrentAddress');

  const handleOwnertype = (e) => {
    setValue(`${formfor}.propertyOwners`, e.target.value === 'INDIVIDUAL' ? null : [], { shouldValidate: true });
  };


  const handleChange = (panel) => (event, isExpanded) => {
    const prev = [...expanded];
    if (isExpanded || !prev.includes(panel)) {
      prev.push(panel);
    } else {
      const index = prev.indexOf(panel);
      prev.splice(index, 1);
    }
    setExpanded(prev);
    setTimeout(() => {
      checkValidAccordions(false);
    }, 1100);
    };

  const checkValueEntered = React.useCallback(
    (field) => {
      const formValues = getValues(field);
      const isEntered = Object.values(formValues || {}).some((x) => x);
      return (
        (!formValues || (isEntered && Object.keys(formValues || {}).length > 0)) && accordionStatus.includes(field)
      );
    },
    [accordionStatus]
  );


  const callPANVerificationAPI = async () => {
    const obj = getValues();
    setShowLoader(true);
    const res = await panVerification(obj);
    if (res.status) {
      if (res.data.disableEdit === true) {
    setIsApplicantDetailsDisabledAfterPANVerification(true);
          }
            } else {
              setErrorMessage({ title: getdescriptiveMessage(res), show: true });
            }
            setShowLoader(false);
          };
  

  const formatValues = (data, type, object) => {
    try {
   
        const recursiveObjectResponse =  getReccurssiveObjectKeys(data, type);
        const applicantMyDetails = recursiveObjectResponse?.applicantMyDetails
        const employmentInfo = recursiveObjectResponse?.employmentInfo
    
        const applicantAddress = recursiveObjectResponse?.applicantAddress || [];
        // sanitize null values from object
        applicantAddress.forEach((item, index) => {
          Object.keys(applicantAddress).forEach((item) => {
            if (applicantAddress[index][item] === null) {
              delete applicantAddress[index][item];
            }
          });
        });
    
        if (applicantMyDetails.phoneNumber && applicantMyDetails.phoneNumber.indexOf('+91') !== 0) {
          applicantMyDetails.phoneNumber = `+91${applicantMyDetails.phoneNumber}`;
        }
    
        // sanitize null values from applicant detials object
        sanitizeObject(applicantMyDetails);
    
        // sanitize null values from employment info object
        sanitizeObject(employmentInfo);
    
        const values = {
          applicantAddress: {
            current: { ...formDefaultValues.ApplicantDetailsDefaultValues.applicantAddress },
            permanent: { ...formDefaultValues.ApplicantDetailsDefaultValues.applicantAddress },
          },
          ...(applicantAddress.length > 0 && {
            applicantAddress: {
              current: {
                ...formDefaultValues.ApplicantDetailsDefaultValues.applicantAddress,
                ...applicantAddress.filter((item) => item.addressType === 'CURRENT')[0],
              },
              permanent: {
                ...formDefaultValues.ApplicantDetailsDefaultValues.applicantAddress,
                ...applicantAddress.filter((item) => item.addressType === 'PERMANENT')[0],
              },
            },
          }),
          applicantMyDetails: {
            ...formDefaultValues.ApplicantDetailsDefaultValues.applicantMyDetails,
            ...applicantMyDetails,
          },
        };
        formatValueObject(values, applicantAddress, applicantMyDetails, employmentInfo);
        
        setReccurssiveObjectKeys(object, type.replace('coApplicant', 'co-applicant'), values);
        // setReccurssiveObjectKeys(object, type.replace('coApplicant', 'co-applicant'), values);

        return object;
    } catch (error) {
    console.log('error :', error);
      
    }
 
  };

  const getApplicantDetailsFun = async () => {
    try {
      setShowLoader(true);
      const res = await getApplicantDetails({responseKey: 'GET_APPLICANT_INFO'});
      const object = { applicant: {}, 'co-applicant': [] };

      
      if (res.status) {
        const responseData = res.data
        
        const applicantReferenceObject = {
          applicantMyDetails : null,
          applicantAddress : [null, null],
          employmentInfo : null,
        }
        const applicantPutIdObject = {}
  
        if (responseData?.applicant?.applicantMyDetails?.id > 0) {
          applicantReferenceObject.applicantMyDetails = responseData?.applicant?.applicantMyDetails?.id
        }
        if (responseData?.applicant?.applicantAddress[0]?.id > 0 && responseData?.applicant?.applicantAddress[1]?.id > 0) {
          applicantReferenceObject.applicantAddress = [responseData?.applicant?.applicantAddress[0]?.id, responseData?.applicant?.applicantAddress[1]?.id]
        }
        if (responseData?.applicant?.employmentInfo?.id > 0) {
          applicantReferenceObject.employmentInfo = responseData?.applicant?.employmentInfo?.id
        }
  
  
  
        applicantPutIdObject.applicant = applicantReferenceObject
        applicantPutIdObject.coApplicant = []
        if (responseData.coApplicant.length > 0) {
          for (let index = 0; index < responseData.coApplicant.length; index++) {
            const coApplicantReferenceObject = {
              applicantMyDetails : null,
              applicantAddress : [null, null],
              employmentInfo : null,
            }
            const activeCoApplicantStateId = responseData.coApplicant[index];
            if (activeCoApplicantStateId?.applicantMyDetails?.id > 0) {
              coApplicantReferenceObject.applicantMyDetails = activeCoApplicantStateId?.applicantMyDetails?.id
            }
            if (activeCoApplicantStateId?.applicantAddress[0]?.id > 0 && activeCoApplicantStateId?.applicantAddress[1]?.id > 0) {
              coApplicantReferenceObject.applicantAddress = [activeCoApplicantStateId?.applicantAddress[0]?.id, activeCoApplicantStateId?.applicantAddress[1]?.id]
            }
            if (activeCoApplicantStateId?.employmentInfo?.id > 0) {
              coApplicantReferenceObject.employmentInfo = activeCoApplicantStateId?.employmentInfo?.id
            }
            applicantPutIdObject.coApplicant.push(coApplicantReferenceObject)
          }
        }
        setPayloadPutIdState(applicantPutIdObject)
        formatValues(res.data, 'applicant', object);
        if (res.data.coApplicant.length > 0) {
          res.data.coApplicant.forEach((item, index) => {
            formatValues(res.data, `coApplicant.${index}`, object);
          });
        }
      } else {
        setErrorMessage({ title: res.error.msg, show: true });
      }
      const arr = [];
      if (object['co-applicant']?.length > 0) {
        object['co-applicant'].forEach((item, index) => {
          arr.push(new Date().getTime() + index);
        });
        setCoApplicants(arr);
      }
  
   
      reset(object, { keepErrors: true });
      setShowLoader(false);
      setTimeout(checkValidAccordions.bind(null, false, arr, true), 1000);
    } catch (error) {
    console.log('error :', error);
      
    }
  
  };

  // api call for get applicant details
  useEffect(() => {
    getApplicantDetailsFun.call();
  }, []);

  const readyPayloadFunction = (data, applicantType) => {
    let modifiedPayload = {};
    const customerType = data?.applicantMyDetails;
    const occupation = data?.applicantMyDetails;
    const crmAccountId = '';
    const applicantAddress = [data?.applicantAddress?.current, data?.applicantAddress?.permanent];
    if (crmAccountId === '') {
      if (customerType === 'NONINDIVIDUAL') {
        delete data?.applicantMyDetails.gender;
        delete data?.applicantMyDetails.occupation;
        delete data?.applicantMyDetails.maritalStatus;
        delete data?.applicantMyDetails.religion;
        delete data?.applicantMyDetails.casteCategory;
        delete data?.applicantMyDetails.residencyStatus;
        delete data?.applicantMyDetails.relationshipWithMainApplicant;
        delete data?.applicantMyDetails.doesYourAadharHasCurrentAddress;
        delete data?.NONINDIVIDUAL.address;
        delete data?.NONINDIVIDUAL.addressType;

        if (data.NONINDIVIDUAL.isThisAnMSME === 'false') {
          delete data.NONINDIVIDUAL.msmeClassification;
          delete data.NONINDIVIDUAL.isTheMSMEUdyamRegistered;
        }
        modifiedPayload = {
          [applicantType]: {
            applicantMyDetails: data?.applicantMyDetails,
            employmentInfo: data.NONINDIVIDUAL,
            applicantAddress: [],
          },
          coApplicant: [],
        };
      } else if (['BANK_SALARIED_PRIVATE', 'CASH_SALARIED', 'BANK_SALARIED_GOVT_PSU'].includes(occupation)) {
        modifiedPayload = {
          [applicantType]: {
            applicantMyDetails: data?.applicantMyDetails,
            employmentInfo: data.bankOrCash,
            applicantAddress,
          },
          coApplicant: [],
        };
      } else if (occupation === 'SELF_EMPLOYED_PROFESSIONAL') {
        modifiedPayload = {
          [applicantType]: {
            applicantMyDetails: data?.applicantMyDetails,
            employmentInfo: data.SELF_EMPLOYED_PROFESSIONAL,
            applicantAddress,
          },
          coApplicant: [],
        };
      } else if (occupation === 'SELF_EMPLOYED_BUSINESSPERSON') {
        if (data.SELF_EMPLOYED_BUSINESSPERSON.isThisAnMSME === 'false') {
          delete data.SELF_EMPLOYED_BUSINESSPERSON.msmeClassification;
          delete data.SELF_EMPLOYED_BUSINESSPERSON.isTheMSMEUdyamRegistered;
        }
        modifiedPayload = {
          [applicantType]: {
            applicantMyDetails: data?.applicantMyDetails,
            employmentInfo: data.SELF_EMPLOYED_BUSINESSPERSON,
            applicantAddress,
          },
          coApplicant: [],
        };
        modifiedPayload[`${applicantType}`].employmentInfo.address = [];
      } else {
        modifiedPayload = {
          [applicantType]: {
            applicantMyDetails: data?.applicantMyDetails,
            applicantAddress,
            employmentInfo: {},
          },
          coApplicant: [],
        };
        modifiedPayload[`${applicantType}`].employmentInfo.address = [];
      }
    } else {
      modifiedPayload = {
        [applicantType]: {
          applicantMyDetails: {
            id: data?.applicantMyDetails?.id || null,
            phoneNumber: data?.applicantMyDetails.phoneNumber,
            crmAccountId: data?.applicantMyDetails.crmAccountId,
            customerType: data?.applicantMyDetails.customerType,
            name: data?.applicantMyDetails.name,
            panCardNumber: data?.applicantMyDetails.panCardNumber,
          },
          applicantAddress,
        },
      };
    }
    if (applicantType === 'applicant') {
      delete modifiedPayload.applicant?.applicantMyDetails.relationshipWithMainApplicant;
    }
    if (modifiedPayload[applicantType]?.employmentInfo?.address) {
      modifiedPayload[applicantType].employmentInfo.addressType = 'OFFICE';
    }
    if (modifiedPayload[`${applicantType}`]?.applicantAddress?.length > 0) {
      modifiedPayload[`${applicantType}`].applicantAddress[0] = {
        ...applicantAddress[0],
        addressType: 'CURRENT',
      };
      modifiedPayload[`${applicantType}`].applicantAddress[1] = {
        ...applicantAddress[1],
        addressType: 'PERMANENT',
      };
    }
    return modifiedPayload[applicantType];
  };

  const onFormSubmit = async (data, redirect) => {
    const modifiedPayload = [];
    const newObject = [];
    modifiedPayload.applicant = readyPayloadFunction(data?.applicant, 'applicant');
    if (data['co-applicant']) {
      data['co-applicant'].forEach((currentValue) => {
        const returnedValue = readyPayloadFunction(currentValue, 'co-applicant');
        newObject.push(returnedValue);
      });
    }

    const payload = {
      applicant: { ...modifiedPayload.applicant },
      coApplicant: newObject,
      addCoApplicant: !redirect,
    };
    // setShowLoader(true);
    const res = await postApplicantDetails({apiKey: 'POST_APPLICANT_INFO', payload, payloadPutIdState });
    if (res.status) {
      if (!redirect) {
        await getApplicantDetailsFun();
        return true;
      }
      hanelMixPanelEvents.call(mixpanel, 'Applicant Details', 'Applicant Details Submitted');
      if (isMobile) {
        navigate(ROUTE.ASSET_DETAILS, { replace: true });
      } else {
        setActiveStep(activeStep + 1);
      }
    } else {
      setErrorMessage({ title: getdescriptiveMessage(res), show: true });
    }
    setShowLoader(false);
    return false;
  };


  const checkValidAccordions = async (openErrorAcc, coApp, clearError) => {
    const { res, allAvailabeAcc } = await valiateFields(coApp || coApplicants, clearError || !openErrorAcc);
    if (openErrorAcc) {
      setResponseForValidation(res, expanded, setExpanded, allAvailabeAcc, false, false);
    }
    setResponseForValidation(res, accordionStatus, setAccordionStatus, allAvailabeAcc, true, true);
    return { res, allAvailabeAcc };
  };

  const setResponseForValidation = (res, arr, setState, allAvailabeAcc, conditionToBeMet, respectDuplicate) => {
    const arrS = [];
    res.forEach((item, index) => {
      const typeArr = allAvailabeAcc[index].split('.');
      const lastElem = typeArr[typeArr.length - 1];
      if (
        ['bankOrCash', 'SELF_EMPLOYED_BUSINESSPERSON', 'SELF_EMPLOYED_PROFESSIONAL', 'NONINDIVIDUAL'].includes(lastElem)
      ) {
        typeArr.splice(typeArr.length - 1, 1, 'employmentInfo');
      }
      const indExists = arrS.indexOf(typeArr.join('.'));
      if (item === conditionToBeMet && indExists === -1) {
        arrS.push(typeArr.join('.'));
      } else if (item !== conditionToBeMet && respectDuplicate && indExists > -1) {
        arrS.splice(indExists, 1);
      }
    });
    setState(arrS);
  };

  const valiateFields = async (coApp, clearError) => {
    const allAvailabeAcc = [];
    const arrAvlbl = ['applicant', ...coApp.map((item, index) => `co-applicant.${index}`)];
    arrAvlbl.forEach((item) => {
      allAvailabeAcc.push(`${item}.applicantAddress`);
      allAvailabeAcc.push(`${item}.applicantMyDetails`);
      const customerType = getValues(`${item}.applicantMyDetails.customerType`);
      if (customerType === 'NONINDIVIDUAL') {
        return allAvailabeAcc.push(`${item}.NONINDIVIDUAL`);
      }
      if (
        ['SELF_EMPLOYED_BUSINESSPERSON', 'SELF_EMPLOYED_PROFESSIONAL'].includes(
          getValues(`${item}.applicantMyDetails.occupation`)
        )
      ) {
        return allAvailabeAcc.push(getValues(`${item}.applicantMyDetails.occupation`));
      }
      return allAvailabeAcc.push(`${item}.bankOrCash`);
    });
    const res = await Promise.all(
      allAvailabeAcc.map(async (item) => {
        const isExistingError = getReccurssiveObjectKeys(errors, item);
        const error = await trigger(item);
        if (clearError && !isExistingError) {
          clearErrors(item);
        }
        return error;
      })
    );
    return { res, allAvailabeAcc };
  };


  return (
  <FormProvider methods={methods} onSubmit={handleSubmit(onFormSubmit)}>
  <Box sx={{ width: '100%', padding: '20px',  background:'#F9FAFB' }}>
  <Box sx={{ display: 'flex', justifyContent: 'center', marginTop:'25px', padding: { xl: '40px' } }}>        
    <Tabs value={tabValue} onChange={handleTabChange} aria-label="tabs">
        <Tab label={t("PERSONAL_DETAILS")} disabled={!personalDetailsNextButton} />
        <Tab label={t("IDENTIFICATION_DOCUMENT")} disabled={identifyVerificationNextButton}/>
        <Tab label={t("CURRENT_ADDRESS")} disabled={currrentAddressNextButton}/>
        <Tab label={t("PERMANENT_ADDRESS")} disabled={permanentAddressNextButton} />
        </Tabs>
        </Box>
        <Box sx={{
        height: {md:'calc(100vh - 215px)'},
          overflowY: 'auto', 
          marginTop:'10px', 
           scrollbarWidth: 'none', /* Firefox */
          '&::-webkit-scrollbar': {
              display: 'none' /* Chrome, Safari, and Edge */
          }
        
        }}
          
          
          >
            
        {/* Personal Details Tab */}
        {tabValue === 0 && (
         
          <PersonalDetails
          watch={watch}
          isApplicantDetailsDisabledAfterPANVerification={isApplicantDetailsDisabledAfterPANVerification}
          onDateChange={onDateChange}
          convertTextToUpperCase={convertTextToUpperCase}
          callPANVerificationAPI={callPANVerificationAPI} 
          setTabValue={setTabValue}
          activeStep={activeStep}
          setActiveStep={setActiveStep}
          setPersonalDetailsNextButton={setPersonalDetailsNextButton}
          personalDetailsNextButton={personalDetailsNextButton}
          tabValue = {tabValue}
          />
          
         
        )}

        {/* Identity Verification Tab */}
        {tabValue === 1 && (

         <IdentityVerification
            watch={watch}
            getValues={getValues}
            isApplicantDetailsDisabledAfterPANVerification={isApplicantDetailsDisabledAfterPANVerification}
            convertTextToUpperCase={convertTextToUpperCase}
            handleChange={handleChange}
            expanded
            tabValue={tabValue}
            setTabValue={setTabValue}
            checkValueEntered={checkValueEntered}
            onDateChange={onDateChange}
            identifyVerificationNextButton={identifyVerificationNextButton}
            setIdentifyVerificationNextButton={setIdentifyVerificationNextButton}
            t={t} />
        )} 

        {/* Current Address Tab */}
        {tabValue === 2 && (
          <>
            
              <AddressInformationsForm
              name={{
                plotNumber: `${formfor}.applicantAddress.current.plotNumber`,
                floorNumber: `${formfor}.applicantAddress.current.floorNumber`,
                streetAndBuildingName: `${formfor}.applicantAddress.current.streetAndBuildingName`,
                locality: `${formfor}.applicantAddress.current.locality`,
                landmark: `${formfor}.applicantAddress.current.landmark`,
                pinCode: `${formfor}.applicantAddress.current.pinCode`,
                villageOrTown: `${formfor}.applicantAddress.current.villageOrTown`,
                tehsil: `${formfor}.applicantAddress.current.tehsil`,
                state: `${formfor}.applicantAddress.current.state`,
                villages: `${formfor}.applicantAddress.current.villages`,
                numberOfMonthsAtThisAddress: `${formfor}.applicantAddress.current.numberOfMonthsAtThisAddress`,
                propertyOwnership: `${formfor}.applicantAddress.current.propertyOwnership`,
              }}
              reset={reset}
              tabValue={tabValue}
              setTabValue={setTabValue}
              t={t}
              getValues={getValues}
              setValue={setValue}
              watchAddress={watchAddress}
              typeOfAddress={watchAddress?.current}
              handleOwnertype={handleOwnertype} 
              currrentAddressNextButton={currrentAddressNextButton}
              setCurrrentAddressNextButton={setCurrrentAddressNextButton}
              watch={watch}
              />
              <Box sx={{display:'flex', alignItems:'flex-start', justifyContent:'flex-start', padding:'0 20px'}} >
              <Checkbox
              sx={{
                padding:'0',
                marginRight:'10px'
              }}
              checked={isSameAsCurrentAddress}
              onChange={handleSwithChange.bind(this, `${formfor}.applicantAddress.permanent.isSameAsCurrentAddress`)} 
              />
              <Box>
              <Typography sx={{ fontWeight: 'bold', lineHeight:'normal' }}>{t(`SAME_AS_CURRENT_ADD`)}</Typography>
              <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                {t("KEEP_SAME_AS_CURRENT_ADDRESS")}
        </Typography>

              </Box>
               </Box>
          
          </>
          
        )} 

        {/* Permanent Address Tab */}
         {tabValue === 3 && (
            <>
          
               <>
            <AddressInformationsForm
            name={{
              plotNumber: `${formfor}.applicantAddress.permanent.plotNumber`,
              floorNumber: `${formfor}.applicantAddress.permanent.floorNumber`,
              streetAndBuildingName: `${formfor}.applicantAddress.permanent.streetAndBuildingName`,
              locality: `${formfor}.applicantAddress.permanent.locality`,
              landmark: `${formfor}.applicantAddress.permanent.landmark`,
              pinCode: `${formfor}.applicantAddress.permanent.pinCode`,
              villageOrTown: `${formfor}.applicantAddress.permanent.villageOrTown`,
              tehsil: `${formfor}.applicantAddress.permanent.tehsil`,
              state: `${formfor}.applicantAddress.permanent.state`,
              villages: `${formfor}.applicantAddress.permanent.villages`,
              numberOfMonthsAtThisAddress: `${formfor}.applicantAddress.permanent.numberOfMonthsAtThisAddress`,
              propertyOwnership: `${formfor}.applicantAddress.permanent.propertyOwnership`,
            }}
            reset={reset}
            getValues={getValues}
            setValue={setValue}
            watchAddress={watchAddress}
            disable={isSameAsCurrentAddress}
            watch={watch}
            typeOfAddress={watchAddress?.permanent} 
            permanentAddressNextButton={permanentAddressNextButton}
             setPermanentAddressNextButton={setPermanentAddressNextButton}            
            
            /></>
          </>
       
        )}
        </Box>
    </Box>

    {tabValue === 3 && 
    <Stack
      direction="row"
      justifyContent="space-between"
      mt={5}
      sx={{
        position: 'fixed',
        bottom: 0,
        left: '225px',
        right: 0,
        bgcolor: 'white',
        padding: '10px',
      }}
    >
            <Button 
             onClick={()=>{setTabValue(tabValue - 1)}}
             variant="outlined" 
             sx={{ minWidth: 120,
             color: "#1B2A68",
             borderColor: "#1B2A68",
             '&:hover': {
              bgcolor: "#2A3C8B",
              color: "#FFFFFF",
               } 
             }} 
             >                
             {t('PREVIOUS')}
            </Button>
            <LoadingButton
              size="large"
              type="submit"
              variant="contained"
              loading={isSubmitting}
              onClick={()=>{handleSubmit()}}
              sx={{ minWidth: 120, bgcolor: "#1B2A68" }} // Optional styling to match button color
            >
              {t('NEXT')} ➜
            </LoadingButton>
          </Stack>
}
    </FormProvider>     
  );
})
