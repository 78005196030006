import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

// @mui
import { Box, Typography, Button, Card, CardContent, Grid,Stack } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import Lottie from 'lottie-react';


import { FormProvider, RHFCheckbox } from '../components/hook-form';
import { useYupValidatoins } from '../custom-hooks';
import {
  assetDetailsContainerStyle as ContainerStyle,
  assetDetailsContentStyle as ContentStyle,
} from '../styled-components';
import { CommonHeader } from '../components/common-components';
import { getFeeDetails, getPaymentLink, updatePaymentStatus } from '../services';
import { CContext } from '../components/utils';
import { constants } from '../constants';
import { filterErrorMessage, hanelMixPanelEvents, printErrorsOnConsole, toasterService } from '../helper';
import animationData from '../assets/images/animationCheck.json';

const { ROUTE } = constants;

export default React.memo(() => {
  const navigate = useNavigate();
  const { t } = useTranslation('translation');
  const { requireDynamic } = useYupValidatoins();
  const { object } = useYupValidatoins();
  const [feePayMent, setFeePayMent] = useState({});
  const [next, setNext] = useState(false);
  const [orderId, setOrderId] = useState();
  const [razorPayPaymentId,setRazorPayPaymentId] = useState();
  const [razorPaySignature,setRazorPaySignature] = useState();
	const [amountPaid, setAmountPaid] = useState(0);


  const [paymentId, setPaymentId] = useState('paid');
  const {
    mixpanel,
    isMobile,
    errorMessage,
    setShowLoader,
    setErrorMessage,
    setFeePayMentDone,
    setApplicationProgress,
    setUpdateApplicationProgress,
    applicationProgress: { completed },
		setActiveStep,
		activeStep,
  } = useContext(CContext);


  useEffect(() => {
    if (completed?.includes('isFeePaymentSaved')) {
      handlePaymentCompleted();
    }
  }, [completed]);

  const handlePaymentCompleted = () => {
    setShowLoader(false);
  };

  const getFeePayments = async () => {
    const res = await getFeeDetails();
      if (res.status && res.data && Object.keys(res.data).length > 0) {
          handlePaymentCompleted();
          setAmountPaid(res?.data?.orderCreatedData?.amount)
          setOrderId(res?.data?.orderId)
        setFeePayMent(res.data);
      } 
      return setShowLoader(false);
  }

  useEffect(() => {
      setShowLoader(true);
      getFeePayments();
  }, []);

  const termAndCOnditionSchema = object.shape({
    tAndCond: requireDynamic('bool', 'TERM_AND_CONDITION_REQUIRED').test(
      'validation',
      'SHOULD_BE_SELECTED',
      (value) => !!value
    ),
  });

  const methods = useForm({
    mode: 'all',
    resolver: yupResolver(termAndCOnditionSchema),
    defaultValues: {
      tAndCond: false,
    },
  });

  useEffect(() => {
    (async () => {
      setPaymentId(orderId);
          setFeePayMentDone(true);
          setNext(true);
          const res = await updatePaymentStatus({
            isPaymentDone: true,
            razorpay_order_id: orderId,
            razorpay_signature: razorPaySignature,
            razorpay_payment_id: razorPayPaymentId,
          });
          hanelMixPanelEvents.call(mixpanel, 'Fee Payments', 'Fee Payments Done');
          // getFeePayments();
          if (res) {
            setFeePayMentDone(true);
						setAmountPaid(res?.data?.orderCreatedData?.amount)
            setUpdateApplicationProgress((prev) => `${!prev}`);
          } else if (res?.status === false) {
            setErrorMessage({ title: res.error.t_message, show: true });
          }
       
    
    })();
  }, [orderId]);

  const {
    handleSubmit,
    setError,
    getValues,
    formState: { isSubmitting, errors },
  } = methods;


  return (
    <>
      <Box
        sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100vh',
            backgroundColor: '#f7f8fa',
            padding: '10px 90px'
        }}
        >
        {/* Success Icon */}
        <Lottie 
            animationData={animationData}
            style={{ width: 100, height: 100, marginBottom:30, }}
          />

        {/* Title and Subtitle */}
        <Typography variant="h4" sx={{ mt: 2, fontWeight: 'bold' }}>
          {t('PAYMENT_SUCCESSFUL')}
        </Typography>
        <Typography variant="body1" sx={{ color: '#6c757d', mt: 1 }}>
            {t('PAYMENT_OF_NON_REFUNDABLE_PART_PROCESSING_FEE_SUCCESSFUL')}
        </Typography>

        {/* Order Details */}
        <Card
            sx={{
            mt: 4,
            padding: '20px',
            borderRadius: '8px',
            width: '600px',
            boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)'
            }}
        >
            <CardContent>
            <Grid container justifyContent="space-between" > 
                <Typography variant="body2" sx={{ fontWeight: 'bold', color: '#6c757d' }}>
                {t('ORDER_ID')}
                </Typography>
                <Typography variant="body2" sx={{ fontWeight: 'bold', color: '#6c757d' }}>
                {t('AMOUNT_PAID')}
                </Typography>
              
            </Grid>
            <Grid container justifyContent="space-between" sx={{ mt: 2 }}>
                <Typography variant="h5" sx={{ fontWeight: 'bold', color: '#000' }}>
                {orderId}
                </Typography>
                <Typography variant="h5" sx={{ fontWeight: 'bold', color: '#000', }}>
                  {amountPaid}
                </Typography>
            </Grid>
            </CardContent>
        </Card>

        {/* Navigation Buttons */}
        <Stack
          direction="row"
          justifyContent="space-between"
          mt={5}
          sx={{
            position: 'fixed',
            bottom: 0,
            left: '225px',
            right: 0,
            bgcolor: 'white',
            padding: '10px',
            // background:'#F9FAFB'
          }}
        >
                <Button 
                variant='outlined'
                 sx={{ minWidth: 120,
                  color: "#1B2A68",
                  borderColor: "#1B2A68",
                  '&:hover': {
                   bgcolor: "#2A3C8B",
                   color: "#FFFFFF",
                    } 
                  }} 
                  onClick={()=>{setActiveStep(activeStep - 1)}}
                >
                {t('PREVIOUS')}
                </Button>

                <LoadingButton
                  size="large"
                  type="submit"
                  variant="contained"
                  sx={{ minWidth: 120, 
                    bgcolor: "#1B2A68",  
                    '&:hover': {
                    bgcolor: "#2A3C8B", 
                    }}} 
                  onClick={()=>{setActiveStep(activeStep + 1)}}
                >
                  {t("NEXT")} ➜
                </LoadingButton>
              </Stack>
      
        </Box>
    </>
  );
});
