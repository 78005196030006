import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Typography } from "@mui/material";

function createData(key, value) {
    return { key, value };
}

const rows = [];

const keyLabels = {
    documentType: "Document Type",
};

const createTheDocumentDetailArrayObject = (documentDetailsArray) => {
    rows.splice(0, rows.length);
    for (let index = 0; index < documentDetailsArray.length; index++) {
        const activeDocumentObject = documentDetailsArray[index];
        const updatedDocumentObject = [];
        Object.keys(activeDocumentObject).forEach((key) => {
            if (key !== 'documentSizeInKB') {
                updatedDocumentObject.push(createData(key, activeDocumentObject[key]));
            }
        });
        rows.push(updatedDocumentObject);
    }
};

const imageStyle = {
    width: '280px',
    height: 'auto',
    borderRadius: '8px',
};

const labelStyle = {
    paddingLeft: '16px',
};

const valueStyle = {
	color: '#00296b'
};

export default function DocumentDetailsPreview(props) {
    createTheDocumentDetailArrayObject(props?.documentDetailsArray);
    return (
        <>
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 300 }} aria-label="simple table">
                    <TableBody>
                        {rows.map((row, rowIndex) => (
                            row.map((activeDocument) => (
                                <TableRow
                                    key={`${rowIndex}-${activeDocument.key}`} 
                                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                                >
                                    <TableCell sx={{ width: '50%' }} align="left" component="th" scope="row">
                                        <Typography variant="h6" style={labelStyle}>
                                            {activeDocument.key === 'documentPath' ? '' : keyLabels[activeDocument.key] || activeDocument.key}
                                        </Typography>
                                    </TableCell>
                                    <TableCell sx={{ width: '50%' }} align="left">
                                        {activeDocument.key === 'documentPath' ? (
                                            <div>
                                                <img src={activeDocument.value} alt="Document" style={imageStyle} />
                                            </div>
                                        ) : (
                                            <Typography variant="h6" style={valueStyle}>
                                                {activeDocument.value}
                                            </Typography>
                                        )}
                                    </TableCell>
                                </TableRow>
                            ))
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
}
