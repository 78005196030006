import React, { memo, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import axios from 'axios';

// @mui
import { Typography, Box, LinearProgress, IconButton, Divider, Tooltip } from '@mui/material';
import { Close as CloseIcon, Done as DoneIcon, UploadFile as UploadFileIcon, Delete as DeleteIcon, AddPhotoAlternateOutlined as AddPhotoAlternateOutlinedIcon, Check as CheckIcon } from '@mui/icons-material';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';

// ----------------------------------------------------------------------

OCRCard.propTypes = {
  deleteFile: PropTypes.func.isRequired,
  onCancelUpload: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  fileData: PropTypes.object.isRequired,
  uploaded: PropTypes.bool.isRequired,
};

function OCRCard({ uploaded, label, name, fileData, onCancelUpload, onChange, fileSize, deleteFile, ...other }) {
  const inputRef = useRef();
  const [cancelRefresh, setCancelRefresh] = useState(false);
  const source = useMemo(() => axios.CancelToken.source(), [cancelRefresh]);
  const { t } = useTranslation('translation');

  const onCancelFileUpload = () => {
    onCancelUpload(source, name);
    setCancelRefresh((prev) => !prev);
  };

  const handleCircleClick = () => {
    if (!uploaded) {
      inputRef.current.click();
    }
  };

  const handleFileChange = (source, event) => {
    onChange(source, event);
  };

  const handleDeleteFile = (name) => {
    deleteFile(name);
  };

  const { asterisk } = other;

  return (
    <Box display="flex" flexDirection="column">
      <Typography sx={{ fontWeight: '500', mb: '15px' }}>
        {t(label)}
        {asterisk && !t(label).includes(' *') && ' *'}
      </Typography>
      <Box
        sx={{
          p: 2,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          border: '1px dashed #233685',
          marginBottom: '20px',
          background: '#E9EBF4',
          position: 'relative',
          borderRadius: '5px',
          width: '100%',
          maxWidth: '440px',
          minHeight: '214px',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '71px',
            width: '71px',
            borderRadius: '50%',
            background: 'white',
            border: '1px solid #233685',
            position: 'absolute',
            top: '40%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            cursor: uploaded ? 'default' : 'pointer',
          }}
          onClick={handleCircleClick}
        >
          {uploaded ? <CheckIcon sx={{ fontSize: 40, color: '#5162A8' }} /> : <AddPhotoAlternateOutlinedIcon sx={{ fontSize: 40, color: '#5162A8' }} />}
        </Box>
        {fileData?.status === 0 && (
          <>
            <Typography variant="h6" color="#5162A8" sx={{ position: 'absolute', bottom: '60px' }}>
              {t('UPLOAD_YOUR_File')}
            </Typography>
            <Typography variant="body2" color="#5162A8" sx={{ position: 'absolute', bottom: '30px' }}>
              {t('DRAG_AND_DROP_FILES_HERE')}
            </Typography>
          </>
        )}
        <input
          ref={inputRef}
          type="file"
          accept="image/png, image/jpg, image/jpeg, application/pdf"
          name={name}
          onChange={handleFileChange.bind(this, source)}
          style={{ display: 'none' }}
          disabled={uploaded}
          {...other}
        />
        {fileData?.status !== 0 && (
          <Box sx={{ width: '100%', position: 'relative', top: '125px' }}>
            <Divider sx={{ width: '100%', background: '#E9EBF4', marginY: 1 }} />
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <UploadFileIcon sx={{ marginRight: '8px', color: '#233685' }} />
                <Box>
                  <Tooltip title={fileData?.file?.name || ''}>
                  <Typography
                    sx={{
                      color: 'rgba(0, 0, 0, 0.871)',
                      fontSize: '16px',
                      fontWeight: 400,
                      lineHeight: '19px',
                      letterSpacing: '0.125px',
                      textAlign: 'left',
                      width:'250px',
                      whiteSpace:'nowrap',
                      overflow:'hidden',
                      textOverflow:'ellipsis'
                    }}
                    variant="body1"
                  >
                    {fileData?.file?.name || ''}
                  </Typography>
                  </Tooltip>
                  {fileData?.status === 1 && !uploaded && (
                    <>
                      <Typography
                        variant="body2"
                        sx={{
                          color: 'var(--text-secondary, #00000099)',
                          fontSize: '13px',
                          fontWeight: 400,
                          lineHeight: '18px',
                          letterSpacing: '0.125px',
                          textAlign: 'left',
                        }}
                      >
                        {fileSize}kb • loading
                      </Typography>
                      <LinearProgress
                        variant="determinate"
                        value={50}
                        sx={{
                          height: '6px',
                          borderRadius: '3px',
                          backgroundColor: '#e0e0e0',
                          '& .MuiLinearProgress-bar': {
                            backgroundColor: '#233685',
                          },
                        }}
                      />
                    </>
                  )}
                  {(fileData?.status === 2 || uploaded) && (
                    <Typography
                      variant="body2"
                      sx={{
                        color: 'rgba(0, 0, 0, 0.6)',
                        fontSize: '13px',
                        fontWeight: 400,
                        lineHeight: '18px',
                        letterSpacing: '0.125px',
                        textAlign: 'left',
                      }}
                    >
                      {fileData?.file?.size}kb • Uploaded
                    </Typography>
                  )}
                </Box>
              </Box>
              {fileData?.status === 1 && !uploaded && (
                <IconButton onClick={onCancelFileUpload}>
                  <CloseIcon color="error" />
                </IconButton>
              )}
              {fileData?.status === 2 && (
                <Box>
                  <IconButton onClick={handleDeleteFile.bind(this, name)}>
                    <DeleteIcon color="grey" />
                  </IconButton>
                  <IconButton>
                    <CheckCircleRoundedIcon sx={{ color: '#2E7D32' }} />
                  </IconButton>
                </Box>
              )}
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
}

export default memo(OCRCard);
