import { styled, Box, StepLabel } from '@mui/material';

export const StepperBox = styled(Box)(({ theme }) => ({
  background: '#233685',
  height: '100vh',
  padding: '11px',
  display: 'flex',
  flexDirection: 'column',
}));

export const LogoSection = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'inherit',
  padding: theme.spacing(1),
}));

export const ContentSections = styled(Box)(({ theme }) => ({
  height: '80%',
  overflowY: 'auto',
  padding: theme.spacing(1),
}));

export const StepLabels = styled(StepLabel)(({theme}) => ({
     '& .MuiStepLabel-label': {
                          color: '#ffffff',
                          '&.Mui-completed': {
                            color: '#ffffff',
                          },
                          '&.Mui-active': {
                            color: '#ffffff',
                          },
                        },
                        '& .MuiStepIcon-root': {
                          fill: '#9CA3AF',
                          '&.Mui-completed': {
                            fill: '#0E9F6E',
                          },
                          '&.Mui-active': {
                            fill: '#0E9F6E',
                          },
                        },
}))
