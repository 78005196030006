/* eslint-disable react/display-name */
/* eslint-disable no-unused-vars */
import * as React from 'react';
import { Box } from '@mui/material';
import { CContext } from '../components/utils';
import PhotosForOcr from './PhotosForOcr';
import BasicInformation from './BasicInformation';
import ApplicantDetails from './ApplicantDetails';
import AssetDetails from './AssetDetails';
import FeePayment from './FeePayment';
import RequiredDocuments from './RequiredDocuments';
import { CommonHeader } from '../components/common-components';

import { formDefaultValues } from '../constants';
import ApplicationStatus from './ApplicationStatus';

export default React.memo(() => {
  const { activeStep } = React.useContext(CContext);

  return (
     <CommonHeader
        {...{
          webViewOnly: true,
        }}
      />
  );
});
