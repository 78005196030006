import React, { memo, useContext } from 'react';
import PropTypes from 'prop-types';
// @mui
import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { CContext } from '../utils';

const AppLoaderBox = styled(Box)(({ theme, showloader }) => ({
  '@keyframes spin': {
    '0%': {
      transform: 'rotate(0deg)',
    },
    '100%': {
      transform: 'rotate(360deg)',
    },
  },
  display: showloader === 'true' ? 'flex' : 'none',
  height: '100%',
  width: '100%',
  position: 'fixed',
  zIndex: 999,
  top: 0,
  left: 0,
  justifyContent: 'center',
  background: theme.palette.grey[3000],
  alignItems: 'center',
  '& .app-loader': {
    border: `3.6px solid ${theme.palette.primary.dark}`,
    borderRight: `3.6px solid transparent`,
    borderRadius: '50%',
    width: 40,
    height: 40,
    animation: 'spin .9s linear infinite',
    WebkitAnimation: 'spin .9s linear infinite',
  },
}));

function AppLoader({ suspenseLoader }) {
  const { showLoader } = useContext(CContext);

  return (
    <AppLoaderBox showloader={(suspenseLoader || showLoader).toString()}>
      {showLoader && <Box className="app-loader" />}
    </AppLoaderBox>
  );
}
AppLoader.propTypes = {
  suspenseLoader: PropTypes.bool,
};
export default memo(AppLoader);
