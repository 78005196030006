import React, { useContext, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

// @mui
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { Stack, Box, Typography, Switch, Button, Dialog, FormControlLabel, Checkbox, DialogActions, DialogContent, Divider } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

// sections
// ----------------------------------------------------------------------
import { Route, useNavigate } from 'react-router-dom';
import { FormProvider, RHFTextField, RHFSelectbox, RHFPhoneNumberInput } from '../components/hook-form';
import { useYupValidatoins } from '../custom-hooks';
import { formDefaultValues } from '../constants';
import { basicInfoContentStyle as ContentStyle, basicInfoContainerStyle as ContainerStyle } from '../styled-components';
import { CContext } from '../components/utils';
import { getBasicInformationFrom, postBasicInformationFrom, updateBasicInformationFrom } from '../services';
import { printErrorsOnConsole, hanelMixPanelEvents, toasterService } from '../helper';
import AssistedByForm from './AssistedByForm';
import PersonalDetailsForm from './PersonalDetailsForm';

const ReferenceAddressComponent = ({ name, index, referenceError }) => {
  const { t } = useTranslation('translation');

  return (
    <Stack sx={{ width: '100%' }}>
     
      <Stack direction="row" spacing={3} mt={2} mb={1}>
        <RHFTextField
          name={`references.${index}.name`}
          label={`${name.toUpperCase()}_REFERENCE_NAME`}
          onlyText
          required
        />
    
      
        <RHFPhoneNumberInput 
        sx={{
          height: '38px',
          padding: '5px 15px',
          boxSizing: 'border-box',
          marginTop: '0!important',
          borderRadius: '10px'
        }}
        helperText= {referenceError ? t('REFERENCES_NUMBER_CANNOT_BE_SAME') : null}
        hideFlag name={`references.${index}.phoneNumber`} label="PHONE_NUMBER" required />
        
    
      </Stack>
      <Stack spacing={3} mb={5} mt={3}>
        <RHFTextField
          name={`references.${index}.address`}
          label={t(`ADD_ADRESS_FOR_${name.toUpperCase()}_REFERENCE`)}
          multiline
          rows={2}

        />
      </Stack>
      <Stack spacing={3} mb={2}>
      <RHFTextField name={`references.${index}.referenceCity`} label="CITY" onlyText required />
      </Stack>
    </Stack>
  );
};

ReferenceAddressComponent.propTypes = {
  name: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
};

export default React.memo(() => {
  const { t } = useTranslation('translation');
  const {
    lang,
    errorMessage,
    setErrorMessage,
    setShowLoader,
    mixpanel,
		activeStep, setActiveStep, isMobile,
    applicationProgress: { completed },
  } = useContext(CContext);

  const navigate = useNavigate();
  const { object, string, requireDynamic, phoneNumber } = useYupValidatoins();
  const [applicationId, setApplicationId]= useState()
  const [selectedRole, setSelectedRole] = useState('');
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [employeeName, setEmployeeName] = useState('');
  const [employeeCode, setEmployeeCode] = useState('');
  const [isAssistedByEmployeeOfAavas, setIsAssistedByEmployeeOfAavas] = useState(false);
  const [isEmployeeAssisted, setIsEmployeeAssisted] = useState(false);
  const [isAssociateAssisted, setIsAssociateAssisted] = useState(false);
  const [isDataSaved, setIsDataSaved] = useState(false);
  const [referenceError, setReferenceError] = useState(false) // New state variable
  
  const basicInfoSchema = object.shape({
    loanTenureInMonths: requireDynamic('possitive-number', 'TENURE_REQUIRED')
      .test('minimum-check', 'MINIMUN_TENURE', (val) => val && +val > 11)
      .test('maximum-check', 'MAXIMUN_TENURE', (val) => val && +val <= 360)
      .test('range-check', 'VALUE_TOO_LARGE', (val) => val && +val <= 9007199254740991),
    product: requireDynamic('string', 'PRODUCT_REQUIRED'),
    endUseForMSME: string.when('product', {
      is: 'MSME',
      then: requireDynamic('string', 'END_USE_MSME_REQUIRED'),
    }),
    isAssistedByEmployeeOfAavas: string,
    assistanceByEmployee: string,
    assistanceByOthers: string,
    aavasEmployeeName: string.when('assistanceByEmployee', {
      is: 'true',
      then: requireDynamic('string', 'EMP_NAME_REQ'),
    }),
    aavasEmployeeCode: string.when('assistanceByEmployee', {
      is: 'true',
      then: requireDynamic('string', 'EMP_CODE_REQ'),
    }),
    associateUserId: string.when('assistanceByOthers', {
      is: 'true',
      then: requireDynamic('string', 'USER_ID_REQ'),
    }),
    associateVendorName: string.when('assistanceByOthers', {
      is: 'true',
      then: requireDynamic('string', 'VENDOR_CODE_REQ'),
    }),
    otherEmiPaidMonthly: string,
    typeOfLoan: requireDynamic('string', 'TYPE_OF_LOAN_REQUIRED'),
    // referCode: string.test('minimum-check', 'REFER_CODE_MIN_LENGTH', (val) => val.length < 1 || +val.length >= 5),
    requiredLoanAmount: requireDynamic('possitive-number', 'LOAN_AMOUTN_REQUIRED')
      .test('minimum-check', 'MINIMUM_LOAN_AMOUNT_200000', (val) => val && val >= 200000)
      .test('maximum-check', 'MAXIMUM_LOAN_AMOUNT_20000000', (val) => val && +val <= 10000000)
      .test('range-check', 'VALUE_TOO_LARGE', (val) => val && +val <= 9007199254740991),
    references: requireDynamic('array', 'TWO_REFERENCE_REQUIRED')
      .of(
        object.shape({
          name: requireDynamic('string', 'NAME_REQUIRED'),
          phoneNumber: requireDynamic('string', 'MOBILE_REQUIRED'),
          address: string,
          referenceCity: requireDynamic('string', 'CITY_REQUIRED'),
        })
      )
      .test({
        name: 'same-number',
        message: 'REFERENCES_NUMBER_CANNOT_BE_SAME',
        test: (value) => {
          const mobilenumber = value[0].phoneNumber;
          if (mobilenumber){
          const isSameNumber = value.every((item) => item.phoneNumber === mobilenumber);
          if (isSameNumber ){
            setReferenceError(true)
          }
          return !isSameNumber;
        }},
        testOptions: { path: `references.phoneNumber` },
      }),
  });
  
  const methods = useForm({
    resolver: yupResolver(basicInfoSchema),
    defaultValues: {
      ...formDefaultValues.basicInformationDefaultValues,
      references: [formDefaultValues.refDefaultValues, formDefaultValues.refDefaultValues],
    },
    mode: 'all',
  });
  
  const {
    handleSubmit,
    reset,
    getValues,
    setValue,
    watch,
    formState: { isSubmitting, errors ,isValid},
  } = methods;


  const getModifiedPayload = (data) => {
    const payload = { ...data, isAssistedByEmployeeOfAavas };
  
    if (isAssistedByEmployeeOfAavas) {
      if (isEmployeeAssisted) {
        payload.associateVendorName = null;
        payload.associateUserId = null;
        if (employeeName && employeeCode) {
          payload.assistedByEmployeeDetails = {
            employeeCode,
            employeeName,
          };
        }
      } else {
        payload.aavasEmployeeCode = null;
        payload.aavasEmployeeName = null;
      }
      if (isAssociateAssisted){
        payload.aavasEmployeeName = null;
        payload.aavasEmployeeCode = null;
        if (employeeName && employeeCode) {
          payload.assistedByAssociateDetails = {
            employeeCode,
            employeeName,
          };
        }
      } else {
        payload.associateUserId = null;
        payload.associateVendorName = null;
      }
      
    } else {
      payload.aavasEmployeeCode = null;
      payload.aavasEmployeeName = null;
      payload.associateVendorName = null;
      payload.associateUserId = null;
      payload.assistedByEmployeeDetails = null;
    }
  
    return payload;
  };
  

  const saveAndContinue = async (data) => {
    const payload = getModifiedPayload(data);
  
    setShowLoader(true);
    let res;
    try {
      if (applicationId) {
        res = await updateBasicInformationFrom({ apiKey: 'PUT_BASIC_INFO', payload }, applicationId);
      } else {
        res = await postBasicInformationFrom({ apiKey: 'POST_BASIC_INFO', payload });
      }
  
      if (res.status) {
        setActiveStep(activeStep + 1);
        hanelMixPanelEvents.call(mixpanel, 'Applicant Basic Informations', 'Basic Informations Submitted');
      } else {
        setErrorMessage({ title: res?.error?.error?.message, show: true });
      }
    } catch (error) {
      console.error("Error:", error);
      setErrorMessage({ title: "An error occurred", show: true });
    } finally {
      setShowLoader(false);
    }
  };
  

  
  const toggleDialog = () => {
    setIsDialogOpen(!isDialogOpen);
  };


  const checkIfFieldExist = (applicant) => {
    if (!applicant?.references) {
      applicant.references = [{}];
    } else {
      if (!applicant.references[0]) {
        applicant.references[0] = {};
      }
      if (!applicant.references[1]) {
        applicant.references[1] = {};
      }
    }
  
    if (applicant?.references[0]?.referenceName) {
      applicant.references[0].name = applicant.references[0].referenceName;
    }
    if (applicant?.references[1]?.referenceName) {
      applicant.references[1].name = applicant.references[1].referenceName;
    }
    if (applicant?.references[0]?.phoneNumber?.indexOf('+91') === -1) {
      applicant.references[0].phoneNumber = `+91${applicant.references[0].phoneNumber}`;
    }
    if (applicant?.references[1]?.phoneNumber?.indexOf('+91') === -1) {
      applicant.references[1].phoneNumber = `+91${applicant.references[1].phoneNumber}`;
    }
  
    return applicant;
  };

  const getValuesForPayload = (applicant, res) => {
    const values = {
      ...applicant,
      ...res.data,
    };
    values.emailId = res?.data?.emailId || res?.data?.applicant?.emailId || res?.data?.applicant?.email;
    return values;
  };

  const sanitizeValuesObject = (values) => {
    Object.keys(values).forEach((item) => {
      if (values[item] === undefined || values[item] === null) {
        delete values[item];
      }
    });
    if (values?.references?.length) {
      Object.keys(values?.references).forEach((item, index) => {
        if (values[item] === undefined || values[item] === null) {
          Object.keys(item).forEach((i2) => {
            if (item[i2] === undefined || item[i2] === null) {
              delete values?.references[index][item];
            }
          });
        }
      });
    }
  };

  const handleNextStep = () => {
    if (isMobile) {
      navigate(Route.PHOTOS_FOR_OCR, { replace: true });
    } else {
      setActiveStep(activeStep - 1);
    }
  };

  const getBasicInfoDetails = async () => {
    setShowLoader(true);
    const res = await getBasicInformationFrom({responseKey: 'GET_BASIC_INFO'});
      if (res && res?.status && res?.data) {
      setApplicationId(res?.data?.applicationId);
      const { applicant } = res?.data;
      if (applicant) checkIfFieldExist.call(null, applicant);
      if (res?.data?.applicant?.associateVendorName === null && res?.data?.applicant?.aavasEmployeeName === null) {
        setValue('assistanceByEmployee', false, { shouldValidate: true });
        setValue('assistanceByOthers', false, { shouldValidate: true });
      } else if (res?.data?.applicant?.associateVendorName === null) {
        setValue('assistanceByEmployee', true, { shouldValidate: true });
        setValue('assistanceByOthers', false, { shouldValidate: true });
      } else if (res?.data?.applicant?.aavasEmployeeName === null) {
        setValue('assistanceByOthers', true, { shouldValidate: true });
        setValue('assistanceByEmployee', false, { shouldValidate: true });
      }
      const values = getValuesForPayload.call(null, applicant || {}, res);
      if(values?.applicant?.aavasEmployeeCode && values?.applicant?.aavasEmployeeName ){
        setEmployeeCode(values?.applicant?.aavasEmployeeCode)
        setEmployeeName(values?.applicant?.aavasEmployeeName)
        setIsEmployeeAssisted(true)
      } else if (values?.applicant?.associateUserId && values?.applicant?.associateVendorName){
        setEmployeeCode(values?.applicant?.associateUserId)
        setEmployeeName(values?.applicant?.associateVendorName)
        setIsAssociateAssisted(true)
      } else {
        setIsEmployeeAssisted(false)
        setIsAssociateAssisted(false)

      }
      if (values.email) delete values.email;
      sanitizeValuesObject.call(null, values);
      reset({ ...getValues(), ...values });
    } else {
      setErrorMessage({ title: res?.error?.msg, show: true });
    }
    setShowLoader(false);
  };

  useEffect(() => {
    getBasicInfoDetails();
  }, []);

  return (
    <Box>
      <ContainerStyle iserrordisplayed={`${errorMessage.show}`}>
        <ContentStyle>
          <FormProvider methods={methods} onSubmit={handleSubmit(saveAndContinue, printErrorsOnConsole.bind(this))}>
            <Stack direction="column" spacing={3} mb={5}>
            <Stack direction="column" spacing={3} mb={5}>
            <Stack direction="row" spacing={3} mb={5}>
            <RHFSelectbox name="typeOfLoan" label="TYPE_OF_LOAN" menus={formDefaultValues.typeOfLoan} required />
            <RHFSelectbox name="product" label="PRODUCT" menus={formDefaultValues.products} required />
            {watch('product') === 'MSME' && (
              <Stack spacing={3} mb={5}>
                <RHFSelectbox name="endUseForMSME" label="FOR_MSME" menus={formDefaultValues.endUseForMSME} required />
              </Stack>
            )}
            </Stack>
  
              <Stack direction="row" spacing={3} mb={5}>
                <RHFTextField name="requiredLoanAmount" label="REQUIRED_LOAN_AMMOUNT" type="number" required />
                <Box>
                <RHFTextField name="loanTenureInMonths" 
                label="LOAN_TENURE" type="number" 
                noInitalZero required inputProps={{ maxLength: 3 }} />    
                <Typography variant='body2' sx={{marginTop:'5px',color:'#6B7280'}} >{t("IN_MONTHS_MORE_THEN")}</Typography>

                </Box>          
                <Box>
                <RHFTextField name="otherEmiPaidMonthly" label="OTHER_EMI_PAID" type="number" />
                <Typography variant='body2' sx={{marginTop:'5px',color:'#6B7280'}} >
                  {t("IF_ANY_PAID_MONTHLY")}
                </Typography>
                </Box>
              </Stack>
            </Stack>
  
            <Box>
            <Typography variant="h6" mb={2}>
              {t('REFERENCES')}
            </Typography>
            <Stack direction="row" spacing={3} width="100%">
            {['first', 'second'].map((item, index) => (
              <ReferenceAddressComponent
                key={item}
                errors={errors}
                index={index}
                name={item}
                reset={reset}
                getValues={getValues}
                referenceError={referenceError}
              />
            ))}
            </Stack>
            </Box>
            </Stack>
            <Button 
        variant="text" 
        sx={{ color: '#233685' }} 
        startIcon={isDataSaved || employeeName  ? <CheckCircleIcon sx={{ color: 'green' }} /> : <AddCircleIcon />} 
        onClick={toggleDialog}
      >
        {isDataSaved || employeeName ? t("ASSISTANT_DETAIL_ADDED") : t('ADD_ASSISTANT_DETAILS')}
          

      </Button>

      <Dialog open={isDialogOpen} onClose={toggleDialog} maxWidth="sm" fullWidth sx={{ "& .MuiDialog-paper": { borderRadius: "0px", backgroundColor: 'transparent', boxShadow: 'none', alignContent: 'center' } }} >
        <AssistedByForm toggleDialog={toggleDialog} setSelectedRole={setSelectedRole} setEmployeeCode={setEmployeeCode} setEmployeeName={setEmployeeName} employeeCode={employeeCode} employeeName={employeeName} selectedRole={selectedRole} setIsAssistedByEmployeeOfAavas={setIsAssistedByEmployeeOfAavas} setIsEmployeeAssisted={setIsEmployeeAssisted} setIsAssociateAssisted={setIsAssociateAssisted} setIsDataSaved={setIsDataSaved} isEmployeeAssisted={isEmployeeAssisted} isAssociateAssisted={isAssociateAssisted} />
      </Dialog>

            <Stack direction="row" justifyContent="space-between" mt={5} sx={{ position: 'fixed',bottom: 0,left: '225px',right: 0,bgcolor: 'white',padding: '10px',}}>
                <Button onClick={()=>{handleNextStep()}} variant="outlined" sx={{ minWidth: 120,color: "#1B2A68",borderColor: "#1B2A68", '&:hover': {bgcolor: "#2A3C8B",color: "#FFFFFF", }    }} >
                {t('PREVIOUS')}
                </Button>
                <LoadingButton size="large" type="submit" variant="contained" loading={isSubmitting} disabled={!isValid} sx={{ minWidth: 120, bgcolor: "#1B2A68", '&:hover': { bgcolor: "#2A3C8B" } }}>{t('NEXT')} ➜</LoadingButton>
              </Stack>
          </FormProvider>
        </ContentStyle>
      </ContainerStyle>
    </Box>
  );
});
