import React from 'react';
import { Box, Container, Typography } from '@mui/material';
import Lottie from 'lottie-react';
import animationData from '../assets/images/animationIcon.json';

const EmailSent = ({ selectedOption, contactInfo, setShowForgotPassword }) => {
  return (
    <Container maxWidth="xs">
      <Box>
        <Box sx={{ marginLeft: 10 }}>
          <Lottie 
            animationData={animationData}
            style={{ width: 100, height: 100 }}
          />
        </Box>
        <Typography variant="h5" component="h1" gutterBottom>
          {selectedOption === 'email' ? 'Email Sent' : 'SMS Sent'}
        </Typography>
        <Typography variant="body2" gutterBottom>
          We have sent a {selectedOption === 'email' ? 'email' : 'SMS'} to <strong>{contactInfo}</strong>
         {''} Check your {selectedOption === 'email' ? 'inbox' : 'messages'} and follow the instructions to reset your account password.
        </Typography>
      </Box>
      <Box mt={2}>
              <Typography
                variant="body2"
                onClick={() => setShowForgotPassword(false)} // Navigate to login page
                sx={{
                  textAlign: 'center',
                  color: '#192C79',
                  cursor: 'pointer',
                  '&:hover': {
                    textDecoration: 'underline',
                    backgroundColor: 'transparent',
                  },
                }}
              >
                Back to login
              </Typography>
            </Box>
    </Container>
  );
};

export default EmailSent;
