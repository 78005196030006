//
import Paper from './Paper';
import Input from './Input';
import Button from './Button';
import Tooltip from './Tooltip';
import Typography from './Typography';
import CssBaseline from './CssBaseline';
import Switch from './Switch';
import Checkbox from './Checkbox';
import Chip from './Chip';
import Menu from './MenuList';
// ----------------------------------------------------------------------

export default function ComponentsOverrides(theme) {
  return Object.assign(
    Input(theme),
    Paper(theme),
    Button(theme),
    Tooltip(theme),
    Typography(theme),
    CssBaseline(theme),
    Switch(theme),
    Checkbox(theme),
    Chip(theme),
    Menu(theme)
  );
}
