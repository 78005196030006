import React, { lazy, useContext, useEffect, useState } from 'react';
import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import LogoOnlyLayout from './layouts/LogoOnlyLayout';
import { PagesWithTitle } from './components/common-components';
// constants
import { constants, formDefaultValues } from './constants';
import { CContext } from './components/utils';
import LoginPageLayout from './layouts/LoginPageLayout';
import { ResetPassword } from './pages/ResetPassword';

const { ROUTE } = constants;

// ----------------------------------------------------------------------
// lazy loading for all pages
const BasicInformation = lazy(() => import('./pages/BasicInformation'));
const ApplicationProgress = lazy(() => import('./pages/ApplicationProgress'));
const ApplicationStatus = lazy(() => import('./pages/ApplicationStatus'));
const LoginForm = lazy(() => import('./pages/LoginForm'));
const RegisterForm = lazy(() => import('./pages/RegisterForm'));
const ApplicantDetails = lazy(() => import('./pages/ApplicantDetails'));
const AssetDetails = lazy(() => import('./pages/AssetDetails'));
const FeePayment = lazy(() => import('./pages/FeePayment'));
const RequiredDocuments = lazy(() => import('./pages/RequiredDocuments'));
const LoanApplication = lazy(() => import('./pages/LoanApplication'));
const InitialScreen = lazy(() => import('./pages/InitialScreen'));
const DataLogs = lazy(() => import('./pages/DataLogs'));

export default function Router() {
  const { activeStep } = useContext(CContext);
  const [title, setTitle] = useState('LOAN_APPLICATION');

  useEffect(() => {
    setTitle(formDefaultValues.titleAsTabs[activeStep || 0]);
  }, [activeStep]);

  return useRoutes([
		{
      path: '/reset-password',
      element: <ResetPassword />,
    },
    {
      path: ROUTE.ROOT,
      element: <LogoOnlyLayout />,
      children: [
        { path: ROUTE.ROOT, element: <LoginPageLayout />, 
        children:[
          { path: ROUTE.ROOT, element: <PagesWithTitle element={LoginForm} title="LOGIN" /> },
          { path: ROUTE.LOGIN, element: <PagesWithTitle element={LoginForm} title="LOGIN" /> },
          
        ] },
        { path: ROUTE.REGISTER, element: <PagesWithTitle element={RegisterForm} title="REGISTER" /> },
        {
          path: ROUTE.BASIC_INFORMATION,
          element: <PagesWithTitle element={BasicInformation} title="BASIC_INFORMATION" />,
        },
        {
          path: ROUTE.APPLICATION_PROGRESS,
          element: <PagesWithTitle element={ApplicationProgress} title="APPLICATION_PROGRESS" />,
        },
        {
          path: ROUTE.APPLICATION_STATUS,
          element: <PagesWithTitle element={ApplicationStatus} title="APPLICATION_STATUS" />,
        },
        {
          path: ROUTE.APPLICANT_DETAILS,
          element: <PagesWithTitle element={ApplicantDetails} title="APPLICANT_DETAILS" />,
        },
        { path: ROUTE.ASSET_DETAILS, element: <PagesWithTitle element={AssetDetails} title="ASSET_DETAILS" /> },
        { path: ROUTE.FEE_PAYMENT, element: <PagesWithTitle element={FeePayment} title="FEE_PAYMENT" /> },
        {
          path: ROUTE.REQUIRED_DOCUMENTS,
          element: <PagesWithTitle title="REQUIRED_DOCUMENTS" element={RequiredDocuments} />,
        },
				{
					path: ROUTE.APPLICATION_STATUS,
          element: <PagesWithTitle title="APPLICATION_STATUS" element={ApplicationStatus} />,
				},
        {
          path: ROUTE.DATA_LOGS,
          element: <PagesWithTitle element={DataLogs} title="DATA_LOGS" />,
        },
        {
          path: ROUTE.LOAN_APPLICAIONT,
          element: <PagesWithTitle element={LoanApplication} title={title} />,
        },
        {
          path: ROUTE.INITIAL_SCREEN,
          element: <PagesWithTitle element={InitialScreen} title="INITIAL_SCREEN" />,
        },
      ],
    },
    // {
    //   path: ROUTE.APPLICATION_STATUS,
    //   element: <PagesWithTitle element={ApplicationStatus} title="APPLICATION_STATUS" />,
    // },
    { path: ROUTE.ALL, element: <Navigate to={ROUTE.LOGIN} replace /> }
  ]);
}
