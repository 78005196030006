import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import PropType from 'prop-types';
import { useTranslation } from 'react-i18next';

// @mui
import { Typography, Stack } from '@mui/material';

// ----------------------------------------------------------------------
import { RHFTextField, RHFRadio, RHFMultiCheckbox, RHFSelectbox } from '../hook-form';
import AddressInformationsForm from './AddressInformationsForm';
import { setReccurssiveObjectKeys } from '../../helper';
import { formDefaultValues } from '../../constants';
import { CContext } from '../utils';
import houseIcon from '../../assets/images/houses.png';
import buildingIcon from '../../assets/images/building (3).png';
import commercialBuildingIcon from '../../assets/images/skyline 1.png';
import highRiseIcon from "../../assets/images/apartment.png";
import bungalowIcon from "../../assets/images/bungalow.png";
import vacantLandIcon from "../../assets/images/sign 1.png";
import residentialIcon from "../../assets/images/shop11.png";
import shopIcon from "../../assets/images/shop11.png";
import commercialOfficeIcon from "../../assets/images/Vector.png";
import SelectableCard from './AssetDetailAssetTypeCard';
import selectedCommercialBuildingIcon from '../../assets/images/AssetDetailsIcons/skyline 1 (1).png';
import selectedHighRiseIcon from '../../assets/images/AssetDetailsIcons/apartment (2).png';
import selectedHousesIcon from '../../assets/images/AssetDetailsIcons/houses (1).png';
import selectedBungalowIcon from '../../assets/images/AssetDetailsIcons/bungalow (1).png';
import selectedCommercialOfficeIcon from '../../assets/images/AssetDetailsIcons/office (1).png';
import selectedResidentialIcon from '../../assets/images/AssetDetailsIcons/shop (1) (1).png';
import selectedVacantLandIcon from '../../assets/images/AssetDetailsIcons/sign 1 (1).png';
import selectedShopIcon from '../../assets/images/AssetDetailsIcons/shop (3).png';
import selectedBuildingIcon from '../../assets/images/AssetDetailsIcons/building (2).png';


const propertyTypes = [
  { icon: houseIcon, selectedIcon: selectedHousesIcon, textKey: 'ROW_HOUSE', value: 'ROW_HOUSE' },
  { icon: vacantLandIcon, selectedIcon: selectedVacantLandIcon, textKey: 'VACANT_LAND', value: 'VACANT_LAND' },
  { icon: bungalowIcon, selectedIcon: selectedBungalowIcon, textKey: 'BUNGALOW', value: 'BUNGALOW' },
  { icon: buildingIcon, selectedIcon: selectedBuildingIcon, textKey: 'GPLUS_TWO', value: 'GPLUS_TWO' },
  { icon: highRiseIcon, selectedIcon: selectedHighRiseIcon, textKey: 'HIGH_RISE', value: 'HIGH_RISE' },
  { icon: residentialIcon, selectedIcon: selectedResidentialIcon, textKey: 'RESIDENTIAL_CUM_COMMERCIAL', value: 'RESIDENTIAL_CUM_COMMERCIAL' },
  { icon: commercialBuildingIcon, selectedIcon: selectedCommercialBuildingIcon, textKey: 'COMMERCIAL_BUILDING', value: 'COMMERCIAL_BUILDING' },
  { icon: commercialOfficeIcon, selectedIcon: selectedCommercialOfficeIcon, textKey: 'COMMERCIAL_OFFICE', value: 'COMMERCIAL_OFFICE' },
  { icon: shopIcon, selectedIcon: selectedShopIcon, textKey: 'COMMERCIAL_SHOP', value: 'COMMERCIAL_SHOP' }
];


const AssetDetails = ({
  formfor,
  reset,
  getValues,
  setValue,
  allApplicant,
  watchForpropertyOwnership,
  currentAddressData,
  watchForAddType,
  watchForPropertyIdentiFied,
  watchIsPropertyIdentified,
}) => {
  const { t } = useTranslation('translation');
  const { isMobile } = useContext(CContext);
  const [isFirsttime, setIsFistTime] = useState(true);
  const watchForAddTypeBool = useMemo(() => watchForAddType === 'true', [watchForAddType]);

  const [selectedType, setSelectedType] = useState(null);

  const handlePropertyTypeSelect = (value) => {
    if (selectedType === value) {
      setSelectedType(null);
      setValue(`${formfor}.typeOfProperty`, null, { shouldValidate: true });
    } else {
      setSelectedType(value);
      setValue(`${formfor}.typeOfProperty`, value, { shouldValidate: true });
    }
  };
  
  const propertyType = getValues(`${formfor}.typeOfProperty`)
  useEffect(() => {
    if (propertyType) {
      setSelectedType(propertyType);
      setValue(`${formfor}.typeOfProperty`, propertyType, { shouldValidate: true });
    }
  }, [propertyType, setValue, formfor]);

  useEffect(() => {
    if (watchForAddTypeBool) {
      reset(setReccurssiveObjectKeys({ ...getValues() }, `${formfor}.address`, currentAddressData));
    } else if (!isFirsttime) {
      reset(
        setReccurssiveObjectKeys({ ...getValues() }, `${formfor}.address`, {
          ...formDefaultValues.AssetDetailsDefaultValues.address,
        })
      );
    }
    setIsFistTime(false);
  }, [watchForAddTypeBool]);

  const handleOwnertype = (e) => {
    setValue(`${formfor}.propertyOwners`, e.target.value === 'INDIVIDUAL' ? null : [], { shouldValidate: true });
  };


  const PropertyOwners = useCallback(
    () =>
      allApplicant.length > 0 && (
        <Stack spacing={3} mt={5} mb={5} className="width-100">
          <RHFMultiCheckbox
            required
            name={`${formfor}.propertyOwners`}
            title="PROPERTY_OWNERS"
            options={allApplicant.map((item) => {
              const midleName = item.midleName ? ` ${item.midleName}` : '';
              const lastName = item.lastName ? ` ${item.lastName}` : '';
              return {
                label: item.firstName + midleName + lastName,
                value: item.id,
              };
            })}
          />
        </Stack>
      ),
    [allApplicant]
  );

  return (
    <React.Fragment key={formfor}>
      {watchForPropertyIdentiFied === 'true' && (
        <Stack spacing={3} mb={5} className="width-100">
          <RHFRadio
            name={`${formfor}.isPropertyIdentified`}
            title="IS_THE_PROPERTY_IDENTIFIED"
            labels={[
              { name: 'YES', value: true },
              { name: 'NO', value: false },
            ]}
            singleLineRadio
            required
          />
        </Stack>
      )}

      {watchForPropertyIdentiFied === 'true' && (
        <Stack spacing={3} mb={5} className="width-100">
          <RHFRadio
            name={`${formfor}.isPropertyGovtFunded`}
            title="IS_THE_PROPERTY_GOVT_FUNDED"
            labels={[
              { name: 'YES', value: true },
              { name: 'NO', value: false },
            ]}
            singleLineRadio
            required
          />
        </Stack>
      )}

      <Stack display="flex" justifyContent="center" spacing={3} mb={5} className="width-100" direction="row" flexWrap="wrap">
        {propertyTypes.slice(0, 5).map((property) => (
          <SelectableCard
            value={propertyType}
            key={property.value}
            icon={selectedType === property.value ? property.selectedIcon : property.icon}
            text={t(property.textKey)}
            isSelected={selectedType === property.value}
            onClick={() => handlePropertyTypeSelect(property.value)}
            sx={{ flexBasis: '20%', maxWidth: '20%' }} // 5 cards per row
          />
        ))}
      </Stack>

      <Stack spacing={3} mb={5} display="flex" justifyContent="center" className="width-100" direction="row" flexWrap="wrap">
        {propertyTypes.slice(5).map((property) => (
          <SelectableCard
            key={property.value}
            icon={selectedType === property.value ? property.selectedIcon : property.icon}
            text={t(property.textKey)}
            isSelected={selectedType === property.value}
            onClick={() => handlePropertyTypeSelect(property.value)}
            sx={{ flexBasis: '25%', maxWidth: '25%' }} 
          />
        ))}
      </Stack>

      {watchIsPropertyIdentified === 'true' && (
        <Stack spacing={3} mb={5} className="width-50">
          <RHFSelectbox
            name={`${formfor}.propertyTitle`}
            label="PROPERTY_TITLE"
            menus={formDefaultValues.propertyTitle}
            required
          />
        </Stack>
      )}
      
      {currentAddressData?.id && (
        <Stack spacing={3} mb={5} className="width-100">
          <RHFRadio
            name={`${formfor}.isCurrentAddSameAsCollateralProperty`}
            title="SAME_AS_CURRENT_ADDRESS"
            labels={[
              { name: 'YES', value: true },
              { name: 'NO', value: false },
            ]}
            singleLineRadio
            required
          />
        </Stack>
      )}

      {getValues(`${formfor}.displayProposedAreaOfConstruction`) && (
        <Stack spacing={3} mb={5} className="width-50">
          <RHFTextField
            name={`${formfor}.proposedAreaOfConstruction`}
            label="PROPOSED_AREA_CONSTRUCTION"
            type="number"
            required
          />
        </Stack>
      )}
      
      <AddressInformationsForm
        name={{
          plotNumber: `${formfor}.address.plotNumber`,
          floorNumber: `${formfor}.address.floorNumber`,
          streetAndBuildingName: `${formfor}.address.streetAndBuildingName`,
          locality: `${formfor}.address.locality`,
          landmark: `${formfor}.address.landmark`,
          pinCode: `${formfor}.address.pinCode`,
          villageOrTown: `${formfor}.address.villageOrTown`,
          tehsil: `${formfor}.address.tehsil`,
          state: `${formfor}.address.state`,
          owned: `${formfor}.propertyOwnership`,
          fromAddresses: false,
        }}
        reset={reset}
        getValues={getValues}
        setValue={setValue}
        disable={watchForAddTypeBool}
        handleOwnertype={handleOwnertype}
      />

      {/* <RHFRadio
          //   showTooltip
          name={`${formfor}.propertyOwnership`}
          title="PROPERTY_OWNERSHIP"
          required
          labels={[
            { name: 'FATHER_MOTHER', value: 'FATHER_MOTHER_OWNED' },
            { name: 'GRAND_PARENTS', value: 'GRAND_PARENTS_OWNED' },
            { name: 'OWNED', value: 'OWNED' },
            { name: 'RENTED_PREMISES', value: 'RENTED_PREMISES' },
          ]}
          //   tooltipText={<>{t('OWNER_SHIP_HELP_TEXT')}</>}
          //   tooltipPlacement={isMobile ? 'top' : 'right'}
          onChange={handleOwnertype.bind(this)}
        /> */}

      {watchForpropertyOwnership === 'OWNED' && <PropertyOwners ownershipType={watchForpropertyOwnership} />}
    </React.Fragment>
  );
};

AssetDetails.propTypes = {
  formfor: PropType.string,
  reset: PropType.func,
  getValues: PropType.func,
  setValue: PropType.func,
  watchForAddType: PropType.string,
  currentAddressData: PropType.object,
  watchForPropertyIdentiFied: PropType.string,
  allApplicant: PropType.array,
  watchForpropertyOwnership: PropType.string,
  watchIsPropertyIdentified: PropType.string,
};

export default React.memo(AssetDetails);
