import React from 'react';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { Typography, InputAdornment, Box, IconButton } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useTranslation } from 'react-i18next';
import { RHFTextField, RHFPhoneNumberInput, FormProvider } from '../components/hook-form';


const LoginForm = ({
  showMobileInputScreen,
  setShowForgotPassword,
  handleEmailClick,
  handleContinue,
  handleOtpClick,
  handleTogglePasswordVisibility,
  methods,
  showPassword,
  handleSubmit,
  onSubmit,
  isValid,
  isSubmitting
}) => {
  const { t } = useTranslation('translation');

  return (
    <FormProvider methods={methods} onSubmit={onSubmit}>
        {showMobileInputScreen ? (
          <RHFPhoneNumberInput
            showLoginUi
            name="mobile"
            placeholder="MobileNumber"
            hideFlag
            sx={{
              marginBottom: '20px',
            }}
          />
        ) : (
          <>
            <RHFTextField
              shwoTextFieldLogin
              name="email"
              placeholder={t('EMAIL')}
              fullWidth
              sx={{
                marginBottom: '20px',
                '& .MuiInputBase-input::placeholder': {
                  color: 'rgba(39, 39, 39, 0.4)',
                },
                '& .MuiOutlinedInput-root': {
                  backgroundColor: '#F5F6F8',
                },
              }}
            />

            <RHFTextField
              shwoTextFieldLogin
              placeholder={t('PASSWORD')}
              type={showPassword ? 'text' : 'password'}
              name="password"
              fullWidth
              sx={{
                marginBottom: '10px',
                '& .MuiInputBase-input::placeholder': {
                  color: 'rgba(39, 39, 39, 0.4)',
                },
                '& .MuiOutlinedInput-root': {
                  backgroundColor: '#F5F6F8',
                },
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      edge="end"
                      sx={{
                        cursor: 'pointer',
                        '&:hover': {
                          backgroundColor: 'transparent',
                        },
                      }}
                      onClick={handleTogglePasswordVisibility}
                    >
                      {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <Box>
              <Typography
                sx={{
                  display: 'flex',
                  justifyContent: 'end',
                  marginBottom: '20px',
                  fontSize: '15px',
                  cursor: 'pointer',
                  '&:hover': {
                    textDecoration: 'underline',
                    backgroundColor: 'transparent',
                  },
                }}
                color="#263B92"
                onClick={() => setShowForgotPassword(true)}
              >
                {t('FORGET_PASSWORD')}?{' '}
              </Typography>
            </Box>
          </>
        )}
        <LoadingButton
          type="submit"
          loading={isSubmitting}
          variant="contained"
          sx={{
            '&.MuiButtonBase-root': {
              backgroundColor: '#192C79',
              padding: '12px 16px',
              borderRadius: '3px',
            },
          }}
          fullWidth
        onClick={showMobileInputScreen ? handleContinue : handleSubmit}
          disabled={!isValid}
        >
          {showMobileInputScreen ? t('CONTINUE') : t('LOGIN')}
        </LoadingButton>
        <Typography
          variant="body2"
          color="#263B92"
          sx={{
            marginTop: '10px',
            marginBottom: '10px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            cursor: 'pointer',
            '&:hover': {
              textDecoration: 'underline',
              backgroundColor: 'transparent',
            },
          }}
        >
          <span
            onClick={showMobileInputScreen ? handleEmailClick : handleOtpClick}
            style={{
              color: '#192C79',
              cursor: 'pointer',
              marginLeft: '5px',
            }}
          >
                      {t('OR_LOGIN_WITH')}{' '}
                      {showMobileInputScreen ? 'EMAIL' : 'OTP'}
          </span>
        </Typography>
    </FormProvider>
  );
};

export default LoginForm;
