/* eslint-disable camelcase */
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import jwt_decode from 'jwt-decode';

// @mui
import { Typography, DialogTitle, Box, Grid, Slider, Button, Card, Dialog, IconButton } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import CloseIcon from '@mui/icons-material/Close';
import { getProvisionalOffer } from '../services';
import { CContext } from '../components/utils';
import { useProvisionalCalculation } from '../custom-hooks/useProvisionalCalculationUpdated';

export default React.memo(({ modalState, handleModalClose, onAcceptOffer, dataForCalculation, offerData }) => {
  const { t } = useTranslation('translation');
  const [, setNext] = useState(false);
  const [applicationFee, setApplicationFee] = useState();
  const [applicableInterestRate, setApplicableInterest] = useState();
  const [minimumLoanAmount, setMinimumLoanAmount] = useState(0);
  const [newEligibleAmount, setNewEligibleLoanAmount] = useState(0);
  const [updatedLoanAmountSlider, setUpdatedLoanAmountSlider] = useState(0);
  const [minimumLoanTenure, setMinimumLoanTenure] = useState(0);
  const [applicableLoanTenure, setNewApplicableTenure] = useState(0);
  const [updatedLoanTenureSlider, setUpdatedLoanTenureSlider] = useState(0);
	const [applicableMonthlyEmi, setApplicableMonthlyEmi] = useState(0);
  const [renderOffer, setRenderOffer] = useState(false);
  const {
    setShowLoader,
    setErrorMessage,
    applicationProgress: { completed },
  } = useContext(CContext);

  const { result, handleValue1Change, handleValue2Change } = useProvisionalCalculation(
    offerData,
    dataForCalculation,
    applicableInterestRate,
    minimumLoanAmount,
    minimumLoanTenure
  );

  const getUserName = () => {
    const token = sessionStorage.getItem('authToken');
    const { firstName, middleName, lastName } = jwt_decode(token);
    const nameParts = [firstName, middleName, lastName].filter(name => name);
    return nameParts.length ? nameParts.join(' ') : ' ';
  };
  
  const [userName] = useState(getUserName);
  

  useEffect(() => {
    if (completed?.includes('isFeePaymentSaved')) {
      handlePaymentCompleted();
    }
  }, [completed]);

  const handlePaymentCompleted = () => {
    setShowLoader(false);
    setNext(true);
  };

  const convertMonthsToYears = (months) => {
    const years = Math.round(months / 12);
    let result = '';
    if (years > 0) {
      result += years === 1 ? '1 year' : `${years} years`;
    }

    return result;
  };

  useEffect(() => {
    (async () => {
      setShowLoader(true);
      const res = await getProvisionalOffer();
      if (res.status) {
        setMinimumLoanAmount(res.data.offerData.minimumLoanAmount);
        setNewEligibleLoanAmount(res.data.offerData.maximumLoanAmount);
        setUpdatedLoanAmountSlider(res.data.offerData.minimumLoanAmount);
        setMinimumLoanTenure(res.data.offerData.minimumLoanTenure);
        setNewApplicableTenure(res.data.offerData.maximumLoanTenure);
        setUpdatedLoanTenureSlider(res.data.offerData.minimumLoanTenure);
        setApplicableInterest(res.data.offerData.applicableInterest);
        setApplicationFee(res.data.offerData.applicableAplicationFee);
        setApplicableMonthlyEmi(res.data.offerData.applicableMonthlyEmi);
        setRenderOffer(true);
      } else {
        setErrorMessage({ title: res.error.errors[0], show: true });
      }
      setShowLoader(false);
    })();
  }, []);

  const onChangeLoanAmount = (e) => {
    setUpdatedLoanAmountSlider(e);
    handleValue1Change(e);
  };

  const onChangeLoanTenure = (e) => {
    setUpdatedLoanTenureSlider(e);
    handleValue2Change(e);
  };

    useEffect(() => {
      if (result) {
        setApplicationFee(result.applicableAplicationFee);
        setApplicableMonthlyEmi(result.applicableEmiForLoanAmountAndTermOffered);
      }
    }, [result]);

  return (
    <>
      {modalState && renderOffer && (
          <Dialog
          onClose={handleModalClose}
          open={modalState}
          sx={{
            display:'flex', justifyContent:'center',
            '& .MuiPaper-root': {
              maxWidth: 'none',
              overflowY: 'visible',
              padding:'30px'
            },
          }}
        >
          <DialogTitle sx={{
        display:'flex',
        alignItems:'center',
        justifyContent: 'space-between'
      }}>
    <Typography color='#1B2A68' variant='h4'> {t('UNASSISTED')}</Typography>   
    <IconButton
          edge="end"
          color="inherit"
          onClick={handleModalClose}
          aria-label="close"
          sx={{
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
         
      <Box sx={{ padding: '32px', maxWidth: '800px', margin: 'auto',  }}>

      <Typography variant="h6" color='#374151' sx={{ marginBottom: '16px' }}>
      {t('CONGRATULATIONS')} {userName}
      </Typography>
      
      <Typography variant="body2" sx={{ marginBottom: '32px', color: 'text.secondary' }}>
      {t('PLEASE_NOTE_THIS_IS')}
      </Typography>
      
      <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
          <Box>
              <Typography variant="body2" color='#6B7280'>{t('LOAN_AMOUNT_TITLE')}</Typography>
              <Typography variant="h5" color='#1B2A68'sx={{ fontWeight: 'bold', marginBottom: '4px' }}>
              ₹ {updatedLoanAmountSlider}
              </Typography>

          <Slider
            name="loanAmount"
            value={updatedLoanAmountSlider}
            min={minimumLoanAmount}
            max={newEligibleAmount}
            step={1}
            onChange={(e) => onChangeLoanAmount(e.target.value)}
            marks={[
              { value: minimumLoanAmount, label: minimumLoanAmount },
              { value: newEligibleAmount, label: newEligibleAmount },
              ]}
            valueLabelDisplay="off"
            sx={{ color: '#1B2A68' }}
            />
          </Box>
      
          <Box sx={{marginBottom:'20px'}} >
              <Typography variant="body2" color='#6B7280'>{t('LOAN_TENURE_TITLE')}</Typography>
              <Typography variant="h5" color='#1B2A68' sx={{ fontWeight: 'bold', marginBottom: '8px' }}>
              {convertMonthsToYears(updatedLoanTenureSlider)}
              </Typography>
              <Slider
            value={updatedLoanTenureSlider}
            name="loanTenure"
            step={1}
            min={minimumLoanTenure}
            max={applicableLoanTenure}
            marks={[
              { value: convertMonthsToYears(minimumLoanTenure), label: convertMonthsToYears(minimumLoanTenure) },
              { value: convertMonthsToYears(applicableLoanTenure), label: convertMonthsToYears(applicableLoanTenure) },
              ]}
            valueLabelDisplay="off"
            onChange={(e) => onChangeLoanTenure(e.target.value)}
            sx={{ color: '#1B2A68' }}
            />
             
          </Box>
      
          <LoadingButton
                  size="large"
                  type="submit"
                  variant="contained"
                  sx={{ minWidth: 120, 
                    bgcolor: "#1B2A68",  
                    '&:hover': {
                    bgcolor: "#2A3C8B", 
                    }}} 
                  
                  onClick={() => {
                    onAcceptOffer(result);
                  }}
                >
                  {t('ACCEPT_OFFER_AND_PROCEED')}
                </LoadingButton>
          </Grid>
      
          <Grid item xs={12} md={6}>
          <Card sx={{ padding: '24px', textAlign: 'left', backgroundColor: 'rgba(0, 0, 0, 0.05)' }}>
              <Typography variant="body2" color="#6B7280" mb={1}>{t('YOUR_TOTAL_EMI_WILL_BE')}</Typography>
              <Typography variant="h2" color="#1B2A68" sx={{ fontWeight: 'bold', marginBottom: '16px' }}>
              <span style={{ fontSize: '0.4em', verticalAlign: 'super' }}>₹</span>   {applicableMonthlyEmi > 0
    ? applicableMonthlyEmi.toFixed(2)
    : result?.applicableEmiForLoanAmountAndTermOffered?.toFixed(2)}
      </Typography>
              <Grid container spacing={2} justifyContent="left">
              <Grid item>
                  <Typography variant="body2" sx={{  color: '#6B7280', marginBottom:'8px' }}>
                  {t('RATE_OF_INTEREST_TITLE')}
                  </Typography>
                  <Typography variant="h6" color="#1B2A68" textAlign='center' sx={{ fontWeight: 'bold' }}>
                  {applicableInterestRate}
                  </Typography>
              </Grid>
              <Grid item>
                  <Typography variant="body2"  sx={{ color: '#6B7280',marginBottom:'8px' }}>
                  {t('INITIALS_PROCESSING_FEES')}
                  </Typography>
                  <Typography variant="h6" textAlign='center' color="#1B2A68" sx={{ fontWeight: 'bold' }}>
                  {applicationFee}
                  </Typography>
              </Grid>
              </Grid>
          </Card>
          </Grid>
      </Grid>
      </Box>
      </Dialog>
      )}
    </>
  );
});

