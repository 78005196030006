import React, { memo, useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

// @mui
import { Stack, Box, Grid, Button } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useTranslation } from 'react-i18next';

// ----------------------------------------------------------------------
import { RHFTextField, RHFSelectbox } from '../hook-form';
import { formDefaultValues } from '../../constants';

const AddressInformationsForm = ({ name, watch,  getValues, disable, setValue, handleOwnertype, setTabValue,
  tabValue, setCurrrentAddressNextButton, currrentAddressNextButton,permanentAddressNextButton, setPermanentAddressNextButton }) => {
    const [villageOptions, setVillageOptions] = useState([]);
      const {
    villages,
    plotNumber,
    floorNumber,
    streetAndBuildingName,
    locality,
    landmark,
    pinCode,
    villageOrTown,
    tehsil,
    state,
    owned,
    numberOfMonthsAtThisAddress,
    propertyOwnership,
  } = name;

  const [pincodeData, setPincodeData] = useState({ city: false, village: false, state: false });
  const { t } = useTranslation('translation');


  const setValuesInForm = (villageOrTownVal, tehsilVal, stateVal, res) => {
    let stateValue = '';
    let distValue = '';
    let localityVal = '';
    let tehsileValue = '';
    stateValue = !stateVal ? res?.data?.stateName || '' : getValues(state);
    distValue = !villageOrTownVal ? res?.data?.districtName || '' : getValues(villageOrTown);
    tehsileValue = !tehsilVal ? res?.data?.villages?.[0] || '' : getValues(tehsil);
    localityVal = !res?.postOffice ? res?.data?.postOffice || '' : getValues(locality);
    setPincodeData({
      city: stateValue.length > 0,
      village: tehsileValue.length > 0,
      state: res?.data?.villages?.[0],
      locality: res?.data.postOffice,
    });
    if (locality) setValue(locality, localityVal, { shouldValidate: true });
    if (tehsileValue) setValue(villageOrTown, tehsileValue, { shouldValidate: true });
    if (distValue) setValue(tehsil, distValue, { shouldValidate: true });
    if (stateValue) setValue(state, stateValue, { shouldValidate: true });
  };

 

  const watchFields = [
    plotNumber,
    floorNumber,
    streetAndBuildingName,
    landmark,
    villageOrTown,
    tehsil,
    state,
    pinCode,
    numberOfMonthsAtThisAddress,
  ];
  const watchedValues =     (watchFields);
  useEffect(() => {
    if (tabValue === 2) {
      const allFilled = watchFields.every(field => getValues(field));
      setCurrrentAddressNextButton(!allFilled);
    }
  }, [watchedValues, tabValue]);
  

  return (

    <><Box
      sx={{
        padding: '20px',
        background: '#F9FAFB'
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <RHFTextField disabled={!!disable} name={plotNumber} label="PLOT_NUMBER" required />
        </Grid>

        <Grid item xs={4}>
          <RHFTextField disabled={!!disable} name={floorNumber} label="FLOOR_NUMBER" required />
        </Grid>

        <Grid item xs={4}>
          <RHFTextField disabled={!!disable} name={streetAndBuildingName} label="STREAT_AND_BUILDING_NAME" required />
        </Grid>

        <Grid item xs={4}>
          <RHFTextField disabled={!!disable} name={locality} label="LOCALITY" />
        </Grid>

        <Grid item xs={4}>
          <RHFTextField disabled={!!disable} name={landmark} label="LANDMARK" alphaNumeric required />
        </Grid>

        <Grid item xs={4}>
          <RHFTextField
            name={tehsil}
            label="DISTRICT"
            disabled={!!disable}
            onlyText
            required />
        </Grid>

        <Grid item xs={4}>
          {villageOptions?.length > 1 && (
            <RHFSelectbox
              name={villageOrTown}
              label="VILLAGE_TOWN"
              disabled={!!disable}
              required
              menus={villageOptions} />
          )}
          {villageOptions?.length <= 1 && (
            <RHFTextField
              name={villageOrTown}
              label="VILLAGE_TOWN"
              disabled={!!disable}
              onlyText
              required />
          )}
        </Grid>

        <Grid item xs={4}>
          <RHFTextField
            name={state}
            disabled={!!disable}
            label="STATE"
            onlyText
            required />
        </Grid>

        <Grid item xs={4}>
          {' '}
        </Grid>

        <Grid item xs={4}>
          <RHFTextField
            disabled={!!disable}
            name={pinCode}
            label="PIN_CODE"
            type="number"
            maxLength={6}
            required />
        </Grid>

        {numberOfMonthsAtThisAddress && (
          <Grid item xs={4}>
            <RHFTextField
              disabled={!!disable}
              name={numberOfMonthsAtThisAddress}
              label="NUMBER_OF_MONTHS_AT_THIS_ADDRESS"
              type="number"
              maxLength={3}
              noInitalZero
              required />
          </Grid>
        )}

        <Grid item xs={4}>
          <RHFSelectbox
            name={propertyOwnership || owned}
            label="PROPERTY_OWNERSHIP"
            menus={formDefaultValues.propertyOwnership}
            required
            onChange={handleOwnertype} />
        </Grid>
      </Grid>



    </Box>
    {tabValue === 2 && 
    <Stack
      direction="row"
      justifyContent="space-between"
      mt={5}
      sx={{
        position: 'fixed',
        bottom: 0,
        left: '225px',
        right: 0,
        bgcolor: 'white',
        padding: '10px',
      }}
    >
        <Button
          onClick={() => { setTabValue(tabValue - 1); } }
          variant="outlined"
          sx={{
            minWidth: 120,
            color: "#1B2A68",
            borderColor: "#1B2A68",
            '&:hover': {
              bgcolor: "#2A3C8B",
              color: "#FFFFFF",
            }
          }}
        >
          {t('PREVIOUS')}
        </Button>
        <LoadingButton
          size="large"
          disabled={tabValue === 2 ? currrentAddressNextButton : false}
          variant="contained"
          onClick={() => { setTabValue(tabValue + 1); }}
          sx={{ minWidth: 120, bgcolor: "#1B2A68" }}
        >
          {t('NEXT')} ➜
        </LoadingButton>

      </Stack>
  }
      
      </>
  );
};

AddressInformationsForm.propTypes = {
  name: PropTypes.object.isRequired,
  setValue: PropTypes.func.isRequired,
  getValues: PropTypes.func.isRequired,
  disable: PropTypes.bool,
  watchAddress: PropTypes.object,
  typeOfAddress: PropTypes.object,
};

export default memo(AddressInformationsForm);

