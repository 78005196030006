import { httpService } from '../helper';
import { constants } from '../constants';

export const postAssetDetails = async (data) => httpService().post(`${constants.API.ASSET_DETAILS}`, data);

export const getAssetDetails = httpService().get.bind(this, constants.API.ASSET_DETAILS);

export const getCurrentAddress = httpService().get.bind(this, constants.API.GET_CURRENT_ADDRESS);
export const getAllApplicantName = httpService().get.bind(this, constants.API.GET_ALL_APPLICANT);
export const deleteAssets = async (id) => httpService().delete(`${constants.API.DELETE_ASSETS}/${id}`);
export const errorLogs = httpService().get.bind(this, constants.API.DATA_LOGS);
