import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Typography, TextField, Button, Checkbox, FormControlLabel, Link, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

function PaymentForm({ onClose, amountToBePaid, onPayNow }) {
  const { t } = useTranslation('translation');
  const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
  };

  return (
    <Box 
      sx={{ 
        margin: '0 auto', 
        padding: '40px', 
        boxShadow: 3, 
        borderRadius: '8px',
        backgroundColor: '#fff',
        position: 'relative'
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '16px' }}>
        <Typography variant="h6" fontWeight="bold">
          {t("PAY_TO_PROCEED")}
        </Typography>
        <IconButton 
          aria-label="close" 
          onClick={onClose} 
        >
          <CloseIcon />
        </IconButton>
      </Box>
      <Typography variant="body2" color="#737E84" fontSize='14px' mb={3}>
        {t("THE_NON_REFUNDABLE_PART")}
      </Typography>
      <Typography variant='body1' fontWeight='bold' fontSize='18px' mb={1} color='#6b7280' >
        {t("AMOUNT")}
        </Typography>
      <TextField
        fullWidth
        disabled
        variant="outlined"
        defaultValue={amountToBePaid}
        InputProps={{
          style: { borderRadius: '8px' }
        }}
        sx={{ mb: 3 }}
      />
      <FormControlLabel
        control={
          <Checkbox 
            checked={isChecked} 
            onChange={handleCheckboxChange} 
            color="primary" 
          />
        }
        label={
          <Typography variant="body2" color="#9ca3af" fontSize='14px' fontWeight="bold" sx={{ textAlign: 'justify' }}>
            {t("I_UNDERSTAND_THAT_THIS_IS")}
            {/* <Link href="https://www.habilelabs.io/" underline="hover">
              here
            </Link> */}
          </Typography>
        }
        sx={{ alignItems: 'flex-start', mb: 3, width: '100%' }}
      />
      <Button
        variant="contained"
        onClick={onPayNow}
        fullWidth
        disabled={!isChecked}
        sx={{
          padding: '12px',
          borderRadius: '8px',
          backgroundColor: isChecked ? '#16284b' : '#e0e0e0',
          boxShadow: isChecked ? 'none' : 'initial',
        }}
      >
      {t("PAY_NOW")}
      </Button>
    </Box>
  );
}

export default PaymentForm;
