import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Radio, RadioGroup, FormControlLabel, FormLabel, Tooltip, Box } from '@mui/material';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import { useFormContext, Controller } from 'react-hook-form';
import { Help as HelpIcon } from '@mui/icons-material';
import { getReccurssiveObjectKeys, hanelMixPanelEvents } from '../../helper';
import { CContext } from '../utils';

RHFRadio.propTypes = {
  name: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  labels: PropTypes.array.isRequired,
  singleLineRadio: PropTypes.bool,
  showTooltip: PropTypes.bool,
  tooltipText: PropTypes.object,
  tooltipOpen: PropTypes.bool,
  onChange: PropTypes.func,
  setShowTooltip: PropTypes.func,
  tooltipPlacement: PropTypes.string,
  required: PropTypes.bool,
};

const RadioStyle = styled(RadioGroup)(({ theme, singlelineradio }) => ({
  flexDirection: 'row',
  marginTop: '0 !important',
  '& > label': {
    marginRight: 0,
    alignItems: 'center',
    color: theme.palette.common.black,
    marginLeft: 0,
    marginTop: 20,
    width: '25%',
  },
  [theme.breakpoints.down('sm')]: {
    flexDirection: singlelineradio === 'true' ? 'row' : 'column',
    '& > label': {
      width: singlelineradio === 'true' ? '25%' : '100%',
    },
  },
  [theme.breakpoints.between('sm', 'md')]: {
    '& > label': {
      width: '33%',
      paddingRight: 20,
    },
  },
}));

export default function RHFRadio({
  name,
  title,
  labels,
  singleLineRadio,
  showTooltip,
  tooltipText,
  tooltipOpen,
  onChange,
  setShowTooltip,
  tooltipPlacement,
  required,
}) {
  const { t } = useTranslation('translation');
  const { control } = useFormContext();
  const { mixpanel } = React.useContext(CContext);

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, formState: { errors } }) => (
        <>
          <FormLabel
            id="demo-radio-buttons-group-label"
            sx={(theme) => ({
              color: theme.palette.common.black,
              display: 'flex',
              alignItems: 'center',
              [theme.breakpoints.down('sm')]: {
                alignItems: 'flex-start',
              },
            })}
          >
            {t(title)}
            {required && !t(title).includes(' *') && ' *'}
            {showTooltip && tooltipText && (
              <Tooltip
                title={tooltipText}
                aria-label="add"
                placement={tooltipPlacement || 'right'}
                arrow
                enterTouchDelay={0}
                {...{
                  open: tooltipOpen,
                  ...(setShowTooltip && {
                    onMouseEnter: setShowTooltip.bind(null, true),
                    onMouseLeave: setShowTooltip.bind(null, false),
                  }),
                }}
              >
                <HelpIcon sx={(theme) => ({ marginLeft: '10px', fill: theme.palette.common.black })} />
              </Tooltip>
            )}
          </FormLabel>
          <RadioStyle
            {...field}
            singlelineradio={(singleLineRadio || '').toString()}
            onChange={(e) => {
              field.onChange(e);
              if (onChange && typeof onChange === 'function') {
                onChange(e);
              }
            }}
            onBlur={(...args) => {
              hanelMixPanelEvents.call(mixpanel, name, name);
              field.onBlur(...args);
            }}
          >
            {labels.map((item) => (
              <FormControlLabel
                key={item.name + item.value}
                value={item.value}
                control={<Radio />}
                label={t(item.name)}
              />
            ))}
          </RadioStyle>
          <Box sx={() => ({ color: '#FF4842', fontSize: '0.75rem', fontWeight: 400, marginTop: '6px !important' })}>
            {!!getReccurssiveObjectKeys(errors, name)?.message && t(getReccurssiveObjectKeys(errors, name)?.message)}
          </Box>
        </>
      )}
    />
  );
}
