// ----------------------------------------------------------------------

export default function Switch(theme) {
  return {
    MuiSwitch: {
      styleOverrides: {
        root: {
          width: 36,
          height: 18,
          padding: 0,
          display: 'flex',
          '& .MuiSwitch-thumb': {
            boxShadow: 'none',
            width: 14,
            height: 14,
            borderRadius: '50%',
            transition: theme.transitions.create(['width'], {
              duration: 200,
            }),
          },
          '& .MuiSwitch-track': {
            borderRadius: 14,
          },
          '& .MuiSwitch-switchBase': {
            padding: 2,
            '&.Mui-checked': {
              transform: 'translateX(19px)',
              color: theme.palette.activeSwitchThumb,
              '& + .MuiSwitch-track': {
                opacity: 0.2,
              },
            },
          },
        },
      },
    },
  };
}
