import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { Typography, Grid, Box, Tabs } from '@mui/material';
import { TabContext } from '@mui/lab';
import { useTranslation } from 'react-i18next';
/* eslint-disable no-console, no-unused-vars, import/no-unresolved */ 
import { doRegister } from 'src/services/registerService';
import { authService, getdescriptiveMessage, toasterService } from '../helper';
import { doLogin, doLoginViaEmail } from '../services';
import { constants } from '../constants';
import { CustomTab } from '../styled-components/loginForm';
import OTPVerification from './OtpVerification';
import LoginForm from './Login';
import ForgotPassword from './ForgotPassword';
import Register from './Register';

const { ROUTE } = constants;

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box mt={2}>{children}</Box>}
    </div>
  );
}

const validationSchemaEmail = Yup.object().shape({
  email: Yup
    .string()
    .email('Invalid Email')
    .required('Email is required')
    .min(3, 'Email should contain min 3 characters.')
    .max(50, 'Email should contain max 50 characters.'),
  password: Yup
    .string()
    .required('Password is required')
    .min(6, 'Password should contain min 6 characters.')
    .max(50, 'Password should contain max 50 characters.')
    .matches(/[A-Z]/, 'Password must contain at least one uppercase letter.')
    .matches(/[a-z]/, 'Password must contain at least one lowercase letter.')
    .matches(/[0-9]/, 'Password must contain at least one number.')
    .matches(/[!@#$%^&*(),.?":{}|<>]/, 'Password must contain at least one symbol.'),
});

const validationSchemaPhone = Yup.object().shape({
  mobile: Yup
    .string()
    .required('Mobile number is required')
});

const validationSchemaRegister = Yup.object().shape({
  firstName: Yup.string()
    .min(3, "First Name must be at least 3 characters")
    .max(50, "First Name cannot be more than 50 characters")
    .required("Name is required"),

  mobile: Yup.string()
    .required("Mobile number is required"),

  email: Yup.string()
    .email("Invalid Email")
    .min(3, "Email Id must be at least 3 characters long")
    .max(50, "Email Id must be less than 50 characters")
    .required("Email is required"),

  password: Yup.string()
    .min(8, "Password should contain min 8 characters")
    .max(50, "Password should not exceed 50 characters")
    .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/, 
      "Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character")
    .required("Password is required"),
});

const Login = () => {
  const [value, setValue] = useState(0);
  const [showMobileInputScreen, setShowMobileInputScreen] = useState(false);
  const [otp, setOtp] = useState('');
  const [showForgotPassword, setShowForgotPassword] = useState(false);
  const [otpScreen, setOtpScreen] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const { t } = useTranslation('translation');

  const methods = useForm({
    resolver: yupResolver(
      value === 0 
      ? (showMobileInputScreen ? validationSchemaPhone : validationSchemaEmail) 
      : validationSchemaRegister
    ),
    mode: 'onChange',
  });

  const { handleSubmit, formState: { isSubmitting, isValid }, setValue: setFormValue, watch } = methods;

  const handleChange = (event, newValue) => setValue(newValue);
  const handleTogglePasswordVisibility = () => setShowPassword(!showPassword);
  const removePrefix = (phoneNumber) => phoneNumber.startsWith('+91') ? phoneNumber.substring(3) : phoneNumber;

  const onSubmit = async (data) => {
    const _data = { ...data, otp: data.otp?.toString() };

    if (showMobileInputScreen) {
      if (_data.otp?.length === 6 && +_data.otp === 123456) {
        const phoneNumber = removePrefix(_data.mobile);
        const res = await doLogin({ otp: _data.otp, phoneNumber });
        if (res.status && res.data.token) {
          authService.authenticate(res.data.token);
          return navigate(ROUTE.OCR, { replace: true });
        }
        return toasterService(getdescriptiveMessage(res), 4);
      }
      return toasterService(t('INVALID_OTP'), 4);
    }

    if (value === 0) {
      if (_data.email && _data.password) {
        const res = await doLoginViaEmail({ emailId: _data.email, password: _data.password });
        if (res.status && res.data.token) {
          authService.authenticate(res.data.token);
          return navigate(ROUTE.OCR, { replace: true });
        }

        return toasterService(getdescriptiveMessage(res), 4);
      }
    } else if (value === 1) {
      const { mobile, ...restData } = _data;
      const phoneNumber = removePrefix(mobile);

      const modifiedPayload = {
        phoneNumber,
        ...restData,
        customerType: 'INDIVIDUAL',
      };

      const res = await doRegister({ payload: modifiedPayload, customerType: 'INDIVIDUAL', apiKey: 'POST_REGISTER' });
      if (res.status && res.data.token) {
        authService.authenticate(res.data.token);
        return navigate(ROUTE.OCR, { replace: true });
      }
      return toasterService(getdescriptiveMessage(res), 4);
    }
  };

  if (showForgotPassword) {
    return <ForgotPassword setShowForgotPassword={setShowForgotPassword}/>;
  }

  return (
    <TabContext value={value.toString()}>
      <Grid container justifyContent="center">
        <Grid item xs={9}>
          {otpScreen ? (
            <OTPVerification
              otp={otp}
              setOtp={(value) => {
                setOtp(value);
                setFormValue('otp', value);
              }}
              methods={methods}
              watch={watch}
              isSubmitting={isSubmitting}
              onSubmit={handleSubmit(onSubmit)}
            />
          ) : (
            <>
              <Typography variant="h4" fontSize="15px" color='#272727'>{t("GET_MORE_THINGS_DONE_WITH_US")}</Typography>
              <Typography sx={{ color: '#272727', opacity:'0.85', marginBottom: '1px', fontSize: '15px' }}>
              {t("SEARCH_SOURCING_THE_WORLD")}
              </Typography>
              <Tabs textColor="primary" value={value} onChange={handleChange}>
                <CustomTab label={t("LOGIN")} value={0} />
                <CustomTab label={t("SIGN_UP")} value={1} />
              </Tabs>

              <TabPanel value={value} index={0}>
                <LoginForm
                  setShowForgotPassword={setShowForgotPassword}
                  methods={methods}
                  onSubmit={handleSubmit(onSubmit)}
                  showMobileInputScreen={showMobileInputScreen}
                  setShowMobileInputScreen={setShowMobileInputScreen}
                  handleEmailClick={() => setShowMobileInputScreen(false)}
                  handleContinue={() => setOtpScreen(true)}
                  handleOtpClick={() => setShowMobileInputScreen(true)}
                  isSubmitting={isSubmitting}
                  handleTogglePasswordVisibility={handleTogglePasswordVisibility}
                  watch={watch}
                  isValid={isValid}
                  showPassword={showPassword}
                />
              </TabPanel>
              <TabPanel value={value} index={1}>
                <Register
                  methods={methods}
                  onSubmit={handleSubmit(onSubmit)}
                  showPassword={showPassword}
                  handleTogglePasswordVisibility={handleTogglePasswordVisibility}
                  isSubmitting={isSubmitting}
                  isValid={isValid}
                />
              </TabPanel>
            </>
          )}
        </Grid>
      </Grid>
    </TabContext>
  );
};

export default Login;
