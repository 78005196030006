import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { isValidPhoneNumber } from 'react-phone-number-input';
import { useForm } from 'react-hook-form';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { Stack, Container, styled, Box, InputAdornment, IconButton } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useTranslation } from 'react-i18next';
import { FormProvider, RHFPhoneNumberInput, RHFTextField } from '../components/hook-form';
import { Page } from '../components/utils';
import { authService, getdescriptiveMessage, toasterService } from '../helper';
import { constants } from '../constants';
import { doRegister } from '../services/registerService';

const { ROUTE } = constants;

const ContentStyle = styled('div')(() => ({
  maxWidth: 600,
  margin: 'auto',
  display: 'flex',
  flexDirection: 'column',
}));

const ContainerStyle = styled(Container)(({ theme }) => ({
  padding: theme.spacing(3, 7),
  backgroundColor: theme.palette.common.white,
  boxShadow: `0px 0px 4px ${theme.palette.common.greyBox}`,
  borderTop: 0,
  borderBottom: 0,
  width: 600,
  margin: 'auto',
  [theme.breakpoints.down('sm')]: {
    width: '100%',
    padding: '24px 20px',
  },
}));

const Register = () => {
  const navigate = useNavigate();
  const { t } = useTranslation('translation');
  const [mobileValidate, setMobileValidate] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const defaultValues = {
    firstName: '',
    middleName: '',
    mobile: '',
    email: '',
    password: '',
    customerType: 'INDIVIDUAL',
  };

  const methods = useForm({
    resolver: yupResolver(
      Yup.object().shape({
        firstName: Yup.string().required(t('FIRST_NAME_REQUIRED')),
        lastName: Yup.string().required(t('LAST_NAME_REQUIRED')),
        middleName: Yup.string(),
        mobile: Yup.string()
          .test('is-valid-phone', t('INVALID_PHONE'), (value) => isValidPhoneNumber(value))
          .required(t('PHONE_NUMBER_REQUIRED')),
        email: Yup.string().email(t('INVALID_EMAIL')).required(t('EMAIL_REQUIRED')),
        password: Yup.string().min(6, t('INVALID_PASSWORD')).required(t('PASSWORD_REQUIRED')),
        customerType: Yup.string().required(t('REQUIRED_FIELD')),
      })
    ),
    defaultValues,
    mode: 'all',
  });
  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const removePrefix = (phoneNumber) => {
    if (phoneNumber.startsWith('+91')) {
      return phoneNumber.substring(3);
    }
    return phoneNumber;
  };


  const onSubmit = async (data) => {
    const { mobile, ...restData } = data;
    const phoneNumber = removePrefix(mobile);
    const res = await doRegister({apiKey: 'POST_REGISTER', payload: { phoneNumber, ...restData }});
    if (res.status && res.data.token) {
      authService.authenticate(res.data.token);
      return navigate(ROUTE.LOGIN, { replace: true });
    }
    return toasterService(getdescriptiveMessage(res), 4);
  };

  return (
    <Page title="Register">
      <ContainerStyle>
        <ContentStyle>
          <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <Stack spacing={3} mb={5}>

              <RHFTextField name="firstName" label={t('FIRST_NAME')}  
              
              sx={{
                '& .MuiOutlinedInput-root': {
                  backgroundColor: '#F5F6F8',
                },
              }}
              />

              <RHFTextField name="lastName" label={t('LAST_NAME')} onlyText required />

              <RHFPhoneNumberInput
                name="mobile"
                label={t('MOBILE_NUMBER')}
                onChange={(txt) => setMobileValidate(isValidPhoneNumber(txt))}
              />

              <RHFTextField name="email" label={t('EMAIL')} />

              <RHFTextField
                name="password"
                type={showPassword ? "text" : "password"}
                label={t('PASSWORD')}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton edge="end" color="primary" onClick={handleTogglePasswordVisibility}>
                        {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}

              />
            </Stack>
            <LoadingButton
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              loading={isSubmitting}
              sx={(theme) => ({
                background: theme.palette.common.blue,
              })}
            >
              {t('REGISTER')}
            </LoadingButton>
            <Box mt={2}>
              <LoadingButton
                fullWidth
                size="large"
                variant="text"
                onClick={() => navigate(ROUTE.LOGIN)}
                sx={(theme) => ({
                  color: theme.palette.primary.main,
                  '&:hover': {
                    backgroundColor: 'transparent',
                  },
                })}
              >
                {t('BACK_TO_LOGIN_PAGE')}
              </LoadingButton>
            </Box>
          </FormProvider>
        </ContentStyle>
      </ContainerStyle>
    </Page>
  );
};

export default React.memo(Register);
