// ----------------------------------------------------------------------

export default function Tooltip(theme) {
  return {
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: theme.palette.common.black,
          padding: '15px 15px',
          fontSize: 12,
          display: 'flex',
          flexDirection: 'column',
          '& .tooltip-container': {
            maxWidth: 500,
            overflowY: 'auto',
            display: 'flex',
            flexDirection: 'column',
            [theme.breakpoints.down('lg')]: {
              maxWidth: 'inherit',
              maxHeight: 300,
            },
          },
          '& .tooltiphead': {
            fontSize: 14,
            fontWeight: 700,
          },
          '& .tooltipBody': {
            fontSize: 12,
            fontWeight: 400,
            paddingBottom: 10,
          },
          '& .reduce-font-size': {
            '& .tooltiphead': {
              fontSize: '12px !important',
            },
          },
        },
        arrow: {
          color: theme.palette.common.black,
        },
      },
    },
  };
}
