import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import { styled } from '@mui/material/styles';
import { useFormContext, Controller } from 'react-hook-form';
import { hanelMixPanelEvents } from '../../helper';
import { CContext } from '../utils';

// ----------------------------------------------------------------------

RHFPhoneNumberInput.propTypes = {
  name: PropTypes.string,
  onChange: PropTypes.func,
  label: PropTypes.string.isRequired,
  required: PropTypes.bool,
};
const PhoneInputStyle = styled(PhoneInput)(({ theme, isphonelabelshrink, hideFlag }) => ({
  height: 43,
  border: `1px solid ${theme.palette.grey[1000]}`,
  padding: '24px 0px',
  boxSizing: 'border-box',
  marginTop: '0 !important',
  borderRadius: '3px',
  '& .PhoneInputCountryIcon': {
    display: hideFlag ? 'none' : 'block',
  },
  '&.PhoneInput--focus': {
    borderColor: theme.palette.primary.main,
    borderWidth: 2,
    '& + .phoneLabel': {
      transform: 'translate(-50px, -27px) scale(0.75)',
      background: theme.palette.common.white,
      padding: '0 5px',
    },
  },
  '& + .phoneLabel': {
    position: 'absolute',
    pointerEvents: 'none',
    fontSize: '1rem',
    top: 17,
    left: 50,
    color: theme.palette.inputLabel,
    transition:
      'color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,max-width 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms',
  },
  '&:hover': {
    borderColor: theme.palette.common.black,
    '&.PhoneInput--focus': {
      borderColor: theme.palette.primary.main,
      borderWidth: 2,
    },
  },
  '& input': {
    borderColor: theme.palette.primary.main,
    borderWidth: 0,
    outline: 'none',
    paddingLeft: 8,
    fontSize: '1rem',
    background: hideFlag && '#F5F6F8',
  },
  '& .PhoneInputCountrySelectArrow': {
    display: 'none',
  },
  '& select': {
    display: 'none',
  },
  '& + .phoneLabel + p': {
    color: theme.palette.inputError,
    fontSize: '0.75rem',
    fontWeight: 400,
    textAlign: 'left',
    marginTop: '3px !important',
    marginLeft: '15px !important',
  },
}));

export default function RHFPhoneNumberInput({ name, showLoginUi = false, hideFlag = false, label, onChange,helperText = '', required, ...rest }) {
  const { t } = useTranslation('translation');
  const { control } = useFormContext();
  const { mixpanel } = useContext(CContext);

  const handleKeyDown = (e) => {
    const value = e.target.value.toString();
    if (value && value.indexOf('0') === 0) {
      e.target.value = value.substr(1, value.length);
    }
    if ([0, 1, 2, 3, 4, 5, 6, 7, 8, 9].includes(+e.key) && value.length > 10) {
      return e.preventDefault();
    }
    return e;
  };

  return (
    <Controller
      name={name}
      control={control}
      rules={{
        validate: (value) => {
          if (!value) {
            return t('Mobile number is required');
          }
          if (!isValidPhoneNumber(value)) {
            return t('Invalid phone number format');
          }
          const indianNumberPattern = /^\+91\d{10}$/;
          if (!indianNumberPattern.test(value)) {
            return t('Please enter a valid Indian mobile number');
          }
          return true;
        },
      }}
      render={({ field, fieldState: { error } }) => (
        <Box sx={{ position: 'relative' }}>
          {!showLoginUi && <Typography variant="body1" gutterBottom> 
            {t(label)}
            {required && !t(label).includes(' *') && ' *'}
            </Typography>}
          <PhoneInputStyle
            {...field}
            {...rest}
            isphonelabelshrink={field.value}
            label={showLoginUi ? t(label) : null}
            onKeyDown={handleKeyDown}
            onChange={(args) => {
              if (args && +args) {
                if (onChange && typeof onChange === 'function') onChange(args);
              }
              field.onChange(args || '');
            }}
            onBlur={(...args) => {
              hanelMixPanelEvents.call(mixpanel, t(label), name);
              field.onBlur(...args);
            }}
            country="+91"
            countries={['IN']}
            international={false}
            countryCallingCodeEditable={false}
            defaultCountry="IN"
            hideFlag={hideFlag}
          />
          {error ? <Typography color="error" variant="caption">{t(error.message)}</Typography> : <Typography color="error" variant="caption">{helperText}</Typography>}
        </Box>
      )}
    />
  );
}
