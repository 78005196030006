import React, { memo, useContext } from 'react';
import PropType from 'prop-types';
import { useTranslation } from 'react-i18next';
// @mui
import { Close as CloseIcon, Error as ErrorIcon } from '@mui/icons-material';
import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { CContext } from '../utils';

const ErrorStyle = styled(Box)(({ theme, show, isMobile }) => ({
  display: show ? 'flex' : 'none',
  position: 'static',
  zIndex: 99,
  padding: '10px 20px',
  color: theme.palette.common.white,
  fontSize: 16,
  background: theme.palette.error.main,
  fontWeight: 500,
  width: '100%',
  left: 0,
  alignItems: 'center',
  ...(!isMobile && { top: 150 }),
  [theme.breakpoints.up('lg')]: {
    ...(!isMobile && {
      top: 162,
      margin: '0px 120px',
      width: 'calc(100% - 240px)',
    }),
  },
  [theme.breakpoints.between('sm', 'lg')]: {
    top: 160,
    ...(!isMobile && {
      margin: '0px 20px',
      width: 'calc(100% - 40px)',
    }),
  },
  '& .errorTitle': {
    margin: 0,
    color: theme.palette.common.white,
    fontSize: 16,
    fontWeight: 500,
    padding: '0 10px',
    marginRight: 'auto',
    textTransform: 'capitalize',
    [theme.breakpoints.up('lg')]: {
      fontSize: 16,
    },
    [theme.breakpoints.down('sm')]: {
      textAlign: 'left',
      fontSize: 14,
    },
  },
  '& .error-message': {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& svg': {
      fontSize: 20,
    },
  },
  '& > svg': {
    fontSize: 20,
    cursor: 'pointer',
  },
}));

function ErrorMessage({ error, setError }) {
  const { isMobile, errorMessage, setErrorMessage } = useContext(CContext);
  const { t } = useTranslation('translation');

  const handleClose = () => {
    if (setError) {
      return setError.call(null, { show: false, errorMessage: null });
    }
    return setErrorMessage.call(null, { show: false, errorMessage: null });
  };

  return (
    <ErrorStyle isMobile={isMobile} show={errorMessage.show || error?.show}>
      <Box className="error-message">
        <ErrorIcon />
      </Box>
      <Box className="errorTitle">{t(errorMessage.title || error?.title)}</Box>
      <CloseIcon onClick={handleClose} />
    </ErrorStyle>
  );
}

ErrorMessage.propTypes = {
  error: PropType.object,
  setError: PropType.func,
};
export default memo(ErrorMessage);
