/* eslint-disable import/no-unresolved */
import React, { useEffect, useState, useRef, useContext } from 'react';
import { Box, Button, Typography, TextField, Dialog, DialogTitle, IconButton } from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';

import { useTranslation } from 'react-i18next';
import {
  deleteRequiredDocument,
  getFeeDetails,
  getRequiredDocument,
  postUploadDocToAvaas,
  uploadRequiredDocument,
} from 'src/services';
import { Image as ImageIcon, Delete as DeleteIcon } from '@mui/icons-material';
import Stack from '@mui/material/Stack';
import { DeleteModal, OCRCard } from 'src/components/common-components';
import { notification } from 'antd';

import { LoadingButton } from '@mui/lab';
import { toasterService } from 'src/helper';
import { CContext } from 'src/components/utils';
import SearchIcon from '@mui/icons-material/Search';
import { useDocumentData } from 'src/custom-hooks';
import { formDefaultValues } from 'src/constants';
import { photosForOcrCardContainerStyle as CardContainerStyle } from '../styled-components';
import PaymentForm from '../components/common-components/PaymentForm';
import FormPreview from './FormPreview';
import ApplicationDataPreview from './ApplicationDataPreview';

export default function RequiredDocuments() {
  const { setActiveStep, setErrorMessage, setShowLoader, activeStep ,setApplicationProgress,  errorMessage } = useContext(CContext);
  const { t } = useTranslation('translation');

  const [deleteModal, setDeleteModal] = useState({ state: false, deleteDocType: null, applicantId: null });
  const [documentArray, setDocumentArray] = useState([]);
  const [fileSize, setFileSize] = useState();
  const [uploaded, setUploaded] = useState({});
  const [feePayMent, setFeePayMent]= useState();
  const { photoData, setPhotosData } = useDocumentData({}, uploaded);
  const [amountToBePaid, setAmountToBePaid]= useState();
  const [orderId, setOrderId] = useState();
  const [razorPayPaymentId,setRazorPayPaymentId] = useState();
  const [razorPaySignature,setRazorPaySignature] = useState();



  const [displayApplicationPreview, setDisplayApplicationPreview] = useState(false);
  const [isApplicationPreviewDisabled, setIsApplicationPreviewDisabled] = useState(true);
  const inputRefs = useRef([]);
  const [openDialog, setOpenDialog] = useState(false);

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };
  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const [open, setOpen] = useState(false);

  const handleOpenFeePayment = async () => {
    try {
      setShowLoader(true);
      const res = await getFeeDetails();
      setFeePayMent(res.data);
  
      if (res.status) {
        setAmountToBePaid(res?.data?.orderCreatedData?.amount_due);
        setOpen(true); // Open the payment dialog
      } else {
        // Handle error if API fails
        openNotification('error', 'Failed to fetch fee details. Please try again.');
      }
    } catch (error) {
      console.error('Error fetching fee details:', error);
      openNotification('error', 'An unexpected error occurred.');
    } finally {
      setShowLoader(false); // Hide the loader
    }
  };
  
  const handleClose = () => {
    setOpen(false);
  };

  const onCancelUpload = async (controller, name) => {
    setPhotosData((prev) => ({ ...prev, [name]: { file: null, status: 0 } }));
    controller.cancel();
  };

  const { photoOfOcrDocType, JPG_JPEG_PDF_REGEX } = formDefaultValues;


  // const handleDeleteFile = async (deleteDocType) => {
  //   try {
  //     await deleteRequiredDocument({ DOCUMENT_TYPE: deleteDocType });
  //     openNotification('success', 'File deleted successfully')
  //     await getDocsArray();
  //     const updatedDocumentArray = documentArray.map((doc) => {
  //       if (doc.label === label) {
  //         return { ...doc, uploaded: false };
  //       }
  //       return doc;
  //     });
  //     setDocumentArray(updatedDocumentArray);
  //   } catch (error) {
  //     console.error('Error deleting document:', error);
  //   }
  // };
  const handleDeleteFile = async (deleteDocType) => {
    try {
      await deleteRequiredDocument({ DOCUMENT_TYPE: deleteDocType });
      
      // Show success notification
      notification.success({
        message: 'File deleted successfully',
        placement: 'topRight',
      });
  
      // Refresh document array to reflect deletion
      await getDocsArray();
      
      const updatedDocumentArray = documentArray.map((doc) => {
        if (doc.label === deleteDocType) {
          return { ...doc, uploaded: false };
        }
        return doc;
      });
      setDocumentArray(updatedDocumentArray);
  
      // Close the delete modal
      setDeleteModal({ state: false, deleteDocType: null });
    } catch (error) {
      console.error('Error deleting document:', error);
    }
  };
  

  const getDocsArray = async () => {
    setShowLoader(true);
    try {
      const res = await getRequiredDocument();
      if (res.status && res.data && res.data.length > 0) {
        const status = {};
        const uploadedStatus = {};

        res.data.forEach((item) => {
          item.fileMetaData = {
            name: item?.documentName,
            size: item?.fileData?.sizeInKB,
          };

          status[item.documentType] = {
            state: item.uploaded,
            status: item.fileData.status,
            file: item?.fileMetaData || {},
          };

          uploadedStatus[item.documentType] = {
            state: item.uploaded,
            file: item?.fileMetaData || null,
          };
        });

        setPhotosData(status);
        setUploaded(uploadedStatus);
      }
      let documentUploadedCount = 0;
      for (let index = 0; index < res?.data.length; index++) {
        const document = res?.data[index];
        if (document.uploaded === true) {
          documentUploadedCount += 1;
        }
      }
      if (documentUploadedCount === 6) {
        setIsApplicationPreviewDisabled(false);
      } else {
        setIsApplicationPreviewDisabled(true);
      }
    } catch (error) {
      console.error('Error fetching documents:', error);
    }
    setShowLoader(false);
  };

  useEffect(() => {
    getDocsArray();
  }, []);

  const openNotification = (type, message) => {
    notification[type]({
      message,
      placement: 'topRight',
    });
  };

  const onChange = async (controller, e) => {
    const file = e.target.files[0];
    setFileSize(file?.size);
    const { type, size } = file;
  
    const validTypes = ['image/jpeg', 'image/png', 'application/pdf'];
    if (!validTypes.includes(type)) {
      return openNotification('error', 'Accepted file types are JPG, JPEG, PNG, and PDF.');
    }
  
    const MAX_FILE_SIZE = 10 * 1024 * 1024;
    if (size > MAX_FILE_SIZE) {
      return openNotification('error', 'File size should not exceed 10MB.');
    }
  
    setPhotosData((prev) => ({ ...prev, [e.target.name]: { file, status: 1 } }));
    const formData = new FormData();
    formData.append('DOCUMENT', file);
    formData.append('DOCUMENT_TYPE', photoOfOcrDocType[e.target.name]);
    e.target.value = '';
    const res = await uploadRequiredDocument(formData, controller);
    if (res.status) {
      setUploaded((prev) => ({ ...prev, [e.target.name]: { file, state: true } }));
      openNotification('success', 'File uploaded successfully');

    } else {
      setUploaded((prev) => ({ ...prev, [e.target.name]: { file: null, state: false } }));
      setPhotosData((prev) => ({ ...prev, [e.target.name]: { file: null, status: 0 } }));
      if (res.error.name !== 'CanceledError') {
        openNotification('error', res.error.msg);
      }
    }
  };

  const payNowButtonClick = async (feePayMent2) => {
  const orderId = feePayMent2?.orderId;
    const amount = feePayMent2?.orderCreatedData?.amount;
    const currency = feePayMent2?.orderCreatedData?.currency;
      try {
        if (!orderId) return; 
  
        const scriptRef = document.createElement('script');
        scriptRef.src = 'https://checkout.razorpay.com/v1/checkout.js';
        scriptRef.async = true;
        scriptRef.onload = () => {
          const options = {
            amount,
            currency,
            name: "Your Company Name",
            description: "Sample Product",
            order_id: orderId,
            handler: (response) => {
             setOrderId(response?.razorpay_payment_id)
             setRazorPayPaymentId(response?.razorpay_payment_id)
             setRazorPaySignature(response?.razorpay_signature)
              setActiveStep(activeStep + 1)
              setApplicationProgress((prev) => ({
                completed: [...prev.completed, 'isFeePaymentSaved'],
                ...prev,
              }))},
             
            prefill: {
              name: "Customer Name",
              email: "customer@example.com",
            },
          };
  
          const rzp1 = new window.Razorpay(options);
          rzp1.open();
        };
        document.body.appendChild(scriptRef);
      } catch (error) {
        setErrorMessage({ title: error, show: true });
        setShowLoader(false);
      }
    
  };


  const saveAndContinue = async () => {
    setShowLoader(true);
    const res = await postUploadDocToAvaas();
    if (res.status) {
      toasterService(t('APPLICATION_DATA_SUBMITTED_SUCCESSFULLY'));
    } else {
      setErrorMessage({ title: res.error.msg, show: true });
    }
    setShowLoader(false);
    return true;
  };

  return (
    <>
      <CardContainerStyle>
      <Box className="contentBox">
        {Object.entries(photoData).map(([key, value]) => (
            <OCRCard
              key={key}
              fileSize={fileSize}
              deleteFile={() => setDeleteModal({ state: true, deleteDocType: key })}
              onCancelUpload={onCancelUpload}
              onChange={onChange}
              uploaded={uploaded[key]?.state}
              name={key}
              fileData={value || { status: 1 }}
              label={key.toUpperCase()}
            />
          ))}
         </Box>
        <Stack
          direction="row"
          justifyContent="space-between"
          mt={5}
          sx={{
            position: 'fixed',
            bottom: 0,
            left: '225px',
            right: 0,
            bgcolor: 'white',
            padding: '10px',
            // background:'#F9FAFB'
          }}
        >
        
                <Button onClick={()=>{setActiveStep(activeStep - 1)}} 
                  variant="outlined" 
                  sx={{ 
                    minWidth: 120,
                  color: "#1B2A68",
                  borderColor: "#1B2A68",
                  '&:hover': {
                    bgcolor: "#2A3C8B",
                    color: "#FFFFFF",
                    } 
                  }} 
                >
                {t('PREVIOUS')}
                </Button>
              

                <Box sx={{display: 'flex', gap:'12px'}}>
                <Button
              startIcon={<SearchIcon />}
              variant="outlined"
              sx={{ 
                position: 'relative',
                color: "#1B2A68",
                borderColor: "#1B2A68",
                '&:hover': {
                    bgcolor: "#2A3C8B",
                    color: "#FFFFFF",
                    }  
              }}
              size="large"
              onClick={handleOpenDialog}
            >
              {t('PREVIEW_APPLICATION_DATA')}
            </Button>

                <LoadingButton
                  size="large"
                  type="submit"
                  variant="contained"
                  onClick={handleOpenFeePayment}
                  sx={{ minWidth: 120, 
                    bgcolor: "#1B2A68",  
                    '&:hover': {
                    bgcolor: "#2A3C8B", 
                    }}}  // Optional styling to match button color
                >
                  {t('NEXT')} ➜
                </LoadingButton>
                </Box>
              </Stack>
      </CardContainerStyle>

      {deleteModal.state && (
        // <DeleteModal
        //   deleteModal={{
        //     ...deleteModal,
        //     txtMessage: 'DELETE_DOCUMENT',
        //     question: 'FILE_DELETE_WARNING_REQ_DOC',
        //   }}
        //   handleModalClose={() => setDeleteModal({ state: false, deleteDocType: null, applicantId: null })}
        //   handleDelete={() => handleDeleteFile(deleteModal.applicantId, deleteModal.deleteDocType)}
        // />
        <DeleteModal
  deleteModal={{
    ...deleteModal,
    txtMessage: 'DELETE_DOCUMENT',
    question: 'FILE_DELETE_WARNING_REQ_DOC',
  }}
  handleModalClose={() => setDeleteModal({ state: false, deleteDocType: null })}
  onDeleteFileModal={handleDeleteFile}
/>

      )}

  
		<Box>
			<Dialog
			open={open}
			onClose={handleClose}
			sx={{
				'& .MuiPaper-root': {
				maxWidth: '450px',
				borderRadius: '0px',
				},
			}}
			>
			<PaymentForm 
        onClose={handleClose} 
        amountToBePaid={amountToBePaid} 
        onPayNow={() => payNowButtonClick(feePayMent)} 
      />

			</Dialog>
		</Box>

		<Box>
			<Dialog
			open={openDialog}
			onClose={handleCloseDialog}
			sx={{
				display: 'flex',
				justifyContent: 'center',
				'& .MuiPaper-root': {
				maxWidth: 'none',
        margin: 0,

				},
			}}
			>
			<DialogTitle
				sx={{
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'space-between',
				}}
			>
				<Typography color="#1B2A68" variant="h4">
				UNASSISTED
				</Typography>
				<IconButton
				edge="end"
				color="inherit"
				onClick={handleCloseDialog}
				aria-label="close"
				sx={{
					color: (theme) => theme.palette.grey[500],
				}}
				>
				<CloseIcon />
				</IconButton>
			</DialogTitle>
		
			<ApplicationDataPreview handleCloseDialog={handleCloseDialog} />
			</Dialog>
		</Box>
    </>
  );
}

