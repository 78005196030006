import { toast } from 'react-toastify';
import { CheckIfNotEmpty } from './utils';

/**
 * to display toaster
 * @param message : message to show on toaster
 * @param type : type of notification ,
 * i.e. if empty them default toaster else
 * 1 : info
 * 2 : success
 * 3 : warning
 * 4 : error
 */
export default (message, type, id) => {
  const options = {
    toastId: id,
    position: 'top-right',
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    maxOpened: 1,
    preventDuplicates: 1,
  };

  const toastTypes = {
    1: toast.info.bind(null, message, options),
    2: toast.success.bind(null, message, options),
    3: toast.warn.bind(null, message, options),
    4: toast.error.bind(null, message, options),
  };

  if (CheckIfNotEmpty(message)) {
    toastTypes[type || 1].call();
  }
};
