import React, { useState } from 'react';
import { Box, Button, Typography, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import PasswordReset from './PasswordReset';

const ForgotPassword = (props) => {
  const {setShowForgotPassword} = props
  const [selectedOption, setSelectedOption] = useState(null);
  const [showPasswordReset, setShowPasswordReset] = useState(false);
  const navigate = useNavigate(); // Initialize navigate
  const { t } = useTranslation('translation');

  const handleSelectOption = (option) => {
    setSelectedOption(option);
  };

  const handleSubmit = () => {
    if (selectedOption) {
      setShowPasswordReset(true);
    }
  };

  if (showPasswordReset) {
    return <PasswordReset selectedOption={selectedOption} setShowForgotPassword={setShowForgotPassword}/>;
  }

 

  return (
    <Box>
      <Typography variant="h5" color="#272727" component="h1" gutterBottom>
        {t("FORGET_PASSWORD")}
      </Typography>
      <Typography variant="body2" gutterBottom sx={{color: '#272727', opacity:'0.85',}}>
        Please select an option to send a password reset link
      </Typography>
      <Grid container spacing={2} mt={2}>
        <Grid item xs={12}>
          <Button
            variant="outlined"
            startIcon={<EmailIcon />}
            fullWidth
            onClick={() => handleSelectOption('email')}
            sx={{
              borderColor: selectedOption === 'email' ? '#192C79' : '#DEDEDE', 
              color: selectedOption === 'email' ? '#192C79' : 'rgba(39, 39, 39, 0.40)',
              borderRadius: '3px',
              justifyContent: 'flex-start',
              textAlign: 'left',
              padding: '10px !important',
              '&:hover': {
                borderColor: '#192C79',
              },
              '& .MuiButton-startIcon': {
                color: selectedOption === 'email' ? '#192C79' : 'rgba(39, 39, 39, 0.40)',
              },
            }}
          >
            Reset Via Email
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Button
            variant="outlined"
            startIcon={<PhoneIcon />}
            fullWidth
            onClick={() => handleSelectOption('phone')}
            sx={{
              borderColor: selectedOption === 'phone' ? '#192C79' : '#DEDEDE',
              color: selectedOption === 'phone' ? '#192C79' : 'rgba(39, 39, 39, 0.40)',
              borderRadius: '3px',
              justifyContent: 'flex-start',
              textAlign: 'left',
              padding: '10px !important',
              '&:hover': {
                borderColor: '#192C79',
              },
              '& .MuiButton-startIcon': {
                color: selectedOption === 'phone' ? '#192C79' : 'rgba(39, 39, 39, 0.40)',
              },
            }}
          >
            Reset Via Phone
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={handleSubmit}
            sx={{
              mt: 2,
              background: '#192C79',
              padding: '10px !important',
              borderRadius: '3px',
              boxShadow: 'none',
              '&:hover': {
                background: '#192C79',
              },
            }}
          >
            Send Link
          </Button>
        </Grid>
      </Grid>
      <Box mt={2}>
        <Typography
          variant="body2"
          sx={{
            textAlign: 'center',
            color: '#192C79',
            cursor: 'pointer',
            '&:hover': {
              textDecoration: 'underline',
              backgroundColor: 'transparent',
            },
          }}
          onClick={() => setShowForgotPassword(false)} // Navigate to login page
        >
          Back to login
        </Typography>
      </Box>
    </Box>
  );
};

export default ForgotPassword;
