import { Box, Tab, Tabs } from '@mui/material';
import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { ApplicantDetailsTabs as ContainerStyle } from '../styled-components';
import ApplicantDetailsInformations from './ApplicantDetailsInformations';
import { CContext } from '../components/utils';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box pt={4}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

export default function ApplicantDetails() {
  const { t } = useTranslation('translation');
  const [value, setValue] = useState(0);

  const {
    errorMessage,
    tabPanelValue,
    applicationProgress: { completed },
  } = useContext(CContext);


  return (
    <ContainerStyle>
        <ApplicantDetailsInformations />
    </ContainerStyle>
  );
}

