/* eslint-disable react/display-name */
import React, { useCallback, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
// @mui
import { Box } from '@mui/material';

import { useTranslation } from 'react-i18next';
import { ChevronRight as ChevronRightIcon } from '@mui/icons-material';

import { formDefaultValues } from '../constants';
import {
  applicationProgressStatusBoxStyle as StatusBoxStyle,
  applicationProgressCardContainerStyle as CardContainerStyle,
} from '../styled-components';
import { CommonHeader } from '../components/common-components';
import { CContext } from '../components/utils';

const { allStepsForAavasLoan } = formDefaultValues;

export default React.memo(() => {
  const { t } = useTranslation('translation');
  const navigate = useNavigate();
  const {
    errorMessage,
    applicationProgress: { available, completed },
  } = useContext(CContext);

  const isClickAble = useCallback(
    (item) => available.includes(item.available) || completed.includes(item.key),
    [completed, available]
  );

  const StatusTile = useCallback(
    ({ item }) => {
      if (available.includes(item.available) && !completed.includes(item.key)) {
        return <span id="status-circle-error" />;
      }
      if (completed.includes(item.key)) {
        return <span id="status-circle-success" />;
      }
      return <span id="status-circle-pending" />;
    },
    [completed, available]
  );

  const handleOnClick = (item) => {
    // if (available.includes(item.available) || completed.includes(item.key)) {
      navigate(item.route, { replace: true });
    // }
  };

  return (
    <>
      <CardContainerStyle iserrordisplayed={`${errorMessage.show}`}>
        {allStepsForAavasLoan.map((item) => (
          // item.key === "isFeePaymentDone" && !offerAccepted ? '' :
          <StatusBoxStyle
            key={item.label}
            className={`buttonBox ${isClickAble(item) ? 'cursor-pointer' : ''}`}
            onClick={handleOnClick.bind(this, item)}
          >
            <Box className="vertical-lines" />
            <Box className={`${!isClickAble(item) ? 'pending-status' : ''}`}>
              <StatusTile item={item} />
              {t(item.label)}
            </Box>
            <ChevronRightIcon className={`hand-icon ${!isClickAble(item) ? 'pending-status-arrow' : ''}`} />
          </StatusBoxStyle>
        ))}
      </CardContainerStyle>
    </>
  );
});
